import { HomeOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormGroup } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';

import type { Firebase } from 'types/firebase';
import { MERCHANT_SEGMENTS } from '../../../../constants';
import * as Styles from './MerchantRevenueForm.styles';

export type MerchantRevenueFormProps = {
  merchant: Firebase.Merchant;
  onSave: (name: string, value: string | number) => void;
};

type FormFieldType = {
  title: string;
  icon: React.ReactNode;
  inputs: {
    type?: string;
    label: string;
    alternativeLabel: string;
    name: string;
    value: string | number | undefined | null;
  }[];
};

const MerchantRevenueForm: React.FC<MerchantRevenueFormProps> = (props) => {
  const formFields = useMemo<FormFieldType[]>(() => {
    const { merchant } = props;

    return [
      {
        title: 'Revenue',
        icon: <HomeOutlined />,
        inputs: [
          {
            label: 'Monthly Turnover',
            alternativeLabel: 'Change Monthly Turnover',
            name: 'monthlyTurnover',
            value: merchant.monthlyTurnover,
          },
          {
            label: 'Average Transaction Amount',
            alternativeLabel: 'Change Average Transaction Amount',
            name: 'averageTransactionAmount',
            value: merchant.averageTransactionAmount,
          },
          {
            label: 'Default VAT',
            alternativeLabel: 'Default VAT',
            name: 'vat',
            value: merchant?.vat,
            type: 'number',
          },
          {
            label: 'Merchant Segment',
            alternativeLabel: 'Change Merchant Segment',
            name: 'merchantSegment',
            value: merchant.merchantSegment,
            type: 'dropdown',
            dropdown: {
              placeholder: 'Select a segment',
              values: MERCHANT_SEGMENTS,
              defaultValue: 'daily',
              options: MERCHANT_SEGMENTS,
            },
          },
        ],
      },
      {
        title: 'Aditional',
        icon: <HomeOutlined />,
        inputs: [
          {
            label: 'Device Type',
            alternativeLabel: 'Change device type',
            name: 'deviceType',
            value: merchant.deviceType,
          },
        ],
      },
    ];
  }, [props]);

  return (
    <div>
      {formFields.map((form) => (
        <Styles.FormContainer key={form.title}>
          <FormGroup icon={form.icon} title={form.title}>
            {form.inputs.map((input) => (
              <FormGroupItem
                {...input}
                key={input.name}
                onSave={(newValue) => props.onSave(input.name, newValue)}
              />
            ))}
          </FormGroup>
        </Styles.FormContainer>
      ))}
    </div>
  );
};

export { MerchantRevenueForm };
