import React from 'react';
import { Modal } from 'antd';

export type ViewPictureDialogProps = {
  visible: boolean;
  onClose: () => void;
  imageSrc: string;
  profileName: string;
};

const ViewPictureDialog: React.FC<ViewPictureDialogProps> = (props) => {
  return (
    <Modal
      title={props.profileName}
      visible={props.visible}
      okButtonProps={{
        hidden: true,
      }}
      cancelText="Close"
      onOk={props.onClose}
      onCancel={props.onClose}
    >
      <img src={props.imageSrc} alt={props.profileName} width="100%" />
    </Modal>
  );
};

export { ViewPictureDialog };
