/**
 * Imports
 */
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styled components
 */
const Flex = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
`;

const StyledRow = styled.div<LayoutProps>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  align-items: ${({ align }) => (align ? align : 'center')};
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Types
 */
export type FlexDirection = 'row' | 'row-reverse' | 'column-reverse';
export type Justify = 'center' | 'flex-end' | 'flex-start' | 'space-between';
export type Align = 'center' | 'flex-end' | 'flex-start' | 'space-between';
export interface LayoutProps {
  direction?: FlexDirection;
  justify?: Justify;
  align?: Align;
}

/**
 * Layout component
 */
const Layout: React.FC<LayoutProps> = ({ children, direction }) => {
  return <Flex direction={direction || 'column'}>{children}</Flex>;
};
/**
 * Layout component
 */
const Row: React.FC<LayoutProps> = ({ children, justify, align }) => {
  return (
    <StyledRow justify={justify} align={align}>
      {children}
    </StyledRow>
  );
};
/**
 * Layout component
 */
const Column: React.FC<LayoutProps> = ({ children }) => {
  return <StyledColumn>{children}</StyledColumn>;
};

/**
 * Exports
 */
export { Layout, Row, Column };
