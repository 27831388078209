import React, { useMemo } from 'react';
import { HomeOutlined } from '@ant-design/icons';

import { FormGroupItem, FormGroupItemProps } from 'components/FormGroupItem';
import { FormGroup } from 'components';

import * as Styles from './MerchantDetailsForm.styles';
import type { Firebase } from 'types/firebase';

export type MerchantDetailsFormProps = {
  merchant: Firebase.Merchant;
  onSave: (name: string, value: string | number) => void;
};

export type FormFieldType = {
  title: string;
  icon: React.ReactNode;
  inputs: Array<FormGroupItemProps>;
};

const MerchantDetailsForm: React.FC<MerchantDetailsFormProps> = (props) => {
  const formFields = useMemo<FormFieldType[]>(() => {
    const { merchant } = props;
    // businessStructure
    // businessLegalName
    // vatNumber
    return [
      {
        title: 'Company',
        icon: <HomeOutlined />,
        inputs: [
          {
            label: 'Business Legal Name',
            alternativeLabel: 'Change Business Legal Name',
            name: 'businessLegalName',
            // @ts-ignore
            value: merchant?.businessLegalName,
            hasCopyIcon: true,
          },
          {
            label: 'Trading Name',
            alternativeLabel: 'Change Trading Name',
            name: 'merchantTradingName',
            value: merchant.merchantTradingName,
            hasCopyIcon: true,
          },
          {
            label: 'Company Registration Number',
            alternativeLabel: '',
            name: 'companyRegistrationNumber',
            value: merchant.companyRegistrationNumber,
            hasCopyIcon: true,
          },
          {
            label: 'VAT Number',
            alternativeLabel: '',
            name: 'vatNumber',
            // @ts-ignore
            value: merchant?.vatNumber,
            hasCopyIcon: true,
            restrictEdit: true,
          },
          {
            label: 'Business Structure',
            alternativeLabel: 'Change Business Structure',
            name: 'businessStructure',
            // @ts-ignore
            value: merchant?.businessStructure,
          },
          {
            label: 'Address',
            alternativeLabel: 'Change Address',
            name: 'addressLine1',
            value: merchant.addressLine1,
            hasCopyIcon: true,
          },
          {
            label: 'City',
            alternativeLabel: 'Change City',
            name: 'city',
            value: merchant.city,
            hasCopyIcon: true,
          },
          {
            label: 'ZIP Code',
            alternativeLabel: 'Change ZIP Code',
            name: 'zipCode',
            value: merchant.zipCode,
            hasCopyIcon: true,
          },
          {
            label: 'Website',
            alternativeLabel: 'Change Website',
            name: 'website',
            value: merchant.website,
            hasCopyIcon: true,
          },
        ],
      },
    ];
  }, [props]);

  return (
    <div>
      {formFields.map((form) => (
        <Styles.FormContainer key={form.title}>
          <FormGroup icon={form.icon} title={form.title}>
            {form.inputs.map((input) => (
              <FormGroupItem
                {...input}
                key={input.name}
                onSave={(newValue) => props.onSave(input.name, newValue)}
              />
            ))}
          </FormGroup>
        </Styles.FormContainer>
      ))}
    </div>
  );
};

export { MerchantDetailsForm };
