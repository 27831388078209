import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { Moment } from 'moment';
import type { SoftPos } from '../types/softpos';

export const getTransactions = async (
  startDate: Moment,
  endDate: Moment,
  merchantId: string,
): Promise<SoftPos.TrxnRaw[]> => {
  const idToken = await getIdToken();
  try {
    const params = {
      startDate: startDate.clone().utc().format(),
      endDate: endDate.clone().utc().format(),
      merchantId,
    };
    const response = await axios.get(
      `${PROJECT_API_URL}/api/softpos/v1/merchant_transactions`,
      {
        params,
        headers: { token: idToken },
      },
    );
    return response.data.result as SoftPos.TrxnRaw[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllTransactions = async (
  startDate: Moment,
  endDate: Moment,
): Promise<SoftPos.TrxnRaw[]> => {
  const idToken = await getIdToken();
  try {
    const utcStart = startDate.clone().utc();
    const utcEnd = endDate.clone().utc();
    const params = {
      startDate: utcStart.format(),
      endDate: utcEnd.format(),
    };
    const response = await axios.get(
      `${PROJECT_API_URL}/api/softpos/v1/all_transactions`,
      {
        params,
        headers: { token: idToken },
      },
    );
    return response.data.result as SoftPos.TrxnRaw[];
  } catch (error) {
    throw new Error(error);
  }
};

export const getLastTransactionDate = async (
  merchantId: string,
): Promise<string> => {
  try {
    const idToken = await getIdToken();
    const response = await axios.get(
      `${PROJECT_API_URL}/api/softpos/v1/last_transaction/${merchantId}`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as string;
  } catch (error) {
    console.log(error);
    return '';
  }
};
