import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { Button, Icon, Input, Text } from 'components';

import { Alert, Select } from 'antd';
import { CopyButton } from 'components/CopyButton';
import { CONTRACT_OPTIONS } from '../../constants';
import * as Styles from './FormGroupItem.styles';
import { SelectOption } from 'utils/OptionUtils';

export type FormGroupItemProps = {
  label: string;
  alternativeLabel: string;
  name: string;
  type?: string;
  value: string | null | number | undefined;
  alertDescription?: any;
  isValid?: (value: string | number) => boolean;
  onSave?: (text: string | number) => void;
  hasCopyIcon?: boolean;
  restrictEdit?: boolean;
  dropdown?: {
    values: SelectOption[];
    defaultValue: string;
    placeholder: string;
    options: SelectOption[];
  };
};

const FormGroupItem: React.FC<FormGroupItemProps> = (props) => {
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(props.value ?? '');
  const [originalValue, setOriginalValue] = useState(props.value ?? '');
  const debouncedValue = useDebounce(value.toString(), 500);

  const handleToggleEditing = () => {
    if (!isEditing) {
      setOriginalValue(value);
    }
    setEditing((isEditingPrev) => !isEditingPrev);
  };

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setValue(event.target.value);
  };

  /**
   * Handle campaign
   */
  const onHandleCampaignValues = () => {
    if (value === 'no-campaign') {
      // @ts-ignore
      props.onSave?.({ noCampaign: 'no-campaign' });
    } else {
      // @ts-ignore
      props.onSave?.({ qpCampaign: value });
    }
  };

  /**
   * Save the value to firestore
   */
  const handleSave = () => {
    // Handle campaign values
    if (props.name === 'campaign') {
      onHandleCampaignValues();
    }
    // Handle binary values
    else if (value === 'true') {
      // @ts-ignore
      props.onSave?.(true);
      // Handle binary values
    } else if (value === 'false') {
      // @ts-ignore
      props.onSave?.(false);
    } else {
      props.onSave?.(value);
    }
    setEditing(false);
  };

  const resetValue = () => {
    setValue(originalValue);
  };

  return (
    <Styles.Container>
      {!isEditing && (
        <>
          <Styles.LabelContainer>
            <Text size="ui3">
              <Styles.StyledDiv>
                {props.label}
                {props.hasCopyIcon && value && (
                  <CopyButton text={value.toString()} margin="0px 5px" />
                )}
              </Styles.StyledDiv>
            </Text>
            {props.isValid && !props.isValid?.(debouncedValue) && (
              <Alert
                message={`Warning: ${props.value} not valid ${props.name}`}
                type="warning"
                showIcon
                closable
                style={{ margin: '1rem 0' }}
              />
            )}
            <Styles.ValueText size="ui2">{props.value}</Styles.ValueText>
          </Styles.LabelContainer>

          {!props.restrictEdit && (
            <Styles.ChangeButton type="text" onClick={handleToggleEditing}>
              Change
            </Styles.ChangeButton>
          )}
        </>
      )}

      {isEditing && (
        <Styles.Column>
          <Styles.Row>
            <Text size="ui2" bold>
              {props.alternativeLabel}
            </Text>

            <Button
              ghost
              onClick={() => {
                handleToggleEditing();
                resetValue();
              }}
            >
              <Icon icon={<CloseOutlined />} />
            </Button>
          </Styles.Row>

          <Styles.InputContainer onSubmit={handleSave}>
            <Text size="ui3">{props.label}</Text>
            <Styles.InputRow>
              {getChangeInput(props, handleChange, value, setValue)}
              <Styles.SaveButton
                onClick={handleSave}
                disabled={props.isValid && !props.isValid?.(debouncedValue)}
              >
                Save
              </Styles.SaveButton>
            </Styles.InputRow>
            {props.isValid && !props.isValid?.(debouncedValue) && (
              <Alert
                message="Warning Invalid Field"
                description={props.alertDescription}
                type="warning"
                showIcon
                closable
                style={{ margin: '1rem 0' }}
              />
            )}
          </Styles.InputContainer>
        </Styles.Column>
      )}
    </Styles.Container>
  );
};

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [delay, value]);

  return debouncedValue;
}

const getChangeInput = (
  props: FormGroupItemProps,
  handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >,
  value: string | number,
  setValue: React.Dispatch<React.SetStateAction<string | number>>,
) => {
  const dropdownHandleChange = (value: string | number) => {
    setValue(value);
  };

  if (props.type === 'textarea') {
    return (
      <Input.TextArea name={props.name} value={value} onChange={handleChange} />
    );
  } else if (props.type === 'dropdown') {
    const defaultValue =
      value && CONTRACT_OPTIONS.map((opt) => opt.value === value)
        ? value.toString()
        : props.dropdown?.defaultValue;
    if (!value) {
      setValue(defaultValue!);
    }
    if (!props?.dropdown?.options) {
      return null;
    }
    return (
      <Select
        style={{ width: '100%', maxWidth: '300px' }}
        showSearch
        placeholder={props.dropdown?.placeholder}
        optionFilterProp="children"
        onSelect={dropdownHandleChange}
        value={defaultValue}
        defaultValue={defaultValue}
      >
        {props.dropdown.options.map((opt) => {
          return (
            <Select.Option key={opt.value} value={opt.value}>
              {opt.label}
            </Select.Option>
          );
        })}
      </Select>
    );
  } else {
    return (
      <Input
        name={props.name}
        value={value}
        onChange={handleChange}
        type={props.type ? props.type : 'text'}
      />
    );
  }
};

export { FormGroupItem };
