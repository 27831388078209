/**
 * Imports
 */
import { Dropdown, Menu, Text, VibrantIcon } from 'components';
import { getDateRange } from 'features/transactions/helpers';
import { setDates as setTransactionDates } from 'features/transactions/redux/actions';
import { fixedRangeSelector as fixedTransactionsRangeSelector } from 'features/transactions/redux/selectors';
import { FixedDatesRange } from 'features/transactions/types';
import moment from 'moment';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'theme';

/**
 * Styling
 */

const SelectButton = styled.div`
  cursor: pointer;
  border-radius: 4px;

  display: flex;
  align-items: center;
`;

const Selection = styled.div<{ hasSelection: boolean }>`
  display: flex;
  align-items: center;
`;
const StyledText = styled(Text)`
  margin-right: 8px;
`;
const ClearButton = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
  border-radius: 50%;
  padding: 2px;
  margin-left: 8px;
`;

/**
 * Private helpers
 */

const _getDateRangeString = (type: string) => {
  switch (type) {
    case FixedDatesRange.NONE:
      return <Trans i18nKey="Transactions.fixed_date_picker.select_btn" />;
    case FixedDatesRange.TODAY:
      return <Trans i18nKey="Transactions.fixed_date_picker.today" />;
    case FixedDatesRange.YESTERDAY:
      return <Trans i18nKey="Transactions.fixed_date_picker.yesterday" />;
    case FixedDatesRange.LAST_WEEK:
      return <Trans i18nKey="Transactions.fixed_date_picker.last_week" />;
    case FixedDatesRange.MONTH_TO_DATE:
      return <Trans i18nKey="Transactions.fixed_date_picker.this_month" />;
    case FixedDatesRange.LAST_MONTH:
      return <Trans i18nKey="Transactions.fixed_date_picker.last_month" />;
    case FixedDatesRange.YEAR_TO_DATE:
      return <Trans i18nKey="Transactions.fixed_date_picker.this_year" />;
    case FixedDatesRange.LAST_YEAR:
      return <Trans i18nKey="Transactions.fixed_date_picker.last_year" />;
    default:
      return <Trans i18nKey="Transactions.fixed_date_picker.select_btn" />;
  }
};

/**
 * Types
 */
export interface FixedDatePickerProps {
  clearable?: boolean;
  comparision?: boolean;
}

/**
 * FixedDatePicker component
 */
const FixedDatePicker: React.FC<FixedDatePickerProps> = ({
  clearable,
  comparision,
}) => {
  const dispatch = useDispatch();
  const transactionsRange = useSelector(fixedTransactionsRangeSelector);
  /* In-use variables */
  const fixedRange = transactionsRange;
  const hasSelection = fixedRange !== FixedDatesRange.NONE;
  const setDatesType = setTransactionDates;

  const _clearSelection = () => {
    const startDate = moment().startOf('day');
    const endDate = moment().endOf('day');

    dispatch(
      setDatesType({ startDate, endDate, fixedRange: FixedDatesRange.NONE }),
    );
  };

  const handleMenuClick = (e: any) => {
    const dates = getDateRange(e.key);
    const { startDate, endDate } = dates;
    dispatch(setDatesType({ startDate, endDate, fixedRange: e.key }));
  };

  return (
    <Dropdown
      overlay={
        comparision ? (
          <Menu onClick={handleMenuClick} selectedKeys={[fixedRange]}>
            <Menu.Item key={FixedDatesRange.TODAY}>
              <Trans i18nKey="Transactions.fixed_date_picker.today" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.YESTERDAY}>
              <Trans i18nKey="Transactions.fixed_date_picker.yesterday" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.LAST_WEEK}>
              <Trans i18nKey="Transactions.fixed_date_picker.last_week" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.MONTH_TO_DATE}>
              <Trans i18nKey="Transactions.fixed_date_picker.this_month" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.LAST_MONTH}>
              <Trans i18nKey="Transactions.fixed_date_picker.last_month" />
            </Menu.Item>
          </Menu>
        ) : (
          <Menu onClick={handleMenuClick} selectedKeys={[fixedRange]}>
            <Menu.Item key={FixedDatesRange.TODAY}>
              <Trans i18nKey="Transactions.fixed_date_picker.today" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.YESTERDAY}>
              <Trans i18nKey="Transactions.fixed_date_picker.yesterday" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.LAST_WEEK}>
              <Trans i18nKey="Transactions.fixed_date_picker.last_week" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.MONTH_TO_DATE}>
              <Trans i18nKey="Transactions.fixed_date_picker.this_month" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.LAST_MONTH}>
              <Trans i18nKey="Transactions.fixed_date_picker.last_month" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.YEAR_TO_DATE}>
              <Trans i18nKey="Transactions.fixed_date_picker.this_year" />
            </Menu.Item>
            <Menu.Item key={FixedDatesRange.LAST_YEAR}>
              <Trans i18nKey="Transactions.fixed_date_picker.last_year" />
            </Menu.Item>
          </Menu>
        )
      }
    >
      <SelectButton>
        <Selection hasSelection={hasSelection}>
          <StyledText size="ui2">{_getDateRangeString(fixedRange)}</StyledText>
          <VibrantIcon icon="chevron-down" size={10} />
        </Selection>
        {hasSelection && clearable && (
          <ClearButton onClick={_clearSelection}>
            <VibrantIcon icon="close" size={10} />
          </ClearButton>
        )}
      </SelectButton>
    </Dropdown>
  );
};

/**
 * Exports
 */
export { FixedDatePicker };
