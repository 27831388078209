/**
 * Notes
 * https://stackoverflow.com/questions/63507722/how-to-override-antd-select-option-hover
 */
/**
 * Imports
 */
import { Select as AntdSelect } from 'antd';
import { styled } from 'theme';

/**
 * Styled components
 */
const Select = styled(AntdSelect)`
  && {
    .ant-select-selector {
      height: 42px;
      display: flex;
      align-items: center;
    }
    .ant-select-arrow {
      & svg {
        fill: none;
      }
    }
    // Dropdown item
    .ant-select-item {
      color: ${({ theme }) => theme.colors.Text.Heading};
      height: 42px;

      display: flex;
      align-items: center;
    }
    // Selected item
    .ant-select-item-option-active {
      background-color: ${({ theme: { colors } }) => colors.Background.Primary};
    }
  }
`;

Select.Option = styled(AntdSelect.Option)`
  && {
  }
`;

/**
 * Exports
 */
export { Select };
