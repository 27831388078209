/**
 * Imports
 */
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Icon } from 'components';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { styled } from 'theme';
interface CopyProps {
  margin: string | undefined;
}

/**
 * Styling
 */
const Copy = styled.div<CopyProps>`
  cursor: pointer;
  margin: ${(props) => (props.margin ? props.margin : 'inherit')};

  &:hover {
    .anticon {
      color: ${({ theme }) => theme.colors.Base.Brand};
    }
  }
`;

/**
 * Types
 */
export interface CopyButtonProps {
  text: string;
  margin?: string;
}

/**
 * CopyButton component
 */
const CopyButton: React.FC<CopyButtonProps> = ({ text, margin }) => {
  const _onCopy = () => {
    message.success('Copied');
  };
  return (
    <CopyToClipboard text={text} onCopy={_onCopy}>
      <Copy margin={margin}>
        <Icon icon={<CopyOutlined />} />
      </Copy>
    </CopyToClipboard>
  );
};

/**
 * Exports
 */
export { CopyButton };
