/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';

import {
  logout,
  userEmail,
  UserEmailAction,
  userUpdated,
  UserUpdatedAction,
} from './actions';
import type { Firebase } from 'types/firebase';

/**
 * Types
 */
export interface UserState {
  user: Firebase.User | null;
  email: string | null;
}

/**
 * Constants
 */
const INITIAL_STATE: UserState = {
  user: null,
  email: null,
};

/**
 * `user` reducer
 */
const userReducer = createReducer(INITIAL_STATE, {
  /**
   * USER_UPDATED
   */
  [userUpdated.type]: (state, action: UserUpdatedAction) => {
    state.user = action.payload;
  },
  /**
   * USER_EMAIL
   */
  [userEmail.type]: (state, action: UserEmailAction) => {
    state.email = action.payload.email;
  },
  /**
   * LOGOUT
   */
  [logout.type]: (state) => {
    state.user = null;
  },
});

/**
 * Exports
 */
export default userReducer;
