/**
 * Imports
 */
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { Space, Table, Text, VibrantIcon } from 'components';
//import { useSettlementsApi } from 'features/settlements';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { SoftPos } from 'types/softpos';
import { formatCurrency } from 'utils';
import * as Styles from './SettlementsTable.styles';

/**
 * Styling
 */
const StyledTooltip = styled(Tooltip)`
  margin-top: 4px;
  margin-left: 8px;
`;
/**
 * Types
 */
export interface SettlementsTableProps {}

/**
 * Table columns
 */
const columns: ColumnsType<SoftPos.Settlement> = [
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.period" />,
    dataIndex: 'startedAt',
    key: 'startedAt',
    render: (startedAt: string, record: SoftPos.Settlement) => {
      return (
        <>
          {moment(startedAt).format('DD.MM')}-
          {moment(record.endedAt).format('DD.MM')}
        </>
      );
    },
  },
  {
    title: () => (
      <Trans i18nKey="Settlements.table_header.transaction_amount" />
    ),
    dataIndex: 'grossAmount',
    key: 'grossAmount',
    render: (grossAmount: number, record: SoftPos.Settlement) => {
      return (
        <Text size="ui2">
          {formatCurrency(grossAmount / 100, record.settlementCurrencyCode)}
        </Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="Settlements.table_header.fee_amount" />,
    dataIndex: 'settlementFeeAmount',
    key: 'settlementFeeAmount',
    render: (settlementFeeAmount: number, record: SoftPos.Settlement) => {
      return (
        <Text size="ui2">
          {formatCurrency(
            settlementFeeAmount / 100,
            record.settlementCurrencyCode,
          )}
        </Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="Settlements.table_header.transfer_amount" />,
    dataIndex: 'settlementAmount',
    key: 'settlementAmount',
    render: (settlementAmount: number, record: SoftPos.Settlement) => {
      return (
        <Text size="ui2">
          {formatCurrency(
            settlementAmount / 100,
            record.settlementCurrencyCode,
          )}
        </Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.closed_at" />,
    dataIndex: 'closedAt',
    key: 'closedAt',
    render: (postedAt: string, record: SoftPos.Settlement) => {
      return <>{moment(postedAt).format('DD.MM.YYYY')}</>;
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.payout_date" />,
    dataIndex: 'payoutDate',
    key: 'payoutDate',
    render: (payoutDate: string, record: SoftPos.Settlement) => {
      return <>{moment(payoutDate).format('DD.MM.YYYY')}</>;
    },
  },
];

export interface SettlementTableProps {
  stlmItems: SoftPos.Settlement[];
}

/**
 * SettlementsTable component
 */
const SettlementsTable: React.FC<SettlementTableProps> = ({ stlmItems }) => {
  return (
    <Styles.Container>
      <Text size="ui2" bold>
        Settlements
      </Text>
      <Space />
      <Table<SoftPos.Settlement>
        columns={columns}
        dataSource={stlmItems}
        rowKey={(record) => record.transferId}
      />
    </Styles.Container>
  );
};

/**
 * Exports
 */
export { SettlementsTable };
