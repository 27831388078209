import { Button } from 'components';
import { styled } from 'theme';

export const PictureContainer = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :last-child {
    margin-left: 15px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const DownloadButton = styled(Button)`
  color: ${({ theme }) => theme.colors.Text.Brand};
`;
export const ChangeButton = styled(Button)`
  color: ${(props) => props.theme.colors.Text.Brand};
`;
