/**
 * Imports
 */
import * as React from 'react';
import { styled } from 'theme';
import Link4PayLogoImg from '../../assets/images/logo-link4pay.png';

/**
 * Styling
 */
const StyledLogo = styled.img<{ width: number }>`
  height: auto;
  width: ${({ width }) => `${width}px`};
`;

/**
 * Types
 */
export interface Link4PayLogoProps {
  width: number;
}

/**
 * Link4PayLogo component
 */
const Link4PayLogo: React.FC<Link4PayLogoProps> = ({ width }) => {
  return <StyledLogo width={width} src={Link4PayLogoImg} alt="Link4Pay" />;
};

/**
 * Exports
 */
export { Link4PayLogo };
