/**
 * Types
 */
export type SelectOption = {
  label: string;
  value: string;
};

/**
 * Get a readable text from a selected value option
 */
export const getOptionValueText = (
  values: SelectOption[],
  value: string | undefined,
): string | null => {
  const selectedObj = values.filter((opt) => opt.value === value);
  const selectedText = selectedObj.length > 0 ? selectedObj[0].label : null;
  return selectedText;
};
