/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import { Moment } from 'moment';
import { FixedDatesRange } from '../types';

import {
  GetTransctionsAction,
  getTransactions,
  setDates,
  SetDatesAction,
  GetTransctionAction,
  getSingleTransaction,
} from './actions';
import type { SoftPos } from 'types/softpos';

/**
 * Types
 */
export interface TrxnState {
  trxnItems: SoftPos.TrxnRaw[];
  trxnItem: SoftPos.TrxnRaw[];
  trxnCount: number;
  startDate: Moment;
  endDate: Moment;
  fixedRange: FixedDatesRange;
}

/**
 * Constants
 */
const INITIAL_STATE: TrxnState = {
  trxnItems: [],
  trxnItem: [],
  trxnCount: 0,
  startDate: moment().startOf('day'),
  endDate: moment().endOf('day'),
  fixedRange: FixedDatesRange.TODAY,
};

/**
 * `transacations` reducer
 */
const transacationsReducer = createReducer(INITIAL_STATE, {
  /**
   * SET_DATES
   */
  [setDates.type]: (state, action: SetDatesAction) => {
    state.endDate = action.payload.endDate;
    state.startDate = action.payload.startDate;
    state.fixedRange = action.payload.fixedRange;
  },
  /**
   * GET_TRANSACTION
   */
  [getSingleTransaction.type]: (state, action: GetTransctionAction) => {
    state.trxnItem = action.payload.trxn;
  },
  /**
   * GET_TRANSACTIONS
   */
  [getTransactions.type]: (state, action: GetTransctionsAction) => {
    state.trxnItems = action.payload.trxnItems;
    state.trxnCount = action.payload.trxnCount;
  },
});

/**
 * Exports
 */
export default transacationsReducer;
