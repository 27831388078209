/**
 * Imports
 */
import { CUSTOMER_FILES_STORAGE } from 'config';
import {
  getFirebaseInstance,
  resetOwnerFiles,
  updateOwnerFile,
} from 'firebaseAPI';
import * as React from 'react';
import { Firebase } from 'types/firebase';
import { getFileExtension } from 'utils/content-types';
const firebase = getFirebaseInstance();
/**
 * Types
 */
export type FilesApiAPIHandlers = {
  onDownloadImage: (fileUrl: string) => Promise<void>;
  onDeleteAllImages: (path: string, merchantId: string) => Promise<void>;
  onDeleteImage: (
    path: string,
    mid: string,
    fileIndex: number,
    oid: number,
  ) => Promise<void>;
  onUploadImage: (data: {
    file: File;
    storagePath: string;
    photoType: number;
    merchantId: string;
    ownerIndex: number;
  }) => Promise<void>;
};
export type FileUrlObj = {
  downloadUrl: string;
  storagePath: string;
  fileName: string;
  fileIndex: number;
};
export type FilesApiAPIType = {
  handlers: FilesApiAPIHandlers;
  isLoading: boolean;
  fileUrls: FileUrlObj[];
};
export interface FilesApiProps {
  owner?: Firebase.Owner;
}

/**
 * CONST
 */
const ID_ROOT_FOLDER = 'onboarding_photo_ids';

/**
 * useFilesApi
 */
const useFilesApi = ({ owner }: FilesApiProps): FilesApiAPIType => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileUrls, setFileUrls] = React.useState<FileUrlObj[]>([]);

  const _findUrl = async (fileUrl: string) => {
    const storage = firebase.storage();
    if (fileUrl.includes('acct_')) {
      return storage
        .refFromURL(`${CUSTOMER_FILES_STORAGE}/${fileUrl}`)
        .getDownloadURL();
    } else {
      return storage.ref(fileUrl).getDownloadURL();
    }
  };

  /**
   * Download image
   * @param - fileUrl a relative path on firebase storage
   */
  const _downloadImageFromPath = async (fileUrl: string): Promise<any> => {
    _findUrl(fileUrl)
      .then((url) => {
        const splittedFiledName = fileUrl.split('/');
        const fileName = `${splittedFiledName[1]}_${splittedFiledName[2]}`;

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        console.log('Error while downloading image', error);
      });
  };

  /**
   * Upload file
   */
  const _uploadImage = async (file: File, storagePath: string) => {
    const fileType = getFileExtension(file.type);
    const storagePathWithExt = `${storagePath.replace(
      /.jpeg|.png|.pdf/gi,
      '',
    )}.${fileType}`;
    const metadata = {
      cacheControl: 'public, max-age=900',
      contentType: file.type,
    };

    await firebase
      .storage()
      .ref()
      .child(storagePathWithExt)
      .put(file, metadata);

    _getImages();

    return storagePathWithExt;
  };

  /**
   * _getImageUrl
   */
  const _getImageUrl = (path: string, bucket?: string): Promise<string> => {
    const storage = firebase.storage();
    if (bucket) {
      return storage.refFromURL(`gs://${bucket}/${path}`).getDownloadURL();
    }
    return storage.ref(path).getDownloadURL();
  };

  /**
   * Delete document
   */
  const _deleteDocument = async (storagePath: string) => {
    const storageRef = firebase.storage();
    await storageRef.ref(storagePath).delete();
  };

  /**
   * GetImages
   */
  const _getImages = () => {
    if (!owner) {
      return;
    }
    const fileUrlMap = owner.fileUrl;
    setFileUrls([]);
    if (owner?.addressId) {
      _getImageUrl(owner.addressId, owner.bucketName).then((downloadUrl) => {
        const fileName = owner
          .addressId!.split('/')[2]
          .replace(/.jpeg|.png|.pdf/gi, '');
        return setFileUrls((prev) => [
          ...prev,
          {
            downloadUrl,
            storagePath: owner.addressId!,
            fileName,
            fileIndex: 1,
          },
        ]);
      });
    }
    if (owner?.photoId) {
      _getImageUrl(owner.photoId, owner.bucketName).then((downloadUrl) => {
        const fileName = owner
          .photoId!.split('/')[2]
          .replace(/.jpeg|.png|.pdf/gi, '');
        return setFileUrls((prev) => [
          ...prev,
          { downloadUrl, storagePath: owner.photoId!, fileName, fileIndex: 0 },
        ]);
      });
    }
    if (fileUrlMap != null) {
      Object.values(fileUrlMap).map((storagePath, fileIndex) => {
        _getImageUrl(storagePath).then((downloadUrl) => {
          const fileName = storagePath
            .split('/')[2]
            .replace(/.jpeg|.png|.pdf/gi, '');
          return setFileUrls((prev) => [
            ...prev,
            { downloadUrl, storagePath, fileName, fileIndex },
          ]);
        });
      });
    }
  };

  React.useEffect(() => {
    if (owner) {
      _getImages();
    }
  }, [owner, owner?.fileUrl]);

  const handlers: FilesApiAPIHandlers = {
    /**
     * onFilesApi
     */
    onDownloadImage: async (fileUrl) => {
      await _downloadImageFromPath(fileUrl);
    },
    /**
     * onDeleteAllImages
     */
    onDeleteAllImages: async (path: string, merchantId: string) => {
      const storageRef = firebase.storage();
      storageRef
        .ref(`${ID_ROOT_FOLDER}/${path}`)
        .listAll()
        .then(async (listResults) => {
          const promises = listResults.items.map((item) => {
            return item.delete();
          });
          await Promise.all(promises);
        });

      await resetOwnerFiles(merchantId);
    },
    /**
     * onDeleteImage
     */
    onDeleteImage: async (
      path: string,
      merchantId: string,
      photoType: number,
      ownerIndex: number,
    ) => {
      setIsLoading(true);
      await _deleteDocument(`${path}`);
      await updateOwnerFile(merchantId, photoType, null, ownerIndex);
      setIsLoading(false);
    },
    onUploadImage: async (data) => {
      setIsLoading(true);
      const { file, storagePath, photoType, merchantId, ownerIndex } = data;
      const path = await _uploadImage(file, storagePath);
      await updateOwnerFile(merchantId, photoType, path, ownerIndex);
      _getImages();
      setIsLoading(false);
    },
  };
  return { handlers, isLoading, fileUrls };
};

/**
 * Exports
 */
export { useFilesApi };
