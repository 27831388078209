import React, { useMemo } from 'react';
import { Typography } from 'antd';

import { BaseLayout } from 'features/navigation/components';
import { SignupsTable } from 'features/signups/components';
import { useSignups } from 'features/signups/hooks';

const SignupsPage: React.FC = () => {
  const { data } = useSignups();

  return (
    <BaseLayout>
      <Typography.Title>Signups</Typography.Title>
      <SignupsTable dataSource={data ?? []} />
    </BaseLayout>
  );
};

export { SignupsPage };
