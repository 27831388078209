/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import type { Firebase } from 'types/firebase';

/**
 * Constants
 */
const PREFIX = 'user';

/**
 * USER_UPDATED
 */
export const USER_UPDATED = `${PREFIX}/USER_UPDATED`;
export type UserUpdatedAction = VibrantlyAction<Firebase.User>;
export const userUpdated = createAction<UserUpdatedAction['payload']>(
  USER_UPDATED,
);

/**
 * USER_EMAIL
 */
export const USER_EMAIL = `${PREFIX}/USER_EMAIL`;
export type UserEmailAction = VibrantlyAction<{ email: string }>;
export const userEmail = createAction<UserEmailAction['payload']>(USER_EMAIL);

/**
 * LOGOUT
 */
export const LOGOUT = `${PREFIX}/LOGOUT`;
export type LogoutAction = VibrantlyAction<object>;
export const logout = createAction<LogoutAction['payload']>(LOGOUT);
