/**
 * Notes
 */
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
// https://www.iban.com/currency-codes
// https://www2.1010data.com/documentationcenter/beta/1010dataReferenceManual/DataTypesAndFormats/currencyUnitCodes.html
// https://en.wikipedia.org/wiki/ISO_4217

import { CountrySelectValues } from 'features/transactions';
/**
 * Convert currency from ISO 4417 (numeric code)
 */
export const GBP_NUM = 826;
export const EUR_NUM = 978;
export const DKK_NUM = 208;
export const NOK_NUM = 578;
export const currencyLookup = (currencyCode: number) => {
  if (currencyCode === GBP_NUM) {
    return 'GBP';
  } else if (currencyCode === EUR_NUM) {
    return 'EUR';
  } else if (currencyCode === DKK_NUM) {
    return 'DKK';
  } else if (currencyCode === NOK_NUM) {
    return 'NOK';
  } else {
    return null;
  }
};

export const countrySelectCurrencyLookup = (
  currencyValue: CountrySelectValues,
): number => {
  switch (currencyValue) {
    case 'DK':
    case 'GL':
    case 'FO':
    case 'ALL':
      return 208;
    default:
      return 978;
  }
};

export const formatCurrency = (
  amount: number,
  currencyIsoNumber?: string,
  language?: string,
): string | undefined => {
  try {
    // TODO: Get lang form  dashboard settings
    const lang = language ? language : 'da'; // en-GB | da

    if (currencyIsoNumber) {
      const formattedCurrency = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currencyIsoNumber,
      }).format(amount);

      return formattedCurrency;
    } else {
      const formattedCurrency = new Intl.NumberFormat(lang).format(amount);
      return formattedCurrency;
    }
  } catch (e) {
    console.log('error in currency');
  }
};
