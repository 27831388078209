/**
 * Imports
 */
import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { PROJECT_API_URL } from 'config';
import {
  MerchantFaroeIslandFormData,
  MerchantFormData,
  MerchantGreenlandFormData,
} from 'features/create-merchant/components';
import { getIdToken } from 'firebaseAPI';
import type { Firebase } from 'types/firebase';

/**
 * createFaroeIslandMerchant
 */
const createFaroeIslandMerchant = async (
  data: MerchantFaroeIslandFormData,
): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `${PROJECT_API_URL}/api/softpos/v1/merchants/create_fo_merchant`,
      data,
      {
        headers: { token: idToken },
      },
    );
    return response.data;
  } catch (exception) {
    const errMsg = exception?.response?.data?.exception?.message
      ? exception.response.data.exception.message
      : 'Failed to create FO merchant';
    throw errMsg;
  }
};
/**
 * createGreenlandMerchant
 */
const createGreenlandMerchant = async (
  data: MerchantGreenlandFormData,
): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `${PROJECT_API_URL}/api/softpos/v1/merchants/create_gl_merchant`,
      data,
      {
        headers: { token: idToken },
      },
    );
    return response.data;
  } catch (exception) {
    const errMsg = exception?.response?.data?.exception?.message
      ? exception.response.data.exception.message
      : 'Failed to create GL merchant';
    throw errMsg;
  }
};

/**
 * createMerchant
 */
const createMerchant = async (data: MerchantFormData): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `${PROJECT_API_URL}/api/softpos/v1/merchants/create_link4pay_merchant`,
      data,
      {
        headers: { token: idToken },
      },
    );
    return response.data;
  } catch (exception) {
    const errMsg = exception?.response?.data?.exception?.message
      ? exception.response.data.exception.message
      : 'Failed to create merchant';
    throw errMsg;
  }
};

/**
 * getMerchants
 */
const getMerchants = async (): Promise<Firebase.Merchant[] | null> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${PROJECT_API_URL}/api/v1/owner/merchants`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Firebase.Merchant[];
  } catch (error) {
    console.log(error);
    return null;
  }
};
/**
 * createSandboxUserAndMerchant
 */
const createSandboxUser = async (
  name: string,
  email: string,
  merchantId: string,
): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `${PROJECT_API_URL}/api/v2/sandbox/setup_sandbox`,
      { name, email, merchantId },
      {
        headers: { token: idToken },
      },
    );
    return response.data;
  } catch (exception) {
    const errMsg = exception?.response?.data?.exception?.message
      ? exception.response.data.exception.message
      : 'Failed to create sandboxUser';
    throw errMsg;
  }
};

/**
 * Exports
 */
export {
  createFaroeIslandMerchant,
  createGreenlandMerchant,
  getMerchants,
  createMerchant,
  createSandboxUser,
};
