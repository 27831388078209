/**
 * Imports
 */
import { Firebase } from 'types/firebase';
import { FirebaseCollectionsType, getFirebaseInstance } from 'firebaseAPI';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { flattenDeep } from 'lodash';
import { UploadFile } from 'antd/lib/upload/interface';
const firebase = getFirebaseInstance();

/**
 * Types
 */

export type AddOwnerAPIHandlers = {
  onAddOwner: (
    name: string,
    filesToUpload: {
      photoIdFile: { file: File; fileList: UploadFile[] };
      addressIdFile: { file: File; fileList: UploadFile[] };
    },
    ownerIndex: number,
  ) => Promise<void>;
};
export type AddOwnerAPIType = {
  handlers: AddOwnerAPIHandlers;
  isLoading: boolean;
};
export interface AddOwnerApiProps {
  userId: string;
  merchantId: string;
}

/**
 * useAddOwner
 */
const useAddOwner = ({
  merchantId,
  userId,
}: AddOwnerApiProps): AddOwnerAPIType => {
  const firestore = useFirestore();
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);
  /* In-use var */

  const _getStoragePathForImage = (
    rootName: string,
    userId: string,
    fileIndex: number,
    ownerIndex: string,
    fileType: string,
  ) => {
    if (fileIndex === 0) {
      return `${rootName}/${userId}/owner_${ownerIndex}_photoId.${fileType}`;
    } else {
      return `${rootName}/${userId}/owner_${ownerIndex}_addressId.${fileType}`;
    }
  };

  const _uploadFile = async (
    file: File,
    fileIndex: number,
    ownerIndex: string,
  ) => {
    // always use jpeg, could this be an issue?
    const fileType = file.type === 'image/png' ? 'jpeg' : 'jpeg';
    const storagePath = _getStoragePathForImage(
      'onboarding_photo_ids',
      userId,
      fileIndex,
      ownerIndex,
      fileType,
    );
    const metadata = {
      cacheControl: 'public, max-age=900',
      contentType: file.type,
    };
    await firebase.storage().ref().child(storagePath).put(file, metadata);

    return storagePath;
  };

  const _addOwner = async (owner: Firebase.Owner) => {
    await firestore
      .collection(FirebaseCollectionsType.MERCHANTS)
      .doc(merchantId)
      .update({
        ownerData: firestore.FieldValue.arrayUnion(owner),
      });
  };

  const handlers: AddOwnerAPIHandlers = {
    /**
     * onAddOwner
     */
    onAddOwner: async (name, filesToUpload, ownerIndex) => {
      setIsLoading(true);

      const storagePaths = await Promise.all([
        await Promise.all(
          filesToUpload.photoIdFile.fileList.map((file, fileIndex) =>
            _uploadFile(
              file.originFileObj as File,
              0,
              `${ownerIndex}_${fileIndex}`,
            ),
          ),
        ),
        await Promise.all(
          filesToUpload.addressIdFile.fileList.map((file, fileIndex) =>
            _uploadFile(
              file.originFileObj as File,
              1,
              `${ownerIndex}_${fileIndex}`,
            ),
          ),
        ),
      ]);
      const fileMap = flattenDeep(storagePaths).reduce((acc, item, index) => {
        return { ...acc, [index]: item };
      }, {});
      const owner: Firebase.Owner = {
        name,
        photoId: null,
        addressId: null,
        roleDirector: false,
        socialSecurityNumber: null,
        fileUrl: fileMap,
      };

      await _addOwner(owner);
    },
  };

  return { handlers, isLoading };
};

/**
 * Exports
 */
export { useAddOwner };
