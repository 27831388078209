import React from 'react';
import { Statistic, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { BaseLayout } from 'features/navigation/components';
import {
  completedMerchantsCountSelector,
  merchantsCountSelector,
  pendingClearhausMerchantsCountSelector,
  pendingVibrantMerchantsCountSelector,
} from 'features/merchants/redux';

import * as Styles from './OverviewPage.styles';
// import { emailLeadsCountSelector } from 'features/email-leads/redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { FirebaseCollectionsType } from 'firebaseAPI';
import { notFullyOnboardedCountSelector } from 'features/signups/redux';
import { isAdminSelector, userSelector } from 'features/user/redux/selectors';
import { useMerchants } from 'features/merchants/hooks';

const HomePage: React.FC = () => {
  const isAdmin = useSelector(isAdminSelector);
  const user = useSelector(userSelector);
  useMerchants();

  useFirestoreConnect({
    collection: FirebaseCollectionsType.SOFTPOS_ONBOARDING,
    storeAs: FirebaseCollectionsType.SIGNUPS,
    where: isAdmin ? undefined : ['countryCode', 'in', user.meta.countries],
  });

  const merchantsCount = useSelector(merchantsCountSelector);
  const completedMerchantsCount = useSelector(completedMerchantsCountSelector);
  const notFullyOnboardedCount = useSelector(notFullyOnboardedCountSelector);
  // const emailLeadsCount = useSelector(emailLeadsCountSelector);
  const pendingVibrantMerchantsCount = useSelector(
    pendingVibrantMerchantsCountSelector,
  );
  const pendingClearhausMerchantsCount = useSelector(
    pendingClearhausMerchantsCountSelector,
  );

  return (
    <BaseLayout>
      <Typography.Title>Overview</Typography.Title>

      <Styles.Container>
        <Styles.Card>
          <Statistic title="Total signup" value={merchantsCount} />
        </Styles.Card>

        <Styles.Card>
          <Statistic title="Complete Signup" value={completedMerchantsCount} />
        </Styles.Card>

        <Styles.Card>
          <Statistic
            title="Pending Vibrant"
            value={pendingVibrantMerchantsCount}
          />
        </Styles.Card>

        <Styles.Card>
          <Statistic
            title="Pending Clearhaus"
            value={pendingClearhausMerchantsCount}
          />
        </Styles.Card>

        <Styles.Card>
          <Statistic
            title="Not fully signed up"
            value={notFullyOnboardedCount}
          />
        </Styles.Card>

        {/* <Styles.Card>
          <Statistic title="Email leads collected" value={emailLeadsCount} />
        </Styles.Card> */}
      </Styles.Container>
    </BaseLayout>
  );
};

export { HomePage };
