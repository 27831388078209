/**
 * Imports
 */

import { FirebaseCollectionsType } from 'firebaseAPI';
import { useFirestore } from 'react-redux-firebase';
import { Firebase } from 'types/firebase';

/**
 * Types
 */
export type FilesApiAPIHandlers = {
  onCompleteOnboarding: (onboardingId: string) => Promise<void>;
};
export type FilesApiAPIType = {
  handlers: FilesApiAPIHandlers;
};

/**
 * useOnboardingApi
 */
const useOnboardingApi = (): FilesApiAPIType => {
  const firestore = useFirestore();

  const handlers: FilesApiAPIHandlers = {
    /**
     * onFilesApi
     */
    onCompleteOnboarding: async (onboardingId) => {
      try {
        console.log('onCompleteOnboarding', onboardingId);

        const currentDataRef = await firestore
          .collection(FirebaseCollectionsType.SOFTPOS_ONBOARDING)
          .doc(onboardingId);

        const resp = await currentDataRef.get();
        const data = resp.data() as Firebase.SoftPosOnboarding;

        const TEMP_DATA = 'TEMP';
        const mergedData = {
          businessActivityDescription: !!data.businessActivityDescription
            ? data.businessActivityDescription
            : TEMP_DATA,
          averageTransactionAmount: !!data.averageTransactionAmount
            ? data.averageTransactionAmount
            : -1,
          monthlyTurnover: !!data.monthlyTurnover ? data.monthlyTurnover : -1,
          bankRegistrationNumber: !!data.monthlyTurnover
            ? data.monthlyTurnover
            : TEMP_DATA,
          bankAccountNumber: !!data.monthlyTurnover
            ? data.monthlyTurnover
            : TEMP_DATA,
          iban: !!data.iban ? data.iban : TEMP_DATA,
        };

        await firestore
          .collection(FirebaseCollectionsType.SOFTPOS_ONBOARDING)
          .doc(onboardingId)
          .update({
            ...mergedData,
            onboardingDone: true,
            onboardedAt: Date.now(),
          });
      } catch (e) {
        console.log('error', e);
        throw e;
      }
    },
  };
  return { handlers };
};

/**
 * Exports
 */
export { useOnboardingApi };
