/**
 * Customer Files Bucket
 */
const getCustomerFilesStorage = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('dev.vibrant.one')) {
    console.log('using dev-customer-files');
    // return 'gs://vibrant-dev-customer-files';
    return 'gs://vibrant-development-customer-files';
  }
  if (hostname.includes('development.vibrant.one')) {
    console.log('using development-customer-files');
    return 'gs://vibrant-development-customer-files';
  }
  if (hostname.includes('softpos-support-dashboard.vibrant.one')) {
    console.log('using production-customer-files');
    return 'gs://vibrantly-production-customer-files';
  }
  return 'gs://vibrant-development-customer-files';
};
export const CUSTOMER_FILES_STORAGE = getCustomerFilesStorage();
/**
 * Project API url
 */
const getprojectApiUrl = () => {
  // return 'https://pos.dev-api.vibrant.app/';
  const hostname = window.location.hostname;
  if (hostname.includes('dev')) {
    return 'https://api.dev.vibrant.one';
  }
  if (hostname.includes('uat') || hostname.includes('vercel.app')) {
    return 'https://api.uat.vibrant.one';
  }
  if (hostname.includes('softpos-support-dashboard.vibrant.one')) {
    return 'https://api.vibrant.one';
  }
  return 'https://api.dev.vibrant.one';
};
export const PROJECT_API_URL = getprojectApiUrl();
/**
 * Firebase environment settings
 */
const getFirebaseConfig = () => {
  if (PROJECT_API_URL.includes('dev-api')) {
    console.log('Running partner');
    return {
      apiKey: 'AIzaSyAygs2N2rQPt5ZUeiNtuJ2Z6a-fS8LUDXk',
      authDomain: 'vibrant-development.firebaseapp.com',
      projectId: 'vibrant-development',
      storageBucket: 'vibrant-development.appspot.com',
      messagingSenderId: '896337117681',
      appId: '1:896337117681:web:c0451d72f57221ee1cc450',
      measurementId: 'G-SQ2J3ZGYZP',
    };
  }

  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      apiKey: 'AIzaSyDVDd4j87hxMN6VRnxKqV9Pv2ynjCDp_CI',
      authDomain: 'vibrant-fb-uat.firebaseapp.com',
      databaseURL: 'https://vibrant-fb-uat.firebaseio.com',
      projectId: 'vibrant-fb-uat',
      storageBucket: 'vibrant-fb-uat.appspot.com',
      messagingSenderId: '922162229168',
      appId: '1:922162229168:web:a5d51558ef31dfdf5a7075',
      measurementId: 'G-8XXKJTTVPC',
    };
  }
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      apiKey: 'AIzaSyAbIbKSw_SmtYM8WLqs9ZUoFsb07q7i28o',
      authDomain: 'vibrantly-production.firebaseapp.com',
      databaseURL: 'https://vibrantly-production.firebaseio.com',
      projectId: 'vibrantly-production',
      storageBucket: 'vibrantly-production.appspot.com',
      messagingSenderId: '836686013166',
      appId: '1:836686013166:web:fa331a808f47bc91c409ff',
      measurementId: 'G-8D35D7VEXC',
    };
  }
  if (PROJECT_API_URL.includes('development.vibrant.one')) {
    console.log('Running development');
    return {
      apiKey: 'AIzaSyAygs2N2rQPt5ZUeiNtuJ2Z6a-fS8LUDXk',
      authDomain: 'vibrant-development.firebaseapp.com',
      projectId: 'vibrant-development',
      storageBucket: 'vibrant-development.appspot.com',
      messagingSenderId: '896337117681',
      appId: '1:896337117681:web:c0451d72f57221ee1cc450',
      measurementId: 'G-SQ2J3ZGYZP',
    };
  }
  if (PROJECT_API_URL.includes('dev.vibrant.one')) {
    console.log('Running dev');
    return {
      apiKey: 'AIzaSyAygs2N2rQPt5ZUeiNtuJ2Z6a-fS8LUDXk',
      authDomain: 'vibrant-development.firebaseapp.com',
      projectId: 'vibrant-development',
      storageBucket: 'vibrant-development.appspot.com',
      messagingSenderId: '896337117681',
      appId: '1:896337117681:web:c0451d72f57221ee1cc450',
      measurementId: 'G-SQ2J3ZGYZP',
    };
  }
  // Using new firebase-development
  console.log('Running localhost');
  return {
    apiKey: 'AIzaSyAygs2N2rQPt5ZUeiNtuJ2Z6a-fS8LUDXk',
    authDomain: 'vibrant-development.firebaseapp.com',
    projectId: 'vibrant-development',
    storageBucket: 'vibrant-development.appspot.com',
    messagingSenderId: '896337117681',
    appId: '1:896337117681:web:c0451d72f57221ee1cc450',
    measurementId: 'G-SQ2J3ZGYZP',
  };
  // Using old firebase-dev
  // console.log('Running dev');
  // return {
  //   apiKey: 'AIzaSyCv-etousHNny0jlIiLLkXopYTyBJbeBLY',
  //   authDomain: 'staging-727fe.firebaseapp.com',
  //   databaseURL: 'https://staging-727fe.firebaseio.com',
  //   projectId: 'staging-727fe',
  //   storageBucket: 'staging-727fe.appspot.com',
  //   messagingSenderId: '400993474174',
  //   appId: '1:400993474174:web:08b58925daf158bfd42a2f',
  //   measurementId: 'G-KB8RSCC0PL',
  // };
};
export const FIREBASE_CONFIG = getFirebaseConfig();
/**
 * Project environment settings
 */
const getProjectConfig = () => {
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat project config');
    return {
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    };
  }
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production project config');
    return {
      environment: 'production',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
    };
  }
  console.log('Running dev project config');
  return {
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    sentryDsn:
      'https://73be25b50269457e92ad5a7c46d5afc1@o551933.ingest.sentry.io/5675936',
  };
};
export const PROJECT_CONFIG = getProjectConfig();
