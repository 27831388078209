import { createSelector } from '@reduxjs/toolkit';
import { VibrantlyState } from 'store/types';
import { validSignups } from 'features/signups/components';
import { Firebase } from 'types/firebase';

export const signupsStoreSelector = createSelector(
  (state: VibrantlyState) => state.firestore,
  (firestore) => firestore.ordered.signups,
);

export const validSignupsSelector = createSelector(
  [signupsStoreSelector],
  (root) =>
    root?.filter(
      (signup: Firebase.SoftPosOnboarding) =>
        !signup.onboardingDone && !signup.onboardedAt && validSignups(signup),
    ),
);

export const notFullyOnboardedCountSelector = createSelector(
  [validSignupsSelector],
  (root) => root?.length,
);
