/**
 * Types
 */
export const ALLOWED_IMAGE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
];
export const ALLOWED_CONTENT_TYPES =
  'application/msword, application/pdf, application/vnd.oasis.opendocument.spreadsheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png';
export type DocumentFileTypes =
  | 'application/msword'
  | 'application/pdf'
  | 'application/vnd.oasis.opendocument.spreadsheet'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'image/jpeg'
  | 'image/png';

export type FileExtensionType =
  | 'doc'
  | 'pdf'
  | 'docx'
  | 'ods'
  | 'xlsx'
  | 'png'
  | 'jpeg';

/**
 * Return file extension from content-type
 */
export const getFileExtension = (type: string): FileExtensionType => {
  if (type === ('application/msword' as DocumentFileTypes)) {
    return 'doc';
  } else if (type === ('application/pdf' as DocumentFileTypes)) {
    return 'pdf';
  } else if (
    type ===
    ('application/vnd.oasis.opendocument.spreadsheet' as DocumentFileTypes)
  ) {
    return 'ods';
  } else if (
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return 'xlsx';
  } else if (
    type ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return 'docx';
  } else if (type === ('image/jpeg' as DocumentFileTypes)) {
    return 'jpeg';
  } else if (type === ('image/png' as DocumentFileTypes)) {
    return 'png';
  } else {
    return 'jpeg';
  }
};
