import { Button, Col, Modal, Row, Tag } from 'antd';
import { Text } from 'components';
import { FirebaseCollectionsType } from 'firebaseAPI';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFirestoreConnect } from 'react-redux-firebase';
import type { Firebase } from 'types/firebase';
import { formatTime } from 'utils';
import * as Styles from './MerchantStatusDetails.styles';
import { useSelector } from 'react-redux';
import { partnerNameSelector } from 'features/merchants/redux';

export type MerchantStatusDetailsProps = {
  merchant: Firebase.Merchant;
  onChangeReadyForApplication: (
    readyForApplication: number | null,
  ) => Promise<void>;
};

const MerchantStatusDetails: React.FC<MerchantStatusDetailsProps> = (props) => {
  const [readyForApplication, setReadyForApplication] = useState(
    props.merchant.readyForApplication,
  );
  const { t } = useTranslation();
  const partnerName = useSelector(partnerNameSelector);
  useFirestoreConnect({
    collection: FirebaseCollectionsType.MERCHANTS,
    storeAs: FirebaseCollectionsType.MERCHANTS,
    doc: props.merchant.merchantId,
  });

  const partnerNameQuery = React.useMemo(() => {
    if (props.merchant?.partnerId) {
      return {
        collection: FirebaseCollectionsType.ACCOUNTS,
        storeAs: 'partnerAccount',
        doc: props.merchant.partnerId,
      };
    } else {
      return [];
    }
  }, [props.merchant?.partnerId]);
  useFirestoreConnect(partnerNameQuery);

  const createdAt = useMemo(() => {
    return formatTime(props.merchant.createdAt);
  }, [props.merchant.createdAt]);

  const handleToggleReadyForApplication = useCallback(() => {
    const readyForApplicationTemp = readyForApplication;
    const nextReadyForApplication =
      readyForApplicationTemp == null ? Date.now() : null;

    Modal.confirm({
      title: t('Global.dialogs.confirmation'),
      onOk: () => {
        setReadyForApplication(nextReadyForApplication);

        props.onChangeReadyForApplication(nextReadyForApplication).catch(() => {
          setReadyForApplication(readyForApplicationTemp);
        });
      },
    });
  }, [readyForApplication, props, t]);

  const vibrantStatusTag = useMemo(() => {
    const isApproved = Boolean(readyForApplication);
    const color = isApproved ? 'green' : 'orange';
    const translationKey = isApproved
      ? 'Global.statuses.approved'
      : 'Global.statuses.pending';

    return (
      <Styles.ClickableTag
        color={color}
        onClick={handleToggleReadyForApplication}
      >
        <Trans i18nKey={translationKey} /> (Vibrant)
      </Styles.ClickableTag>
    );
  }, [handleToggleReadyForApplication, readyForApplication]);

  const clearhausStatusTag = useMemo(() => {
    const isApproved = Boolean(props.merchant.clearhausKYCdone);
    const color = isApproved ? 'green' : 'orange';
    const translationKey = isApproved
      ? 'Global.statuses.approved'
      : 'Global.statuses.pending';

    return (
      <Tag color={color}>
        <Trans i18nKey={translationKey} /> (Clearhaus)
      </Tag>
    );
  }, [props.merchant.clearhausKYCdone]);

  const clearhausEcomStatusTag = useMemo(() => {
    const isApproved = Boolean(props.merchant?.clearhausEcomMerchantId);
    const color = isApproved ? 'green' : 'orange';
    const translationKey = isApproved
      ? 'Global.statuses.approved'
      : 'Global.statuses.pending';

    return (
      <Tag color={color}>
        <Trans i18nKey={translationKey} /> (Clearhaus Ecom)
      </Tag>
    );
  }, [props.merchant?.clearhausEcomMerchantId]);

  return (
    <Row>
      <Col span={8}>
        <Text size="ui3" bold>
          Merchant Trading Name: {props.merchant.merchantTradingName}
        </Text>

        <Text size="ui3" bold>
          Account Id: {props.merchant?.accountId}
        </Text>

        <Text size="ui3" bold>
          Merchant Id: {props.merchant.merchantId}
        </Text>

        <Text size="ui3" bold>
          Clearhaus MID POS: {props.merchant.clearhausMerchantId || 'N/A'}
        </Text>
        <Text size="ui3" bold>
          Clearhaus MID e-com / Payment link:{' '}
          {props.merchant.clearhausEcomMerchantId || 'N/A'}
        </Text>
        <Text size="ui3" bold>
          User Id: {props.merchant.onboardingId ?? 'N/A'}
        </Text>

        <Text size="ui3" bold>
          Company Registration Number:{' '}
          {props.merchant.companyRegistrationNumber}
        </Text>
        <Text size="ui3" bold>
          Created At: {createdAt}
        </Text>
        {props.merchant?.partnerId && (
          <Text size="ui3" bold>
            Created By Partner: {partnerName}
          </Text>
        )}
      </Col>
      <Col span={16}>
        <Row justify="end">
          {vibrantStatusTag}
          {clearhausStatusTag}
          {clearhausEcomStatusTag}
        </Row>
      </Col>
    </Row>
  );
};

export { MerchantStatusDetails };
