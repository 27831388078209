/**
 * Imports
 */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ProviderProps } from 'react-redux';

import userFeature from 'features/user';
import { getFirebaseInstance, getUser } from 'firebaseAPI';

import { rootReducer } from './reducer';

/**
 * Functions
 */
const storePromise: Promise<ProviderProps['store']> = new Promise(
  (resolve, reject) => {
    try {
      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
        ],
      });

      const firebase = getFirebaseInstance();

      firebase.auth().onAuthStateChanged(async (authState) => {
        if (authState == null) {
          console.log('User is not authenticated or logged out');
          store.dispatch(userFeature.redux.actions.logout({}));
        } else {
          const user = await getUser();

          if (user) {
            store.dispatch(userFeature.redux.actions.userUpdated(user));
          }
          store.dispatch(
            userFeature.redux.actions.userEmail({ email: authState.email! }),
          );
        }

        resolve(store);
      });
    } catch (error) {
      console.log('ERROR', error);
      reject(error);
    }
  },
);
/**
 * Redux store
 */
export { storePromise };
