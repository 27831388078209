import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { RadioChangeEvent, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import type { Firebase } from 'types/firebase';
import { Container } from 'features/transactions';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { SignupDetails } from 'features/signups/components/SignupDetails';
import { Box, Button, Icon, Table, Text } from 'components';
import { useSelector } from 'react-redux';
import { isAdminSelector } from 'features/user/redux/selectors';
import styled from 'styled-components';
import { useOnboardingApi } from 'features/signups/api';
import { updateOnboardingApi } from 'firebaseAPI/firebaseAPI';
import { Checkbox } from 'components/CheckBox/CheckBox';

export type OnboardingTableProps = {
  dataSource: Firebase.SoftPosOnboarding[];
};

const EllipsisWrapper = styled.div`
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const IbanWrapper = styled.div`
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledCheckBox = styled(Checkbox)`
  && {
    display: flex;
    flex-direction: right;
    padding-right: 3.9%;
    padding-bottom: 1%;
`;

/*
Onboarding

Created at
CVR- trading name
Phone - contactPhone
business-activity - businessActivityDescription
revenue - monthlyTurnover
bank - iban
ceo - ownerData[0]
owner ownerData[0].addressId
 */

export const validSignups = (
  row: Firebase.SoftPosOnboarding,
): Firebase.SoftPosOnboarding | undefined => {
  if (
    !row.addressLine1 &&
    !row.businessActivityDescription &&
    !row.city &&
    !row.companyRegistrationNumber &&
    !row.iban &&
    !row.merchantTradingName &&
    !row.monthlyTurnover &&
    !row.ownerData &&
    !row.contact?.contactEmail &&
    !row.contact?.fullName &&
    !row.contact?.phone
  ) {
    return;
  }

  return row;
};

const SignupsTable: React.FC<OnboardingTableProps> = ({ dataSource }) => {
  const {
    handlers: { onCompleteOnboarding },
  } = useOnboardingApi();
  const [removedSignups, setRemovedSignups] = useState<Map<string, boolean>>(
    new Map(),
  );
  const [showAll, setShowAll] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminSelector);
  const _handleFinishOnboarding = async (onboardingId: string) => {
    try {
      window.confirm('Are you sure you want to complete this user?')
        ? await onCompleteOnboarding(onboardingId)
        : null;
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    const hiddenMap = new Map();
    dataSource.forEach((onboarding) => {
      hiddenMap.set(onboarding.onboardingId, onboarding.removeFromSignup);
    });
    setRemovedSignups(hiddenMap);
  }, [dataSource]);

  const _onChangeSignupVisibility = async (
    e: RadioChangeEvent,
    onboardingId: string,
  ) => {
    const value = e.target.checked;
    await updateOnboardingApi(onboardingId, { removeFromSignup: value });
    const newMap = removedSignups;
    newMap.set(onboardingId, value);
    setRemovedSignups(newMap);
  };
  const _onCheckboxClick = (e: RadioChangeEvent) => {
    setShowAll(e.target.checked);
  };
  const columns: ColumnsType<Firebase.SoftPosOnboarding> = [
    {
      title: 'Created At',
      key: 'createdAt',
      sorter: (a, b) => b.createdAt - a.createdAt,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        const formatedTime = moment(record.createdAt).format(
          'DD.MM.YYYY - HH:mm',
        );
        return (
          <Typography.Text>
            <div>
              <Tooltip
                title={`User id:${record.onboardingId} - Country: ${record.countryCode}`}
              >
                {formatedTime}
              </Tooltip>
            </div>
          </Typography.Text>
        );
      },
    },
    {
      title: 'Registration Number',
      dataIndex: 'companyRegistrationNumber',
      key: 'companyRegistrationNumber',
      render: (companyRegistrationNumber, record) => {
        return (
          <EllipsisWrapper>
            <Typography.Text>{companyRegistrationNumber}</Typography.Text>
          </EllipsisWrapper>
        );
      },
    },
    {
      title: 'Trading Name',
      dataIndex: 'merchantTradingName',
      key: 'merchantTradingName',
      render: (_, record) => {
        const name = record.merchantTradingName || record.businessLegalName;
        return <Typography.Text>{name}</Typography.Text>;
      },
    },
    {
      title: 'Contact',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, record) => {
        return (
          <Box>
            <Typography.Text>{record.contact?.fullName}</Typography.Text>
            <Typography.Text>{record.contact?.contactEmail}</Typography.Text>
            <Typography.Text>{record.contact?.phone}</Typography.Text>
          </Box>
        );
      },
    },
    {
      title: 'Business Activity',
      key: 'businessActivityDescription',
      dataIndex: 'businessActivityDescription',
      render: (businessActivityDescription) => {
        return (
          <Tooltip title={businessActivityDescription}>
            <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
              {businessActivityDescription}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: 'Monthly turnover',
      key: 'monthlyTurnover',
      responsive: ['sm'],
      dataIndex: 'monthlyTurnover',
    },
    {
      title: 'IBAN',
      key: 'iban',
      responsive: ['sm'],
      dataIndex: 'iban',
      render: (iban, record) => {
        return (
          <Tooltip title={iban}>
            <IbanWrapper>{iban}</IbanWrapper>
          </Tooltip>
        );
      },
    },
    {
      title: 'CEO',
      key: 'ownerData',
      render: (_, record) => {
        if (!record.ownerData) {
          return [];
        }

        // the first in the array is the CEO
        return <Typography.Text>{record.ownerData[0].name}</Typography.Text>;
      },
    },
    {
      title: 'Documents',
      key: 'ownerData',
      render: (_, record) => {
        const ceo = record.ownerData && record.ownerData[0];
        const documentExists = ceo?.addressId && ceo?.photoId;
        return documentExists ? (
          <Icon icon={<CheckCircleOutlined />} color="Green300" />
        ) : (
          <Icon icon={<StopOutlined />} color="Red300" />
        );
      },
    },
    {
      title: 'Complete Onboarding',
      key: 'onboardingId',
      render: (_, record) => {
        if (isAdmin) {
          return (
            <Box>
              <Button
                type="primary"
                onClick={() => _handleFinishOnboarding(record.onboardingId)}
              >
                OK
              </Button>
            </Box>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Visibility',
      render: (_, record) => {
        return (
          <Checkbox
            checked={record.removeFromSignup}
            onChange={(e: RadioChangeEvent) =>
              _onChangeSignupVisibility(e, record.onboardingId)
            }
          >
            Hide
          </Checkbox>
        );
      },
    },
  ];

  return (
    <Container>
      <StyledCheckBox onChange={_onCheckboxClick}>
        Show Hidden Signups
      </StyledCheckBox>
      <Table
        dataSource={
          showAll
            ? dataSource
            : dataSource.filter(
                (onboarding) => !removedSignups.get(onboarding.onboardingId),
              )
        }
        columns={columns}
        size="middle"
        pagination={false}
        expandable={{
          expandedRowRender: (record: Firebase.SoftPosOnboarding) => {
            if (
              !record.contact ||
              Object.values(record.contact).every((value) => value === '')
            ) {
              return <Text size="ui2">Ask IT to get signup email</Text>;
            }

            return <SignupDetails contactInformation={record.contact} />;
          },
        }}
      />
    </Container>
  );
};

export { SignupsTable };
