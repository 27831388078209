/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.transactions;

export const trxnItemsSelector = createSelector(
  [rootSelector],
  (root) => root.trxnItems,
);
export const trxnCountSelector = createSelector(
  [rootSelector],
  (root) => root.trxnCount,
);
export const trxnItemSelector = createSelector(
  [rootSelector],
  (root) => root.trxnItem,
);
export const endDateSelector = createSelector(
  [rootSelector],
  (root) => root.endDate,
);
export const startDateSelector = createSelector(
  [rootSelector],
  (root) => root.startDate,
);
export const fixedRangeSelector = createSelector(
  [rootSelector],
  (root) => root.fixedRange,
);
