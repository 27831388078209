/**
 * Imports
 */
import * as React from 'react';
import { styled } from 'theme';
import { Sidebar } from '../Sidebar';
import { MobileMenu } from '../MobileMenu';
import useBreakpoint from 'hooks/useBreakpoint';

/**
 * Styling
 */
const Body = styled.div<{ fullHeight: boolean }>`
  display: flex;
  align-items: ${(props) => props.fullHeight && 'center'};
  flex: 1;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;

  @media (max-width: 600px) {
    padding: 24px 24px 0px 24px;
    max-width: 100vw;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

/**
 * Types
 */
export interface BaseLayoutProps {
  fullHeight?: boolean;
  overflow?: string;
}

/**
 * BaseLayout component
 */
const BaseLayout: React.FC<BaseLayoutProps> = ({
  overflow,
  children,
  fullHeight = false,
}) => {
  const { isXs, isSm } = useBreakpoint();
  const shouldShowSidebar = !isXs && !isSm;
  return (
    <Body fullHeight={fullHeight}>
      {shouldShowSidebar && <Sidebar />}
      <Column>
        {!shouldShowSidebar && <MobileMenu />}
        <Main>{children}</Main>
      </Column>
    </Body>
  );
};

/**
 * Exports
 */
export { BaseLayout };
