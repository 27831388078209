import { HomeOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormGroup } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';

import { styled } from 'theme';
import type { Firebase } from 'types/firebase';
import { getOptionValueText } from 'utils/OptionUtils';
import {
  AVERAGE_DELIVERY_TIME_OPTIONS,
  BINARY_OPTIONS,
  MERCHANT_PRODUCT_TYPE,
} from '../../../../constants';
/**
 * Styling
 */
const Container = styled.div``;

/**
 * Types
 */
export type MerchantEcomFormProps = {
  merchant: Firebase.Merchant;
  onSave: (name: string, value: string | number) => void;
};

type FormFieldType = {
  title: string;
  icon: React.ReactNode;
  inputs: {
    type?: string;
    label: string;
    alternativeLabel: string;
    name: string;
    value: string | number | null;
  }[];
};

/**
 * MerchantEcomForm component
 */
const MerchantEcomForm: React.FC<MerchantEcomFormProps> = (props) => {
  // @ts-ignore
  const formFields = useMemo<FormFieldType[]>(() => {
    const { merchant } = props;
    const merchantProductType =
      getOptionValueText(
        MERCHANT_PRODUCT_TYPE,
        merchant?.ecomInformation?.productType,
      ) ?? 'NOT SET';
    const dropShippingValue =
      getOptionValueText(
        BINARY_OPTIONS,
        merchant?.ecomInformation?.dropShipping?.toString(),
      ) ?? 'NOT SET';
    const recurringTransactionsValue =
      getOptionValueText(
        BINARY_OPTIONS,
        merchant?.ecomInformation?.recurringTransactions?.toString(),
      ) ?? 'NOT SET';
    const averageDeliveryValue =
      getOptionValueText(
        AVERAGE_DELIVERY_TIME_OPTIONS,
        merchant?.ecomInformation?.averageDeliveryTime,
      ) ?? 'NOT SET';
    return [
      {
        title: 'Ecom',
        icon: <HomeOutlined />,
        inputs: [
          {
            type: 'dropdown',
            label: 'Recurring transactions',
            alternativeLabel: 'Recurring transactions',
            name: 'ecomInformation.recurringTransactions',
            value: recurringTransactionsValue,
            dropdown: {
              placeholder: 'Select a type',
              values: BINARY_OPTIONS,
              options: BINARY_OPTIONS,
            },
          },
          {
            type: 'dropdown',
            label: 'Product type',
            alternativeLabel: 'Product type',
            name: 'ecomInformation.productType',
            value: merchantProductType,
            dropdown: {
              placeholder: 'Select a type',
              values: MERCHANT_PRODUCT_TYPE,
              options: MERCHANT_PRODUCT_TYPE,
            },
          },
          {
            type: 'dropdown',
            label: 'Drop shipping',
            alternativeLabel: 'Drop shipping',
            name: 'ecomInformation.dropShipping',
            value: dropShippingValue,
            dropdown: {
              placeholder: 'Select a type',
              values: BINARY_OPTIONS,
              options: BINARY_OPTIONS,
            },
          },
          {
            type: 'dropdown',
            label: 'Average delivery time',
            alternativeLabel: 'Average delivery time',
            name: 'ecomInformation.averageDeliveryTime',
            value: averageDeliveryValue,
            dropdown: {
              placeholder: 'Select a type',
              values: AVERAGE_DELIVERY_TIME_OPTIONS,
              options: AVERAGE_DELIVERY_TIME_OPTIONS,
            },
          },
        ],
      },
    ];
  }, [props]);

  return (
    <div>
      {formFields.map((form) => (
        <Container key={form.title}>
          <FormGroup icon={form.icon} title={form.title}>
            {form.inputs.map((input) => (
              <FormGroupItem
                {...input}
                key={input.name}
                onSave={(newValue) => props.onSave(input.name, newValue)}
              />
            ))}
          </FormGroup>
        </Container>
      ))}
    </div>
  );
};

export { MerchantEcomForm };
