import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Collapse, Empty } from 'antd';
import { FormGroup } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';
import React, { useState } from 'react';
import { Container } from './MerchantUserDetails.styles';
import type { Firebase } from 'types/firebase';
import { styled } from 'theme';
import { AddUserModal } from './AddUserModal';

const StyledEmpty = styled(Empty)`
  && {
    margin: 0;
  }
`;

export type MerchantUsersDetailsProps = {
  merchantId: string;
  users: Firebase.UserData[];
};

const MerchantUserDetails: React.FC<MerchantUsersDetailsProps> = (props) => {
  const [isForcingValidationOnIMEI, setIsForcingValidationOnIMEI] = useState(
    false,
  );
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <Container>
      <FormGroup
        icon={<UserOutlined />}
        title="User"
        onCreate={() => setModalOpen(true)}
      >
        <AddUserModal show={isModalOpen} change={setModalOpen} />
        {props.users.length > 0 ? (
          <Collapse>
            {props.users?.map((user) => (
              <Collapse.Panel key={user.userId} header={user.userId}>
                <FormGroupItem
                  label="userId"
                  name="userId"
                  alternativeLabel="userId"
                  value={user.userId}
                  restrictEdit={true}
                />
                <FormGroupItem
                  label="email"
                  name="email"
                  alternativeLabel="email"
                  value={user.email ? user.email : ''}
                  restrictEdit={true}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <StyledEmpty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Users"
          />
        )}
      </FormGroup>
    </Container>
  );
};

export { MerchantUserDetails };
