/**
 * Imports
 */
import { Alert, Input, Modal, Space } from 'antd';
import { Spinner, VibrantIcon } from 'components';
import * as React from 'react';
import { Firebase } from 'types/firebase';
import { createSandboxUser } from 'apis';

export interface SandboxModalProps {
  showSandboxModal: boolean;
  hideSandboxModal: () => void;
  merchant: Firebase.Merchant;
}

/**
 * AddOwnerModal component
 */
const SandboxModal: React.FC<SandboxModalProps> = ({
  merchant,
  showSandboxModal,
  hideSandboxModal,
}) => {
  const [name, setName] = React.useState<string>('');
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [successMsg, setSuccessMsg] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const _handleCancel = () => {
    hideSandboxModal();
  };

  const _handleOk = async () => {
    if (name && email) {
      setLoading(true);
      try {
        await createSandboxUser(name, email, merchant.merchantId);
        setSuccessMsg('Merchant and user successfully added to sandbox');
        setTimeout(() => {
          setSuccessMsg('');
          hideSandboxModal();
        }, 1000);
      } catch (e) {
        setErrorMsg(e as string);
        setTimeout(() => {
          setErrorMsg('');
        }, 3000);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Enable Vibrant Sandbox"
      visible={showSandboxModal}
      onCancel={_handleCancel}
      onOk={_handleOk}
      closeIcon={<VibrantIcon icon="close" size={20} />}
    >
      {loading ? (
        <Spinner showSpinner={true} />
      ) : (
        <Space direction="vertical">
          <Input
            addonBefore="Name"
            onChange={(e) => setName(e.currentTarget.value)}
            placeholder="Write name"
          />
          <Input
            addonBefore="Email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="Write sandbox email"
          />
          {errorMsg && <Alert message={errorMsg} type="error" />}
          {successMsg && <Alert message={errorMsg} type="success" />}
        </Space>
      )}
    </Modal>
  );
};

/**
 * Exports
 */
export { SandboxModal };
