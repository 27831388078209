import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Icon } from 'components';
import { Avatar, Skeleton, Upload } from 'antd';
import { Space, Text } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';
import { useFilesApi } from 'features/merchants/hooks';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import type { Firebase } from 'types/firebase';
import { ALLOWED_IMAGE_TYPES } from 'utils';
import { ViewPictureDialog } from '..';
import * as Styles from './MerchantOwnerForm.styles';
import { isKYCSelector } from 'features/user/redux/selectors';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const DeleteButton = styled.div`
  cursor: pointer;
  margin-left: 12px;
`;

export type MerchantOwnerFormProps = {
  owner: Firebase.Owner;
  ownerIndex: number;
  onSave: (name: string, value: string | number) => void;
};

const MerchantOwnerForm: React.FC<MerchantOwnerFormProps> = ({
  onSave,
  owner,
  ownerIndex,
}: MerchantOwnerFormProps) => {
  const isKYCAdmin = useSelector(isKYCSelector);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [dialogSrc, setDialogSrc] = useState<string>();
  const { merchantId } = useParams<{ merchantId: string }>();
  const {
    handlers: { onUploadImage, onDownloadImage, onDeleteImage },
    isLoading,
    fileUrls,
  } = useFilesApi({ owner });

  /**
   * _handleOpenDialog
   */
  const _handleOpenDialog = (url: string) => {
    setDialogVisible(true);
    setDialogSrc(url);
  };
  /**
   * _handleCloseDialog
   */
  const _handleCloseDialog = () => {
    setDialogVisible(false);
  };

  /**
   * _onUploadImage
   */
  const _onUploadImage = async (
    { file }: any,
    storagePath: string,
    photoType: number,
    merchantId: string,
    ownerIndex: number,
  ) => {
    await onUploadImage({
      file,
      storagePath,
      photoType,
      merchantId,
      ownerIndex,
    });
  };
  /**
   * _downloadPhoto
   */
  const _downloadPhoto = async (url: string) => {
    await onDownloadImage(url);
  };
  /**
   * _downloadPhoto
   */
  const _deletePhoto = async (
    path: string,
    mid: string,
    fileIndex: number,
    oid: number,
  ) => {
    if (
      confirm('Are you sure you want to delete this document? ' + path) == true
    ) {
      await onDeleteImage(path, mid, fileIndex, oid);
    } else {
    }
  };
  /**
   * formFields
   */
  const formFields = useMemo(() => {
    return [
      {
        label: 'Name',
        alternativeLabel: 'Change name',
        name: 'name',
        value: owner.name,
        hasCopyIcon: true,
      },
      {
        label: 'Social Security Number',
        alternativeLabel: 'Change Social Seecurity Number',
        name: 'socialSecurityNumber',
        value: owner.socialSecurityNumber,
        hasCopyIcon: true,
      },
    ];
  }, [owner]);

  return (
    <div>
      {fileUrls.map((url, index) => {
        const isPDF = url.downloadUrl.includes('.pdf');
        return (
          <Styles.PictureContainer key={index}>
            <Styles.AvatarContainer
              onClick={() => !isPDF && _handleOpenDialog(url.downloadUrl)}
            >
              {isLoading ? (
                <Skeleton.Avatar active={true} size={40} shape="square" />
              ) : !isPDF ? (
                <Avatar
                  src={url.downloadUrl}
                  icon={<UserOutlined />}
                  size="large"
                  shape="square"
                />
              ) : (
                <Avatar size="large" shape="square">
                  PDF
                </Avatar>
              )}
              <Space size="sm" vertical />
              {isPDF ? (
                <a href={url.downloadUrl} target="_blank" rel="noreferrer">
                  <Text size="ui3" link>
                    VIEW
                  </Text>
                </a>
              ) : (
                <Text size="ui3" link>
                  VIEW
                </Text>
              )}
            </Styles.AvatarContainer>
            <Upload
              beforeUpload={() => false}
              showUploadList={false}
              onChange={(file) =>
                _onUploadImage(
                  file,
                  url.storagePath,
                  url.fileIndex,
                  merchantId,
                  ownerIndex,
                )
              }
              accept={`${ALLOWED_IMAGE_TYPES}`}
            >
              <Styles.ChangeButton type="text">Change</Styles.ChangeButton>
            </Upload>
            <Styles.DownloadButton
              type="text"
              onClick={() => _downloadPhoto(url.storagePath)}
            >
              Download
            </Styles.DownloadButton>
            {isKYCAdmin && (
              <DeleteButton
                onClick={() =>
                  _deletePhoto(
                    url.storagePath,
                    merchantId,
                    url.fileIndex,
                    ownerIndex,
                  )
                }
              >
                <Icon icon={<DeleteOutlined />} />
              </DeleteButton>
            )}
          </Styles.PictureContainer>
        );
      })}

      {formFields.map((input) => (
        <FormGroupItem
          key={input.name}
          {...input}
          onSave={(newValue) => onSave(input.name, newValue)}
        />
      ))}

      <ViewPictureDialog
        visible={isDialogVisible}
        imageSrc={dialogSrc!}
        profileName={owner.name}
        onClose={_handleCloseDialog}
      />
    </div>
  );
};

export { MerchantOwnerForm };
