import styled from 'styled-components';
import { Table as AntdTable } from 'antd';

export const Table = styled(AntdTable)`
  & .ant-table {
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
    overflow-x: scroll;

    /* Responsive styles */
    @media (max-width: 1440px) {
      max-width: calc(100vw - 255px);
    }
    @media (max-width: 720px) {
      max-width: calc(100vw - 48px);
    }
  }

  & .ant-table-thead .ant-table-cell {
    background-color: transparent;
    padding: 8px 12px;
    color: ${(props) => props.theme.colors.Text.TableLabel};
    font-size: 13px;
    font-weight: bold;
  }

  & .ant-table-cell {
    cursor: pointer;
    padding: 8px 12px;
    border-bottom: ${(props) =>
      `1px solid ${props.theme.colors.Border.TableRowBorder}`};
  }

  .ant-table-column-sorters {
    padding: 0px;
  }

  & .ant-table-column-sorter {
    display: none;
  }

  & .ant-table-row:not(:last-child) .ant-table-cell {
    border: none;
  }

  & .ant-pagination-total-text {
    display: none;
  }

  .ant-table-pagination.ant-pagination {
    margin: 24px 0px 0px 0px;
  }

  & .ant-table-pagination {
    align-items: center;
  }

  & .ant-pagination-prev,
  .ant-pagination-next {
    height: auto;
    display: flex;
    justify-content: center;

    svg {
      color: ${(props) => props.theme.colors.Background.TableArrow};
    }
  }

  & .ant-pagination-item {
    border: none;
    font-size: 17px;
    color: ${(props) => props.theme.colors.Text.TablePaginationInactive};
  }

  & .ant-pagination-item-active a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.Text.TablePaginationActive};
  }
`;
