/**
 * Imports
 */
import * as React from 'react';

import { createDynamicLink } from 'apis/dynamicLinkAPI';
import {
  Box,
  Button,
  Text,
  Icon,
  messageInfo,
  Spinner,
  messageError,
} from 'components';
import { emailSelector, userSelector } from 'features/user/redux/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import { Firebase } from 'types/firebase';
import { CopyOutlined, QrcodeOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getUserDatas } from 'firebaseAPI';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
/**
 * Types
 */
export interface CreateQRLoginProps {
  terminal: Firebase.Terminal;
}

/**
 * CreateQRLogin component
 */
const CreateQRLogin: React.FC<CreateQRLoginProps> = (props) => {
  const [signInLink, setSignInLink] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [isfecthingUsers, setIsfecthingUsers] = React.useState(false);
  const [users, setUsers] = React.useState<Firebase.UserData[]>([]);
  const { merchantId } = useParams<{ merchantId: string }>();

  const fetchUsers = React.useCallback(async () => {
    setIsfecthingUsers(true);
    const resp = await getUserDatas(merchantId);
    if (resp?.length > 0) {
      setUsers(resp);
    } else {
      messageError({ content: 'Failed to get users' });
    }
    setIsfecthingUsers(false);
  }, [merchantId]);

  const _onCopy = (content: string) => {
    messageInfo({ content });
  };

  const _onCreateDynamicLink = async () => {
    setIsLoading(true);
    const resp = await createDynamicLink(
      // @ts-ignore
      selectedUserId,
      props.terminal.terminalId,
    );
    setIsLoading(false);
    if (resp?.data.url) {
      setSignInLink(resp.data.url);
    } else {
      messageError({ content: 'Failed to create QR link' });
    }
  };

  React.useEffect(() => {
    fetchUsers();
  }, [merchantId]);

  return (
    <Box
      minHeight={256}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Box>
        <Text size="h5">{props.terminal.name}</Text>
      </Box>
      {isfecthingUsers ? (
        <Spinner showSpinner />
      ) : (
        <Box>
          <Box flexDirection="column" mt="24PX">
            <Box display="flex" justifyContent="space-between">
              <Text size="p2">User</Text>
              <Box>
                <Select
                  placeholder="Select user"
                  // @ts-ignore
                  defaultValue={selectedUserId ? selectedUserId : undefined}
                  style={{ minWidth: 240 }}
                  // @ts-ignore
                  onChange={(userId) => setSelectedUserId(userId)}
                  options={users.map((user) => {
                    return {
                      label: user.email,
                      value: user.userId,
                    };
                  })}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={12}>
              <Text size="p2">Terminal ID</Text>
              <CopyToClipboard
                text={props.terminal.terminalId}
                onCopy={() => _onCopy('Copied')}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                >
                  <Text size="p2" bold>
                    {props.terminal.terminalId}
                  </Text>
                  <Box ml={12}>
                    <Icon icon={<CopyOutlined />} />
                  </Box>
                </Box>
              </CopyToClipboard>
            </Box>
          </Box>
          {signInLink && (
            <Box mt={24} alignItems="center" justifyContent="center">
              <QRCode value={signInLink} size={256} />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" mt={24}>
            <Button
              disabled={!selectedUserId}
              loading={isLoading}
              onClick={_onCreateDynamicLink}
              type="primary"
            >
              <Box display="flex" alignItems="center">
                <Box mr="8px">
                  {/* @ts-ignore */}
                  <Icon icon={<QrcodeOutlined />} color="Brand500" />
                </Box>
                <Text size="ui2"> Create QR login</Text>
              </Box>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

/**
 * Exports
 */
export { CreateQRLogin };
