import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { createTerminal } from 'apis';
import { useParams } from 'react-router-dom';
import { VibrantAPI } from 'types/vibrant';

export type TerminalFormData = {
  name: string;
  activationCode: string;
  descriptor: string;
};
export interface AddTerminalFormProps {
  handleCancel: () => void;
}

const AddTerminalForm: React.FC<AddTerminalFormProps> = (props) => {
  const [form] = Form.useForm();
  const { merchantId } = useParams<{ merchantId: string }>();

  const _onFinish = async (values: TerminalFormData) => {
    await createTerminal(values, merchantId);
    props.handleCancel();
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      onFinish={_onFinish}
      form={form}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input the name of the terminal!',
          },
          { max: 30, message: 'Name must be max 30 characters.' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="ActivationCode"
        name="activationCode"
        rules={[
          {
            required: true,
            message: 'Please input the activation code of the terminal!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Descriptor"
        name="descriptor"
        rules={[
          {
            required: true,
            message: 'Please input the descriptor of the terminal!',
          },
          { max: 21, message: 'Descriptor must be max 21 characters.' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Row justify="space-between">
          <Button type="default" onClick={props.handleCancel}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export { AddTerminalForm };
