import { styled } from 'theme';
import { Checkbox as AntdCheckbox } from 'antd';

/**
 * Dropdown override style
 */
const Checkbox = styled(AntdCheckbox)`
  && {
    flex-direction: row-reverse;
  }
`;

/**
 * Exports
 */
export { Checkbox };
