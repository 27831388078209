/**
 * Imports
 */
import { CUSTOMER_FILES_STORAGE } from 'config';
import { FirebaseCollectionsType, getFirebaseInstance } from 'firebaseAPI';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { UploadDocument, getFileExtension } from 'utils';
import { merchantSelector } from '../redux';
const firebase = getFirebaseInstance();
/**
 * Types
 */
export type DocumentsApiAPIHandlers = {
  onDeleteDocument: (fileName: string) => Promise<void>;
  onDownloadImage: (fileUrl: string) => Promise<void>;
  onGetDocuments: () => Promise<DocumentObj[]>;
  onUpdateCompanyDocuments: (document: UploadDocument) => Promise<void>;
};
export type DocumentsApiAPIType = {
  handlers: DocumentsApiAPIHandlers;
  isLoading: boolean;
  documents: DocumentObj[];
};
export interface DocumentsApiProps {
  userId: string;
  merchantId: string;
  accoutId: string;
  usesCustomerFiles: boolean;
}
/**
 * CONST
 */
const DOCUMENT_ROOT_FOLDER = 'onboarding_documents';
export type DocumentObj = {
  name: string;
  url: string;
};

/**
 * useDocumentsApi
 */
const useDocumentsApi = ({
  userId,
  merchantId,
  accoutId,
  usesCustomerFiles,
}: DocumentsApiProps): DocumentsApiAPIType => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [documents, setDocuments] = React.useState<DocumentObj[]>([]);
  const merchant = useSelector(merchantSelector(merchantId));
  const firestore = useFirestore();

  const rootFolderPath = React.useMemo(() => {
    if (usesCustomerFiles || !merchant.companyDocumentsPath) {
      return `${DOCUMENT_ROOT_FOLDER}/${accoutId}`;
    } else {
      return `${DOCUMENT_ROOT_FOLDER}/${userId}`;
    }
  }, [userId, merchantId]);

  const storageRef = React.useMemo(() => {
    if (usesCustomerFiles || !merchant.companyDocumentsPath) {
      return firebase.storage().refFromURL(CUSTOMER_FILES_STORAGE);
    } else {
      return firebase.storage().ref();
    }
  }, [userId, merchantId]);

  /**
   * Download file
   * @param - fileUrl a relative path on firebase storage
   */
  const _downloadFileFromPath = async (fileUrl: string): Promise<any> => {
    const folderPath = `${rootFolderPath}/${fileUrl}`;
    storageRef
      .child(folderPath)
      .getDownloadURL()
      .then((url) => {
        const splittedFiledName = fileUrl.split('/');
        const fileName = `${splittedFiledName[0]}`;

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {});
  };

  /**
   * Get path for documents
   */
  const _getStoragePathForDocument = (
    documentName: string,
    fileExtension: string,
  ) => {
    const timestamp = moment().format('DD.MM.YY_HH.mm');
    return `${rootFolderPath}/${documentName}_${timestamp}.${fileExtension}`;
  };

  /**
   * Upload document
   */
  const _uploadDocument = async (file: File, documentName: string) => {
    const fileExtension = getFileExtension(file.type);
    const storagePath = _getStoragePathForDocument(documentName, fileExtension);
    const metadata = {
      cacheControl: 'public, max-age=900',
      contentType: file.type,
    };
    await storageRef.child(storagePath).put(file, metadata);
    return storagePath;
  };

  /**
   * Delete document
   */
  const _deleteDocument = async (storagePath: string) => {
    await storageRef.child(storagePath).delete();
  };

  const handlers: DocumentsApiAPIHandlers = {
    /**
     * onDeleteDocument
     */
    onDeleteDocument: async (fileName: string) => {
      setIsLoading(true);
      await _deleteDocument(`${rootFolderPath}/${fileName}`);
      setIsLoading(false);
    },
    /**
     * onGetDocuments
     */
    onGetDocuments: async () => {
      setIsLoading(true);
      try {
        const resp = await storageRef.child(rootFolderPath).listAll();
        const documentsRef = resp.items;
        const documents = await Promise.all(
          documentsRef.map(async (doc) => {
            const url = await doc.getDownloadURL();
            return {
              name: doc.name,
              url,
            };
          }),
        );
        setIsLoading(false);
        return documents;
      } catch (error) {
        alert(JSON.stringify(error.message));
        setIsLoading(false);
        return [];
      }
    },
    /**
     * onUpdateCompanyDocuments
     */
    onUpdateCompanyDocuments: async (data) => {
      await _uploadDocument(data.file, data.name);
      if (!merchant.companyDocumentsPath) {
        await firestore
          .collection(FirebaseCollectionsType.MERCHANTS)
          .doc(merchant.merchantId)
          .update({
            companyDocumentsPath: rootFolderPath,
          });
      }
      await handlers.onGetDocuments();
    },
    /**
     * onDownloadImage
     */
    onDownloadImage: async (fileUrl) => {
      await _downloadFileFromPath(fileUrl);
    },
  };

  return { handlers, isLoading, documents };
};

/**
 * Exports
 */
export { useDocumentsApi };
