/**
 * Imports
 */
import moment from 'moment';

/**
 * CONST
 */
export const DATE_FORMAT_TIME = 'DD.MM.YYYY - HH:mm';
export const DATE_FORMAT_DATE = 'DD.MM.YYYY';
export const DATE_FORMATS = {
  time: 'DD.MM.YYYY - HH:mm',
  date: 'DD.MM.YYYY',
};

/**
 * Convert date to local time
 */
export const getLocalTime = (utcTime: string): string => {
  const utc = moment.utc(utcTime).clone().toLocaleString();
  return utc;
};

/**
 * formatTime
 */
export const formatTime = (time: number): string => {
  return moment(time).format(DATE_FORMAT_TIME);
};
