import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Firebase } from 'types/firebase';

export type OnboardingStore = {
  signups: Firebase.SoftPosOnboarding[];
};

const initialState: OnboardingStore = {
  signups: [],
};

const slice = createSlice({
  name: 'signups',
  initialState,
  reducers: {
    setSignups: (
      state,
      action: PayloadAction<Firebase.SoftPosOnboarding[]>,
    ) => {
      state.signups = action.payload;
    },
  },
});

const { actions, reducer } = slice;

export { actions, reducer };
