import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmailLead } from 'features/email-leads';

export type EmailLeadsStore = {
  emailLeads: EmailLead[];
};

const initialState: EmailLeadsStore = {
  emailLeads: [],
};

const slice = createSlice({
  name: 'emailLeads',
  initialState,
  reducers: {
    setEmailLeads: (state, action: PayloadAction<EmailLead[]>) => {
      state.emailLeads = action.payload;
    },
  },
});

const { actions, reducer } = slice;

export { actions, reducer };
