import { getEmailLeadsApi } from 'firebaseAPI';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { emailLeadsSelector, actions } from '../redux';

const useEmailLeads = () => {
  const [isLoading, setLoading] = useState(false);
  const data = useSelector(emailLeadsSelector);
  const dispatch = useDispatch();

  const getEmailLeads = async () => {
    try {
      setLoading(true);

      const leads = await getEmailLeadsApi();
      dispatch(actions.setEmailLeads(leads));
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    isLoading,
    getEmailLeads,
  };
};

export { useEmailLeads };
