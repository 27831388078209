/**
 * Imports
 */
import { Card, Statistic } from 'antd';
import { Box } from 'components';
import { CountrySelectValues } from 'features/transactions';
import { validTransaction } from 'features/transactions/helpers/validTransaction';
import { uniq } from 'lodash';
import * as React from 'react';
import { styled } from 'theme';
import { SoftPos } from 'types/softpos';
import { countrySelectCurrencyLookup, currencyLookup, EUR_NUM } from 'utils';

const dkEuroRate = 0.1342;
const noEuroRate = 0.095;

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  @media (max-width: 925px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > div {
      width: 100%;
      margin: 0 0 12px;
    }
  }
`;
const StyledCard = styled(Card)`
  @media (max-width: 600px) {
    width: 100%;
  }
  & .ant-card-body {
    @media (max-width: 600px) {
      padding: 16px 8px;
    }
  }
`;

/**
 * Types
 */
export interface KeyNumbersProps {
  trxnItems: SoftPos.TrxnRaw[];
  country: CountrySelectValues;
}

const calculateAllMoney = (trxnItems: SoftPos.TrxnRaw[]) => {
  const value = trxnItems.reduce((previousValue, currentT: SoftPos.TrxnRaw) => {
    if (validTransaction(currentT)) {
      if (currentT.merchantCountry === 'DK') {
        return previousValue + currentT.transactionAmount * dkEuroRate;
      }
      if (currentT.merchantCountry === 'FO') {
        return previousValue + currentT.transactionAmount * dkEuroRate;
      }
      if (currentT.merchantCountry === 'GL') {
        return previousValue + currentT.transactionAmount * dkEuroRate;
      }
      if (currentT.merchantCountry === 'NO') {
        return previousValue + currentT.transactionAmount * noEuroRate;
      }
      return previousValue + currentT.transactionAmount;
    }
    return previousValue;
  }, 0);
  return value;
};

/**
 * KeyNumbers component
 */
const KeyNumbers: React.FC<KeyNumbersProps> = ({ trxnItems, country }) => {
  const currencyNumber = countrySelectCurrencyLookup(country);
  const countryDefault: CountrySelectValues =
    country === 'ALL' ? 'DK' : country;
  return (
    <Root>
      <Box mr="12px">
        <StyledCard style={{ height: '100%' }}>
          <Statistic
            valueStyle={{ color: '#3f8600' }}
            title="Active Merchants"
            value={uniq(trxnItems.map((t) => t.merchantCode)).length}
          />
        </StyledCard>
      </Box>
      <Box mr="12px">
        <StyledCard style={{ height: '100%' }}>
          <Statistic
            valueStyle={{ color: '#3f8600' }}
            title="No. of transactions"
            value={trxnItems.filter((t) => validTransaction(t)).length}
          />
        </StyledCard>
      </Box>
      <Box mr="12px">
        <StyledCard style={{ height: '100%' }}>
          <Statistic
            valueStyle={{ color: '#3f8600' }}
            title={`Sum of ${countryDefault} transactions`}
            value={new Intl.NumberFormat(country === 'ES' ? 'es' : 'da', {
              style: 'currency',
              currency: currencyLookup(currencyNumber)!,
            }).format(
              trxnItems.reduce((previousValue, currentT: SoftPos.TrxnRaw) => {
                if (
                  validTransaction(currentT) &&
                  currentT.transactionCurrency === currencyNumber &&
                  currentT.merchantCountry === country
                ) {
                  return previousValue + currentT.transactionAmount;
                }
                return previousValue;
              }, 0),
            )}
            formatter={(value) => value}
            // suffix={currencyLookup(currencyNumber)}
          />
        </StyledCard>
      </Box>
      <Box mr="12px">
        <StyledCard style={{ height: '100%' }}>
          <Statistic
            valueStyle={{ color: '#3f8600' }}
            title={`Sum of All transactions`}
            value={new Intl.NumberFormat('es', {
              style: 'currency',
              currency: currencyLookup(EUR_NUM)!,
            }).format(calculateAllMoney(trxnItems))}
            formatter={(value) => value}
            // suffix={currencyLookup(currencyNumber)}
          />
        </StyledCard>
      </Box>
    </Root>
  );
};

/**
 * Exports
 */
export { KeyNumbers };
