import { Moment } from 'moment';
import type { SoftPos } from 'types/softpos';

/**
 * CONST
 */
export enum FixedDatesRange {
  NONE = 'NONE',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  LAST_MONTH = 'LAST_MONTH',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  LAST_YEAR = 'LAST_YEAR',
}
export declare namespace Transactions {
  ///////////////////////////////////////////////////////////////////////////////////
  // Redux
  ///////////////////////////////////////////////////////////////////////////////////
  export type GetTransctionsPayload = {
    trxnItems: SoftPos.TrxnRaw[];
    trxnCount: number;
  };
  export type GetTransctionPayload = {
    trxn: SoftPos.TrxnRaw[];
  };
  export type SetDatesPayload = {
    startDate: Moment;
    endDate: Moment;
    fixedRange: FixedDatesRange;
  };
}
