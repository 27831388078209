import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { firebaseLogout } from 'firebaseAPI';
import { UserAddOutlined } from '@ant-design/icons';
import { Logo, Space, Text, VibrantIcon, Icon } from 'components';
import { ROUTES } from 'router/RootRouter';
import * as Styles from './MobileMenu.styles';
import {
  emailSelector,
  isLink4PaySupporterSelector,
} from 'features/user/redux/selectors';

interface SidebarItems {
  icon: React.ReactNode;
  path: string;
  text: React.ReactNode;
  hide?: boolean;
}

const MobileMenu: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState('string');
  const email = useSelector(emailSelector);
  const isLink4PaySupporter = useSelector(isLink4PaySupporterSelector);
  const navigate = useHistory();
  const location = useLocation();

  useEffect(() => {
    const pathName = location.pathname.split('/')[1];
    setCurrentPath(`/${[pathName]}`);
  }, [location.pathname]);

  const sidebarItems: SidebarItems[] = useMemo(() => {
    return [
      {
        icon: <VibrantIcon icon="home" />,
        path: ROUTES.home.path,
        text: <Trans i18nKey="Sidebar.menu_items.home" />,
      },
      {
        icon: <VibrantIcon icon="transaction" />,
        path: ROUTES.transactions.path,
        text: <Trans i18nKey="Sidebar.menu_items.transactions" />,
      },
      {
        icon: <VibrantIcon icon="pin" />,
        path: ROUTES.merchants.path,
        text: <Trans i18nKey="Sidebar.menu_items.merchants" />,
      },
      {
        icon: <VibrantIcon icon="print" />,
        path: ROUTES.signups.path,
        text: <Trans i18nKey="Sidebar.menu_items.signups" />,
        hide: isLink4PaySupporter,
      },
      {
        icon: <Icon icon={<UserAddOutlined />} />,
        path: ROUTES['create-merchant'].path,
        text: <Trans i18nKey="Sidebar.menu_items.create_merchant" />,
        hide: !isLink4PaySupporter,
      },
    ];
  }, []);

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const navigateTo = (path: string) => {
    handleClose();
    navigate.push(path);
  };

  const handleLogout = async () => {
    await firebaseLogout();
    navigateTo(ROUTES.login.path);
  };

  return (
    <>
      <Styles.Wrapper onClick={handleOpen}>
        <VibrantIcon icon="menu" size={20} />
      </Styles.Wrapper>
      <Styles.Drawer
        visible={visible}
        width="100vw"
        placement="right"
        closable
        closeIcon={<VibrantIcon icon="close" size={20} />}
        onClose={handleClose}
      >
        <Styles.Container>
          <Styles.Header>
            <Logo size={24} single />
          </Styles.Header>
          <Styles.Content>
            <Styles.Top>
              {sidebarItems.map((item) => {
                if (item.hide) {
                  return null;
                }
                const isSelected = currentPath === item.path;
                return (
                  <Styles.Link
                    key={item.path}
                    $selected={isSelected}
                    onClick={() => navigateTo(item.path)}
                  >
                    <Text size="p1">{item.text}</Text>
                    <Icon
                      icon={item.icon}
                      size={20}
                      color={isSelected ? 'Orange500' : undefined}
                    />
                  </Styles.Link>
                );
              })}
            </Styles.Top>
            <Styles.Bottom>
              <Space size="lg" />
              <Space size="xs" />
              <Text size="ui3" color="ui">
                {email}
              </Text>
              <Space size="xs" />
              <Text size="ui3" color="ui" onClick={handleLogout}>
                <Trans i18nKey="Global.actions.btn_logout" />
              </Text>
            </Styles.Bottom>
          </Styles.Content>
          <Styles.Footer>
            <Styles.Row>
              <Space size="xs" vertical />
            </Styles.Row>
          </Styles.Footer>
        </Styles.Container>
      </Styles.Drawer>
    </>
  );
};

export { MobileMenu };
