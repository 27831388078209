import { Typography } from 'antd';
import { BaseLayout } from 'features/navigation/components';
import React from 'react';
import { CreateMerchantForm } from '../components';

const CreateGreenlandMerchantPage: React.FC = () => {
  return (
    <BaseLayout>
      <Typography.Title>Create Greenland Merchant</Typography.Title>
      <CreateMerchantForm />
    </BaseLayout>
  );
};

export { CreateGreenlandMerchantPage };
