import React, { useState, Fragment } from 'react';
import { Modal } from 'antd';
import { AddTerminalForm } from './AddTerminalForm';
import styled from 'styled-components';
import { Button, Text } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { hideAddTerminalModal } from 'features/navigation/redux/actions';
import { showAddTerminalModalSelector } from 'features/navigation/redux/selectors';

export const AddButton = styled(Button)`
  color: ${(props) => props.theme.colors.Text.Brand};
`;

const AddTerminalModal = () => {
  const showTerminalModal = useSelector(showAddTerminalModalSelector);
  const dispatch = useDispatch();

  const _handleCancel = () => {
    dispatch(hideAddTerminalModal({}));
  };

  return (
    <Modal
      title="Add Terminal"
      visible={showTerminalModal}
      onCancel={_handleCancel}
      footer={null}
      okText="Create"
    >
      <AddTerminalForm handleCancel={_handleCancel} />
    </Modal>
  );
};

export { AddTerminalModal };
