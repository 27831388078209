/**
 * Imports
 */
import { PaginationProps, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { CreditCard, Space, Table, Text } from 'components';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router/RootRouter';
import { styled } from 'theme';
import type { SoftPos } from 'types/softpos';
import { currencyLookup, DATE_FORMATS, formatCurrency } from 'utils';
import { ColumnSearchProps } from 'utils/ColumnSearchProps';
import { TransactionsDetails } from '../TransactionsDetails';

/**
 * Constants
 */
const RESPONSE_CODE_APPROVED = '00';

/**
 * Styling
 */
export const Container = styled.div`
  margin-top: 16px;

  & .ant-table .ant-table-thead .ant-table-cell {
    font-size: 11px;
  }

  & .ant-table .ant-table-row .ant-table-cell {
    font-size: 11px !important;
  }

  & .normal {
  }
  & .refund {
    background-color: #f7e47e;
  }
`;
const StyledTag = styled(Tag)`
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Private helpers
 */
const _mapTrxnStatus = (key: string) => {
  const m: any = {
    A: {
      label: 'Advice',
      color: 'orange',
    },
    S: {
      label: 'Suspicious',
      color: 'red',
    },
    F: {
      label: 'Reject',
      color: 'red',
    },
    R: {
      label: 'Reversed',
      color: 'blue',
    },
    V: {
      label: 'Void',
      color: 'blue',
    },
    N: {
      label: 'Normal',
      color: 'green',
    },
    I: {
      label: 'Invalid',
      color: 'red',
    },
    P: {
      label: 'Problem',
      color: 'red',
    },
  };
  return m[key];
};

/**
 * CONST
 */
const TRANSACTION_STATUS_FILTERS = [
  {
    text: 'Advice',
    value: 'A',
  },
  {
    text: 'Suspicious',
    value: 'S',
  },
  {
    text: 'Reject',
    value: 'F',
  },
  {
    text: 'Reversed',
    value: 'R',
  },
  {
    text: 'Void',
    value: 'V',
  },
  {
    text: 'Normal',
    value: 'N',
  },
  {
    text: 'Invalid',
    value: 'I',
  },
  {
    text: 'Problem',
    value: 'P',
  },
];

/**
 * Types
 */
type MerchantFilterOpt = { value: string; text: string };
export interface TransactionsTableProps {
  trxnItems: SoftPos.TrxnRaw[];
  paginate: false | undefined;
  isFetching: boolean;
  tableChangeHandler: (pagination: PaginationProps) => void;
  merchantsFilterOpt: MerchantFilterOpt[];
}

/**
 * TransactionsTable component
 */
const TransactionsTable: React.FC<TransactionsTableProps> = ({
  trxnItems,
  paginate,
  isFetching,
  tableChangeHandler,
  merchantsFilterOpt,
}) => {
  const history = useHistory();
  /**
   * Table columns
   */
  const columns: ColumnsType<SoftPos.TrxnRaw> = React.useMemo(() => {
    return [
      {
        title: <Trans i18nKey="Transactions.table_header.card_source" />,
        dataIndex: 'cardSource',
        responsive: ['md'],
        key: 'cardSouce',
        render: (cardSource: SoftPos.CardSource) => {
          return <CreditCard size="md" card={cardSource} />;
        },
      },
      {
        title: <Trans i18nKey="Transactions.table_header.date" />,
        dataIndex: 'utcTime',
        key: 'utcTime',
        render: (utcTime: string) => {
          return (
            <Text size="ui2">{moment(utcTime).format(DATE_FORMATS.time)}</Text>
          );
        },
      },
      {
        title: <Trans i18nKey="Transactions.table_header.terminal_id" />,
        dataIndex: 'terminalCode',
        sorter: {
          compare: (a: any, b: any) => a.terminalCode - b.terminalCode,
        },
        responsive: ['md'],
        key: 'terminalCode',
        ...ColumnSearchProps('terminalCode'),
        render: (terminalCode: string) => {
          return <Text size="ui2">{terminalCode}</Text>;
        },
      },
      {
        title: <Trans i18nKey="Transactions.table_header.merchant_id" />,
        dataIndex: 'merchantCode',
        sorter: {
          compare: (a: any, b: any) => a.merchantCode - b.merchantCode,
        },
        responsive: ['md'],
        key: 'merchantCode',
        ...ColumnSearchProps('merchantCode'),
        render: (merchantId: string) => {
          return <Text size="ui2">{merchantId}</Text>;
        },
      },
      {
        title: <Trans i18nKey="Transactions.table_header.merchant_name" />,
        dataIndex: 'merchantName',
        responsive: ['md'],
        sorter: {
          compare: (a: any, b: any) =>
            a.merchantName > b.merchantName ? 1 : -1,
        },
        key: 'merchantName',
        filters: merchantsFilterOpt,
        onFilter: (value, record) => {
          return record.merchantName === value;
        },
        render: (merchantName: string) => {
          return <Text size="ui2">{merchantName}</Text>;
        },
      },
      {
        title: <Trans i18nKey="Transactions.table_header.txnEffect" />,
        dataIndex: 'txnEffect',
        responsive: ['md'],
        key: 'txnEffect',
        render: (txnEffect: SoftPos.TrxnRaw['txnEffect']) => (
          <Text size="ui2">{txnEffect === 'D' ? 'Sale' : 'Refund'}</Text>
        ),
      },
      {
        title: () => (
          <Trans i18nKey="SettlementDetails.table_header.last_four" />
        ),
        dataIndex: 'cardNo',
        responsive: ['md'],
        key: 'cardNo',
        ...ColumnSearchProps('cardNo'),
        render: (cardNo: string) => {
          return (
            <Text size="ui2">**{cardNo?.substring(cardNo?.length - 4)}</Text>
          );
        },
      },
      {
        title: <Trans i18nKey="Transactions.table_header.amount" />,
        dataIndex: 'transactionAmount',
        key: 'transactionAmount',
        sorter: {
          compare: (a: any, b: any) =>
            b.transactionAmount - a.transactionAmount,
        },
        render: (transactionAmount: number, record: SoftPos.TrxnRaw) => {
          const alphabeticalCurrency = currencyLookup(
            record.transactionCurrency,
          );
          const formatted = alphabeticalCurrency
            ? formatCurrency(transactionAmount, alphabeticalCurrency)
            : 'N/A';
          return <Text size="ui2">{formatted}</Text>;
        },
      },
      {
        title: <Trans i18nKey="Transactions.table_header.responseCode" />,
        dataIndex: 'responseCode',
        responsive: ['md'],
        key: 'responseCode',
        sorter: {
          compare: (a: any, b: any) => a.responseCode - b.responseCode,
        },
        ...ColumnSearchProps('responseCode'),
        render: (responseCode: SoftPos.TrxnRaw['responseCode']) => (
          <Text size="ui2">{responseCode}</Text>
        ),
      },
      {
        title: (
          <Trans i18nKey="Transactions.table_header.responseDescription" />
        ),
        dataIndex: 'responseDescription',
        key: 'responseDescription',
        render: (
          responseDescription: SoftPos.TrxnRaw['responseDescription'],
          record,
        ) => (
          <Tooltip title={responseDescription}>
            <StyledTag
              color={
                record.responseCode !== RESPONSE_CODE_APPROVED
                  ? 'orange'
                  : 'green'
              }
            >
              {responseDescription}
            </StyledTag>
          </Tooltip>
        ),
      },
      {
        title: <Trans i18nKey="Transactions.table_header.trxn_status" />,
        dataIndex: 'transactionStatus',
        key: 'transactionStatus',
        filters: TRANSACTION_STATUS_FILTERS,
        onFilter: (value, record) => {
          return (
            record.transactionStatus &&
            record.transactionStatus.includes(value as string)
          );
        },
        render: (transactionStatus: string) => {
          const status = _mapTrxnStatus(transactionStatus);
          return <Tag color={status?.color}>{status?.label}</Tag>;
        },
      },
    ];
  }, [merchantsFilterOpt]);

  return (
    <Container>
      <Text size="ui2" bold>
        Transactions
      </Text>
      <Space size="sm" />
      <Table<SoftPos.TrxnRaw>
        rowClassName={(record: SoftPos.TrxnRaw) => {
          return record.txnEffect === 'D' ? 'normal' : 'refund';
        }}
        columns={columns}
        dataSource={trxnItems}
        loading={isFetching}
        onChange={tableChangeHandler}
        pagination={paginate}
        rowKey={(record) => record.txnGuid}
        onRow={(record) => {
          return {
            onClick: () =>
              history.push(`${ROUTES.merchants.route}/${record.merchantCode}`),
          };
        }}
        expandable={{
          expandedRowRender: (record: SoftPos.TrxnRaw) => (
            <TransactionsDetails trxnItem={record} />
          ),
        }}
      />
    </Container>
  );
};

/**
 * Exports
 */
export { TransactionsTable };
