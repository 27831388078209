import { HomeOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormGroup } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';

import { styled } from 'theme';
import type { Firebase } from 'types/firebase';
import { getOptionValueText } from 'utils/OptionUtils';
import { MERCHANT_PAYMENT_TYPE } from '../../../../constants';
/**
 * Styling
 */
const Container = styled.div``;

/**
 * Types
 */
export type MerchantTypeProps = {
  merchant: Firebase.Merchant;
  onSave: (name: string, value: string | number) => void;
};

type FormFieldType = {
  title: string;
  icon: React.ReactNode;
  inputs: {
    type?: string;
    label: string;
    alternativeLabel: string;
    name: string;
    value: string | number | null;
  }[];
};

/**
 * MerchantType component
 */
const MerchantType: React.FC<MerchantTypeProps> = (props) => {
  const { merchant } = props;
  const merchantTypeValue =
    getOptionValueText(MERCHANT_PAYMENT_TYPE, merchant?.merchantPaymentType) ??
    'NOT SET';
  const canAddEcomId = !!merchant?.campaign && merchant?.clearhausEcomApiKey;
  // @ts-ignore
  const formFields = useMemo<FormFieldType[]>(() => {
    return [
      {
        title: 'Merchant Type',
        icon: <HomeOutlined />,
        inputs: [
          {
            type: 'dropdown',
            label: 'Merchant Type',
            alternativeLabel: 'Merchant types',
            name: 'merchantPaymentType',
            value: merchantTypeValue,
            dropdown: {
              placeholder: 'Select a type',
              values: MERCHANT_PAYMENT_TYPE,
              options: MERCHANT_PAYMENT_TYPE,
            },
          },
          {
            type: 'input',
            label: 'Clearhaus Ecom Merchant Id (campaign must be set)',
            alternativeLabel: 'Clearhaus Ecom Merchant Id',
            name: 'clearhausEcomMerchantId',
            restrictEdit: !canAddEcomId,
            value: merchant?.clearhausEcomMerchantId ?? 'Not set',
          },
          {
            type: 'input',
            label: 'Clearhaus Ecom API Key',
            alternativeLabel: 'Clearhaus Ecom API Key',
            name: 'clearhausEcomApiKey',
            value: merchant?.clearhausEcomApiKey ?? 'Not set',
          },
        ],
      },
    ];
  }, [merchant]);

  return (
    <div>
      {formFields.map((form) => (
        <Container key={form.title}>
          <FormGroup icon={form.icon} title={form.title}>
            {form.inputs.map((input) => (
              <FormGroupItem
                {...input}
                key={input.name}
                onSave={(newValue) => props.onSave(input.name, newValue)}
              />
            ))}
          </FormGroup>
        </Container>
      ))}
    </div>
  );
};

export { MerchantType };
