import styled from 'styled-components';
import { Button, Text } from 'components';
import { CopyButton } from 'components/CopyButton';

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Container = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;
  border-top: 1px solid;
  border-color: ${(props) => props.theme.colors.Background.Primary};

  &:last-child {
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.colors.Background.Primary};
  }
`;

export const LabelContainer = styled.div``;

export const InputContainer = styled.form`
  margin-top: 16px;
`;

export const InputRow = styled(Row)`
  margin-top: 8px;
`;

export const ChangeButton = styled(Button)`
  color: ${(props) => props.theme.colors.Text.Brand};
`;

export const SaveButton = styled(Button)`
  margin-left: 12px;
  background-color: ${(props) => props.theme.colors.Background.Brand};
  color: ${(props) => props.theme.colors.Text.White};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.Background.Brand};
    color: ${(props) => props.theme.colors.Text.White};
  }
`;

export const ValueText = styled(Text)`
  margin-top: 8px;
  white-space: pre-wrap;
`;

export const StyledDiv = styled.div`
  display: flex;
`;
