import { isAdminSelector, userSelector } from 'features/user/redux/selectors';
import { FirebaseCollectionsType } from 'firebaseAPI';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import type { Firebase } from 'types/firebase';
import { validSignupsSelector } from '../redux';

export type useSignupsProps = () => {
  data: Firebase.SoftPosOnboarding[];
  isLoading: boolean;
};

const useSignups: () => { data: Firebase.SoftPosOnboarding[] } = () => {
  const data = useSelector(validSignupsSelector);
  const isAdmin = useSelector(isAdminSelector);
  const user = useSelector(userSelector);

  useFirestoreConnect({
    collection: FirebaseCollectionsType.SOFTPOS_ONBOARDING,
    storeAs: FirebaseCollectionsType.SIGNUPS,
    where: [
      isAdmin
        ? ['onboardingDone', '==', false]
        : ['countryCode', 'in', user.meta.countries],
      ['onboardingDone', '==', false],
    ],
    orderBy: ['createdAt', 'desc'],
  });

  return { data };
};

export { useSignups };
