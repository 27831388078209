import { UserAddOutlined } from '@ant-design/icons';
import { Icon, Logo, Text, VibrantIcon, Link4PayLogo } from 'components';
import {
  collapseSidebar,
  openSidebar,
} from 'features/navigation/redux/actions';
import { sidebarCollapsedSelector } from 'features/navigation/redux/selectors';
import {
  emailSelector,
  isGreenlandSupporterSelector,
  isLink4PaySupporterSelector,
  isSoftPosSupportSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { firebaseLogout } from 'firebaseAPI';
import React, { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
/**
 * Imports
 */
import { ROUTES } from 'router/RootRouter';
import * as Styles from './Sidebar.styles';
import { AppButton } from './Sidebar.styles';

/**
 * Types
 */
export interface SidebarProps {}

/**
 * Sidebar component
 */
const Sidebar: React.FC<SidebarProps> = (_props) => {
  const [currentPath, setCurrentPath] = React.useState('string');
  const collapsed = useSelector(sidebarCollapsedSelector);
  const email = useSelector(emailSelector);
  const user = useSelector(userSelector);
  const isLink4PaySupporter = useSelector(isLink4PaySupporterSelector);
  const isGreenlandSupporter = useSelector(isGreenlandSupporterSelector);
  const isSoftPosSupport = useSelector(isSoftPosSupportSelector);
  const isFromFaroeIsland = user?.meta?.countries?.includes('FO');
  const navigate = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const sidebarItems = useMemo(() => {
    return [
      {
        icon: <VibrantIcon icon="home" />,
        path: ROUTES.home.path,
        text: <Trans i18nKey="Sidebar.menu_items.home" />,
      },
      {
        icon: <VibrantIcon icon="transaction" />,
        path: ROUTES.transactions.path,
        text: <Trans i18nKey="Sidebar.menu_items.transactions" />,
      },
      {
        icon: <VibrantIcon icon="pin" />,
        path: ROUTES.merchants.path,
        text: <Trans i18nKey="Sidebar.menu_items.merchants" />,
      },
      {
        icon: <VibrantIcon icon="print" />,
        path: ROUTES.signups.path,
        text: <Trans i18nKey="Sidebar.menu_items.signups" />,
        hide: isLink4PaySupporter,
      },
      {
        icon: <Icon icon={<UserAddOutlined />} />,
        path: ROUTES['create-merchant'].path,
        text: <Trans i18nKey="Sidebar.menu_items.create_merchant" />,
        hide: !isLink4PaySupporter,
      },
      {
        icon: <Icon icon={<UserAddOutlined />} />,
        path: ROUTES['create-faroeisland-merchant'].path,
        text: <Trans i18nKey="Sidebar.menu_items.create_merchant" />,
        hide: !isFromFaroeIsland,
      },
      {
        icon: <Icon icon={<UserAddOutlined />} />,
        path: ROUTES['create-greenland-merchant'].path,
        text: <Trans i18nKey="Sidebar.menu_items.create_merchant" />,
        hide: !isGreenlandSupporter,
      },
      {
        icon: <Icon icon={<UserAddOutlined />} />,
        path: ROUTES['create-greenland-merchant'].path,
        text: <Trans i18nKey="Sidebar.menu_items.create_merchant" />,
        hide: !isGreenlandSupporter,
      },
    ];
  }, []);

  useEffect(() => {
    const pathName = location.pathname.split('/')[1];
    setCurrentPath(`/${[pathName]}`);
  }, [location.pathname]);

  const toggleCollaped = () => {
    dispatch(collapsed ? openSidebar({}) : collapseSidebar({}));
  };

  const _navigateTo = (path: string) => {
    navigate.push(path);
  };

  const _handleLogout = async () => {
    await firebaseLogout();
    navigate.push(ROUTES.login.path);
  };

  return (
    <Styles.StyledMenu
      mode="inline"
      inlineCollapsed={collapsed}
      selectedKeys={[currentPath]}
    >
      <Styles.Top onClick={() => _navigateTo(ROUTES.home.path)}>
        {isLink4PaySupporter ? (
          <div>
            <Link4PayLogo width={124} />
          </div>
        ) : (
          <Logo size={collapsed ? 16 : 20} single={collapsed} />
        )}
      </Styles.Top>

      {sidebarItems.map((item) => {
        if (item.hide) {
          return null;
        }
        return (
          <Styles.MenuItem
            key={item.path}
            icon={item.icon}
            onClick={() => _navigateTo(item.path)}
          >
            <Text size="ui2">{item.text}</Text>
          </Styles.MenuItem>
        );
      })}

      {!collapsed && (
        <Styles.ShortcutsContainer>
          <Text size="ui3" color="ui">
            {email}
          </Text>
          {isSoftPosSupport && (
            <AppButton href="vibrantio://a2a?callbackUrl=https://vibrant.io/">
              Open Vibrant App
            </AppButton>
          )}
          <Styles.LogoutButton size="ui3" onClick={_handleLogout}>
            <Trans i18nKey="Global.actions.btn_logout" />
          </Styles.LogoutButton>
        </Styles.ShortcutsContainer>
      )}

      <Styles.CollapseContainer>
        <Styles.CollapseButton onClick={toggleCollaped}>
          <VibrantIcon
            icon={collapsed ? 'arrow-long-right' : 'arrow-long-left'}
          />
        </Styles.CollapseButton>
      </Styles.CollapseContainer>
    </Styles.StyledMenu>
  );
};

/**
 * Exports
 */
export { Sidebar };
