/**
 * Imports
 */
import { UserOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Button, Icon, Input, Text } from 'components';
import { useAddOwner } from 'features/merchants';
import { hideAddOwnerModal } from 'features/merchants/redux';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Firebase } from 'types/firebase';
import { ALLOWED_IMAGE_TYPES } from 'utils';
const { Dragger } = Upload;
/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;
const StyledFormItem = styled(Form.Item)`
  && {
    & .ant-form-item-required {
      font-size: 13px !important;
      margin-bottom: 0 !important;
      &:before {
        display: none !important;
      }
    }
  }
`;
// Upload
const StyledDragger = styled(Dragger)`
  background-color: white !important;
  padding: 8px 32px;
`;
const UploadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const IconWrapper = styled.div`
  margin-right: 38px;
`;
const UploadText = styled.div`
  width: 100%;
  max-width: 200px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

/**
 * Types
 */
export type OwnerFormRow = {
  name: string;
  photoIdFile: { file: File; fileList: UploadFile[] };
  addressIdFile: { file: File; fileList: UploadFile[] };
};
export interface AddOwnerFormProps {
  merchant: Firebase.Merchant;
}

/**
 * AddOwnerForm component
 */
const AddOwnerForm: React.FC<AddOwnerFormProps> = ({ merchant }) => {
  /* Local state */
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const {
    handlers: { onAddOwner },
  } = useAddOwner({
    merchantId: merchant.merchantId,
    userId: merchant.onboardingId,
  });
  const { t } = useTranslation();

  const _onFinish = async (values: OwnerFormRow) => {
    try {
      setIsLoading(true);
      const ownerIndex = merchant.ownerData?.length || 0;
      const { name, photoIdFile, addressIdFile } = values;
      await onAddOwner(name, { photoIdFile, addressIdFile }, ownerIndex);
      setIsLoading(false);
      dispatch(hideAddOwnerModal({}));
    } catch (exception) {
      setIsLoading(false);
      console.log('Failed to add owner', exception);
    }
  };

  const _onChange = (_values: OwnerFormRow, allValues: OwnerFormRow) => {
    const allFieldsFilled =
      Object.values(allValues).filter((e) => e)?.length === 3;

    if (allFieldsFilled) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <Root>
      <Form
        name="dynamic_form_nest_item"
        onFinish={_onFinish}
        autoComplete="off"
        onValuesChange={_onChange}
        layout="vertical"
      >
        {/* Name */}
        <StyledFormItem
          label={<Trans i18nKey="Merchant.add_owner.name_label" />}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={t('Merchant.add_owner.name_placeholder')} />
        </StyledFormItem>
        {/* Photoid */}
        <StyledFormItem
          label={<Trans i18nKey="Merchant.add_owner.photoID_label" />}
          name="photoIdFile"
          tooltip={<Trans i18nKey="Merchant.add_owner.photoID_tooltip" />}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <StyledDragger
            beforeUpload={() => false}
            multiple={false}
            accept="image/jpeg, image/png"
          >
            <UploadContent>
              <IconWrapper>
                <Icon icon={<UserOutlined />} size={40} color="Grey300" />
              </IconWrapper>
              <UploadText>
                <Text size="ui2" bold>
                  <Trans i18nKey="Merchant.add_owner.photoID_text" />
                </Text>
                <Text size="p3">
                  <Trans i18nKey="Merchant.add_owner.photoID_btn" />
                </Text>
              </UploadText>
            </UploadContent>
          </StyledDragger>
        </StyledFormItem>
        {/* SsnId */}
        <StyledFormItem
          label={<Trans i18nKey="Merchant.add_owner.addressId_label" />}
          name="addressIdFile"
          tooltip={<Trans i18nKey="Merchant.add_owner.addressId_tooltip" />}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <StyledDragger
            beforeUpload={() => false}
            multiple={false}
            accept={`${ALLOWED_IMAGE_TYPES}`}
          >
            <UploadContent>
              <IconWrapper>
                <Icon icon={<UserOutlined />} size={40} color="Grey300" />
              </IconWrapper>
              <UploadText>
                <Text size="ui2" bold>
                  <Trans i18nKey="Merchant.add_owner.addressId_text" />
                </Text>
                <Text size="p3">
                  <Trans i18nKey="Merchant.add_owner.addressId_btn" />
                </Text>
              </UploadText>
            </UploadContent>
          </StyledDragger>
        </StyledFormItem>
        <StyledFormItem>
          <Button
            block
            disabled={!isValid}
            loading={isLoading}
            htmlType="submit"
            type="primary"
          >
            <Trans i18nKey="Merchant.add_owner.btn_add" />
          </Button>
        </StyledFormItem>
      </Form>
    </Root>
  );
};

/**
 * Exports
 */
export { AddOwnerForm };
