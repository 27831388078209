/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import { VibrantlyState } from 'store/types';
import type { Firebase } from 'types/firebase';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.merchants;

export const showAddOwnerModalSelector = createSelector(
  [rootSelector],
  (root) => root.modals.ownerModal,
);

export const merchantsSelector = createSelector(
  (state: VibrantlyState) => state.firestore,
  (firestore) => firestore.ordered.merchants,
);

export const merchantSelector = (id: string) =>
  createSelector(
    [firestoreRootSelector],
    (firestore) => firestore.data[`merchant_${id}`] as Firebase.Merchant,
  );

export const firestoreRootSelector = createSelector(
  (state: VibrantlyState) => state.firestore,
  (root) => root,
);
export const currentMerchantSelector = createSelector(
  [firestoreRootSelector],
  (firestore) => firestore.data.merchant as Firebase.Merchant,
);

export const merchantsCountSelector = createSelector(
  [merchantsSelector],
  (merchants) => merchants?.length,
);
export const completedMerchantsCountSelector = createSelector(
  [merchantsSelector],
  (merchants: Record<number, Firebase.Merchant>) => {
    return filter(
      merchants,
      (merchant) =>
        merchant.clearhausKYCdone != null &&
        merchant.readyForApplication != null,
    ).length;
  },
);
export const pendingVibrantMerchantsCountSelector = createSelector(
  [merchantsSelector],
  (merchants) => {
    return filter(merchants, (merchant) => merchant.readyForApplication == null)
      .length;
  },
);
export const pendingClearhausMerchantsCountSelector = createSelector(
  [merchantsSelector],
  (merchants) => {
    return filter(merchants, (merchant) => merchant.clearhausKYCdone == null)
      ?.length;
  },
);

/**
 * Firestore
 */
export const partnerNameSelector = createSelector(
  [firestoreRootSelector],
  (root) => root.data?.partnerAccount?.name,
);
/**
 * Filters
 */
export const filtersSelector = createSelector(
  [rootSelector],
  (root) => root.filters,
);
