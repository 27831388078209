/**
 * Imports
 */
import { List, Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Trans } from 'react-i18next';
import { message } from 'antd';
import type { SoftPos } from 'types/softpos';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;
export const StyledList = styled(List)`
  max-width: 400px;

  && {
    & .ant-list-item {
      padding: 12px 16px;
    }
  }
`;
export const ListItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
`;
const ListItemTitle = styled.div``;

/**
 * CONST
 */

type K1 = keyof Pick<
  SoftPos.TrxnRaw,
  | 'txnGuid'
  | 'merchantCode'
  | 'merchantCountry'
  | 'merchantName'
  | 'cardNo'
  | 'cardDci'
  | 'cardSource'
  | 'requestDate'
  | 'requestTime'
  | 'responseCode'
  | 'responseDescription'
  | 'rrn'
  | 'transactionName'
  | 'transactionAmount'
  | 'transactionCurrency'
  | 'transactionStatus'
  | 'txnEffect'
  | 'txnEntry'
  | 'txnRegion'
  | 'terminalCode'
  | 'utcTime'
>;
type K2 = {
  field: K1;
  title: React.ReactNode;
};
type TrxnDetails = {
  [key in K1]: K2;
};
const TRXN_DETAILS: TrxnDetails = {
  txnGuid: {
    field: 'txnGuid',
    title: <Trans i18nKey="Transactions.table_details.txnGuid" />,
  },
  rrn: {
    field: 'rrn',
    title: 'RRN',
  },
  merchantCode: {
    field: 'merchantCode',
    title: <Trans i18nKey="Transactions.table_details.merchantCode" />,
  },
  merchantCountry: {
    field: 'merchantCountry',
    title: <Trans i18nKey="Transactions.table_details.merchantCountry" />,
  },
  merchantName: {
    field: 'merchantName',
    title: <Trans i18nKey="Transactions.table_details.merchantName" />,
  },
  transactionStatus: {
    field: 'transactionStatus',
    title: <Trans i18nKey="Transactions.table_details.transactionStatus" />,
  },
  responseCode: {
    field: 'responseCode',
    title: <Trans i18nKey="Transactions.table_details.responseCode" />,
  },
  responseDescription: {
    field: 'responseDescription',
    title: <Trans i18nKey="Transactions.table_details.responseDescription" />,
  },
  cardNo: {
    field: 'cardNo',
    title: <Trans i18nKey="Transactions.table_details.cardNo" />,
  },
  cardDci: {
    field: 'cardDci',
    title: <Trans i18nKey="Transactions.table_details.cardDci" />,
  },
  cardSource: {
    field: 'cardSource',
    title: <Trans i18nKey="Transactions.table_details.cardSource" />,
  },
  requestDate: {
    field: 'requestDate',
    title: <Trans i18nKey="Transactions.table_details.requestDate" />,
  },
  requestTime: {
    field: 'requestTime',
    title: <Trans i18nKey="Transactions.table_details.requestTime" />,
  },
  transactionName: {
    field: 'transactionName',
    title: <Trans i18nKey="Transactions.table_details.transactionName" />,
  },
  transactionAmount: {
    field: 'transactionAmount',
    title: <Trans i18nKey="Transactions.table_details.transactionAmount" />,
  },
  transactionCurrency: {
    field: 'transactionCurrency',
    title: <Trans i18nKey="Transactions.table_details.transactionCurrency" />,
  },
  txnEffect: {
    field: 'txnEffect',
    title: <Trans i18nKey="Transactions.table_details.txnEffect" />,
  },
  txnEntry: {
    field: 'txnEntry',
    title: <Trans i18nKey="Transactions.table_details.txnEntry" />,
  },
  txnRegion: {
    field: 'txnRegion',
    title: <Trans i18nKey="Transactions.table_details.txnRegion" />,
  },
  terminalCode: {
    field: 'terminalCode',
    title: <Trans i18nKey="Transactions.table_details.terminalCode" />,
  },
  utcTime: {
    field: 'utcTime',
    title: <Trans i18nKey="Transactions.table_details.utcTime" />,
  },
};

/**
 * Types
 */
export interface TransactionsDetailsProps {
  trxnItem: SoftPos.TrxnRaw;
}
/**
 * TransactionsDetails component
 */
const TransactionsDetails: React.FC<TransactionsDetailsProps> = ({
  trxnItem,
}) => {
  const _onCopy = () => {
    message.info('Copied');
  };

  return (
    <Root>
      <StyledList bordered>
        {Object.values(TRXN_DETAILS).map((item: K2) => {
          const copyText = trxnItem[item.field]?.toString()
            ? trxnItem[item.field]!.toString()
            : 'null';
          return (
            <CopyToClipboard key={item.field} onCopy={_onCopy} text={copyText}>
              <ListItem>
                <ListItemTitle>
                  <Text size="ui2" bold>
                    {item.title}
                  </Text>
                </ListItemTitle>
                <Text size="ui2">
                  {trxnItem[item.field] ? trxnItem[item.field] : 'null'}
                </Text>
              </ListItem>
            </CopyToClipboard>
          );
        })}
      </StyledList>
    </Root>
  );
};

/**
 * Exports
 */
export { TransactionsDetails };
