import { MailOutlined } from '@ant-design/icons';
import { FormGroup } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';
import React, { FunctionComponent, useMemo } from 'react';

type Props = {
  contact: { fullName: string; contactEmail: string; phone: string };
  onSave: (inputName: string, inputValue: string) => Promise<void>;
};

type FormFieldsInput = {
  label: string;
  alternativeLabel: string;
  name: string;
  value: string;
};

export const MerchantContactForm: FunctionComponent<Props> = (props) => {
  const formFields = useMemo<FormFieldsInput[]>(
    () => [
      {
        label: 'Full Name',
        alternativeLabel: 'Change Full Name',
        name: 'contact.fullName',
        value: props.contact.fullName,
        hasCopyIcon: true,
      },
      {
        label: 'Contact Email',
        alternativeLabel: 'Change Email',
        name: 'contact.contactEmail',
        value: props.contact.contactEmail,
        hasCopyIcon: true,
      },
      {
        label: 'Phone',
        alternativeLabel: 'Change phone',
        name: 'contact.phone',
        value: props.contact.phone,
        hasCopyIcon: true,
      },
    ],
    [props.contact.contactEmail, props.contact.phone, props.contact.fullName],
  );

  return (
    <FormGroup icon={<MailOutlined />} title="Contact">
      {formFields.map((input) => (
        <FormGroupItem
          key={input.name}
          {...input}
          onSave={(newInputValue) =>
            props.onSave(input.name, newInputValue.toString())
          }
        />
      ))}
    </FormGroup>
  );
};
