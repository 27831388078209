import { Avatar, Menu } from 'antd';
import { Anchor, Text } from 'components';
import { styled } from 'theme';

export const StyledMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 240px;
  padding: ${(props) => (props.inlineCollapsed ? 30 : 32)}px;
  border: 0;
  background-color: ${(props) => props.theme.colors.Background.Primary};
  transition-duration: 0ms;

  && {
    .ant-menu-item {
      color: ${(props) => props.theme.colors.Text.IconInactive};
      padding: 0px !important;
      margin: 0px;
      font-size: 17px;
      height: auto;
      margin-bottom: 32px;

      & svg {
        width: auto;
        color: ${(props) => props.theme.colors.Text.Placeholder};
        /* fill: transparent; */
      }

      &:active,
      :focus {
        background-color: transparent;
      }

      &:hover {
        transition: color 0.3s;
        color: ${(props) => props.theme.colors.Text.IconHover};

        svg {
          transition: color 0.3s;
          color: ${(props) => props.theme.colors.Text.IconHover};
        }
      }

      .vibranticon {
        margin-right: 25px;
      }
      .anticon {
        margin-right: 25px;
      }
    }

    .ant-menu-item-selected {
      background-color: transparent;
      color: ${(props) => props.theme.colors.Text.Icon};

      svg {
        color: ${(props) => props.theme.colors.Text.Brand} !important;
        /* fill: transparent; */
      }

      ::after {
        border: 0;
      }
    }
  }

  /* Logo */
  .ant-menu-item-only-child {
    margin: 16px 0px;

    svg {
      max-width: 89px;
      color: ${(props) => props.theme.colors.Text.Icon} !important;
    }
  }
`;

export const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;

export const Top = styled(Menu.Item)`
  cursor: pointer;
  text-align: left;
  height: 48px;
`;

export const ShortcutsContainer = styled.div`
  margin-top: 18px;

  & > :last-child {
    margin-top: 16px;
  }
`;

export const AccountDetailsContainer = styled.div`
  margin-top: auto;
`;

export const AccountDetailsTextWrapper = styled.div`
  cursor: pointer;
  margin-top: 12px;
`;

export const AccountNameText = styled(Text)`
  color: ${(props) => props.theme.colors.Text.Label};
`;

export const AnchorText = styled(Anchor)`
  display: block;
  color: ${(props) => props.theme.colors.Text.LinkHover};
  cursor: pointer;
  margin-bottom: 16px;
`;

export const LogoutButton = styled(Text)`
  display: block;
  color: ${(props) => props.theme.colors.Text.LinkHover};
  cursor: pointer;
  margin-top: 4px;

  &:hover {
    color: ${(props) => props.theme.colors.Text.Brand};
  }
`;
export const AppButton = styled.a`
  display: block;
  color: ${(props) => props.theme.colors.Text.LinkHover};
  cursor: pointer;
  margin-top: 4px;
  font-size: 13px;
  margin-top: 16px;
  &:hover {
    color: ${(props) => props.theme.colors.Text.Brand};
  }
`;

export const ProfilePicture = styled(Avatar)<{ isCollapsed: boolean }>`
  transform: ${(props) => (props.isCollapsed ? `translate(-15%)` : 'initial')};
`;

export const CollapseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
  padding-top: 23px;
  margin-top: auto;
`;

export const CollapseButton = styled.div`
  cursor: pointer;
`;
