import React from 'react';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { EmailLead } from 'features/email-leads';

export type EmailLeadsTableProps = {
  dataSource: (EmailLead & { key: string })[];
  loading: boolean;
};

const columns: ColumnsType<EmailLead> = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (_, record) => {
      return <Typography.Text>{record.email}</Typography.Text>;
    },
  },
];

const EmailLeadsTable: React.FC<EmailLeadsTableProps> = (props) => {
  return (
    <Table
      dataSource={props.dataSource}
      columns={columns}
      loading={props.loading}
    />
  );
};

export { EmailLeadsTable };
