/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { Transactions } from '../types';

/**
 * Constants
 */
const PREFIX = 'transactions';

/**
 * GET_TRANSACTIONS
 */
export const GET_TRANSACTIONS = `${PREFIX}/GET_TRANSACTIONS`;
export type GetTransctionsAction = VibrantlyAction<
  Transactions.GetTransctionsPayload
>;
export const getTransactions = createAction<GetTransctionsAction['payload']>(
  GET_TRANSACTIONS,
);
/**
 * GET_TRANSACTION
 */
export const GET_TRANSACTION = `${PREFIX}/GET_TRANSACTION`;
export type GetTransctionAction = VibrantlyAction<
  Transactions.GetTransctionPayload
>;
export const getSingleTransaction = createAction<
  GetTransctionAction['payload']
>(GET_TRANSACTION);
/**
 * SET_DATES
 */
export const SET_DATES = `${PREFIX}/SET_DATES`;
export type SetDatesAction = VibrantlyAction<Transactions.SetDatesPayload>;
export const setDates = createAction<SetDatesAction['payload']>(SET_DATES);
