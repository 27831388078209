/**
 * Imports
 */
import * as React from 'react';

import { styled } from 'theme';
import { Button, Input, Select } from 'components';
import { Col, Form, message, Row } from 'antd';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { createGreenlandMerchant } from 'apis';
import { IBAN_REGEX } from './iban-examples';
/**
 * Styling
 */
const Root = styled.div`
  width: 100%;
  max-width: 480px;
`;
const StyledSelect = styled(Select)`
  && {
    min-width: 100%;
    .ant-select-selector .ant-select-selection-search-input {
      height: 42px;
    }
    .ant-select-arrow svg {
      fill: ${({ theme }) => theme.colors.Text.UI};
    }
  }
`;
const StyledOption = styled(Select.Option)``;

/**
 * Types
 */
export interface CreateMerchantFormProps {}

export type MerchantFormRow = {
  merchantStoreName: string;
  merchantName: string;
  registrationNumber: string;
  descriptor: string;
  // currency: string;
  addressLine1: string;
  city: string;
  zip: string;
  country: string;
  timezone: string;
  name: string;
  email: string;
  phone: string;
  iban: string;
  averageTransactionAmount: string;
  monthlyTurnover: string;
  businessActivityDescription: string;
  password: string;
};
export type MerchantFormData = {
  merchantStoreName: string;
  merchantName: string;
  registrationNumber: string;
  descriptor: string;
  currency: string;
  address: {
    addressLine1: string;
    city: string;
    zip: string;
    country: string;
    timezone: string;
  };
  contact: {
    name: string;
    email: string;
    phone: string;
  };
  bank: {
    iban: string;
  };
  businessActivityDescription: string;
  password: string;
  averageTransactionAmount: number;
  monthlyTurnover: number;
  website: string;
};

export const MERCHANT_FORM_FIELDS: {
  [key in keyof MerchantFormRow]: keyof MerchantFormRow;
} = {
  merchantStoreName: 'merchantStoreName',
  merchantName: 'merchantName',
  registrationNumber: 'registrationNumber',
  descriptor: 'descriptor',
  addressLine1: 'addressLine1',
  city: 'city',
  zip: 'zip',
  country: 'country',
  timezone: 'timezone',
  name: 'name',
  email: 'email',
  phone: 'phone',
  iban: 'iban',
  averageTransactionAmount: 'averageTransactionAmount',
  monthlyTurnover: 'monthlyTurnover',
  businessActivityDescription: 'businessActivityDescription',
  password: 'password',
};
const COUNTRY_CODES: { [key in string]: { name: string } } = {
  GL: {
    name: 'Greenland',
  },
};
const TIMEZONE_MAP: { [key in string]: string } = {
  GL: 'Europe/Copenhagen',
};
const CreateMerchantForm: React.FC<CreateMerchantFormProps> = (_props) => {
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [form] = Form.useForm();

  const _reset = () => {
    form.resetFields();
  };

  const _isValid = (allValues: MerchantFormRow) => {
    if (
      allValues.merchantStoreName &&
      allValues.merchantName &&
      allValues.registrationNumber &&
      allValues.descriptor &&
      allValues.addressLine1 &&
      allValues.city &&
      allValues.zip &&
      allValues.country &&
      allValues.name &&
      allValues.email &&
      allValues.phone &&
      allValues.iban
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const _onChange = (_values: MerchantFormRow, allValues: MerchantFormRow) => {
    _isValid(allValues);
  };

  const _onFinish = async (values: MerchantFormRow) => {
    setIsLoading(true);
    try {
      const data: MerchantFormData = {
        merchantStoreName: values.merchantStoreName,
        merchantName: values.merchantName,
        registrationNumber: values.registrationNumber,
        descriptor: values.descriptor,
        address: {
          addressLine1: values.addressLine1,
          city: values.city,
          zip: values.zip,
          country: values.country,
          timezone: TIMEZONE_MAP[values.country],
        },
        contact: {
          name: values.name,
          email: values.email,
          phone: values.phone,
        },
        bank: {
          iban: values.iban,
        },
        averageTransactionAmount: parseInt(values.averageTransactionAmount),
        businessActivityDescription: values.businessActivityDescription,
        monthlyTurnover: parseInt(values.monthlyTurnover),
        password: values.password,
        // Hardcoded values
        currency: 'DKK',
        website: 'www.terminal.gl',
      };
      const resp = await createGreenlandMerchant(data);
      if (resp.success) {
        message.success('Merchant succesfully created');
        _reset();
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Root>
      <Form
        layout="vertical"
        onFinish={_onFinish}
        onValuesChange={_onChange}
        form={form}
      >
        {/* merchantStoreName */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.merchantStoreName}
          label="Merchant Store Name"
        >
          <Input placeholder="Terminal GL" />
        </Form.Item>
        {/* merchantName */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.merchantName}
          label="Merchant Name"
        >
          <Input placeholder="Terminal GL Legal" />
        </Form.Item>
        {/* registrationNumber */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.registrationNumber}
          label="Registration Number"
        >
          <Input placeholder="13645183" />
        </Form.Item>
        {/* descriptor */}
        <Form.Item name={MERCHANT_FORM_FIELDS.descriptor} label="Descriptor">
          <Input placeholder="Max 21 characters" />
        </Form.Item>
        {/* addressLine1 */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.addressLine1}
          label="Address Line"
        >
          <Input placeholder="Indaleeqqap Aqq. 3" />
        </Form.Item>
        <Row gutter={8}>
          {/* city */}
          <Col span={12}>
            <Form.Item name={MERCHANT_FORM_FIELDS.city} label="City">
              <Input placeholder="Nuuk" />
            </Form.Item>
          </Col>
          {/* zip */}
          <Col span={12}>
            <Form.Item name={MERCHANT_FORM_FIELDS.zip} label="Zip">
              <Input placeholder="3900" />
            </Form.Item>
          </Col>
        </Row>
        {/* country */}
        <Form.Item name={MERCHANT_FORM_FIELDS.country} label="Country">
          <StyledSelect placeholder="Select country">
            {Object.entries(COUNTRY_CODES).map((c) => {
              return (
                <StyledOption key={c[0]} value={c[0]}>
                  <ReactCountryFlag
                    style={{
                      fontSize: '18px',
                      lineHeight: '18px',
                      marginRight: '8px',
                      marginTop: '-4px',
                    }}
                    countryCode={c[0]}
                    svg
                  />
                  {c[1].name}
                </StyledOption>
              );
            })}
          </StyledSelect>
        </Form.Item>
        {/* name */}
        <Form.Item name={MERCHANT_FORM_FIELDS.name} label="Name">
          <Input placeholder="Owner name here" />
        </Form.Item>
        {/* email */}
        <Form.Item name={MERCHANT_FORM_FIELDS.email} label="E-mail">
          <Input placeholder="xxxx@terminal.gl" />
        </Form.Item>
        {/* phone */}
        <Form.Item name={MERCHANT_FORM_FIELDS.phone} label="Phone">
          <Input placeholder="+299 32 10 01" />
        </Form.Item>
        {/* iban */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.iban}
          label="IBAN"
          rules={[
            {
              pattern: IBAN_REGEX,
              required: true,
              message: 'Please add a valid IBAN',
            },
          ]}
        >
          <Input placeholder="GL8964710123456789" />
        </Form.Item>

        {/* averageTransactionAmount */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.averageTransactionAmount}
          label="Average Transaction Amount"
        >
          <Input placeholder="50" type="number" />
        </Form.Item>
        {/* monthlyTurnover */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.monthlyTurnover}
          label="Monthly Turnover"
        >
          <Input placeholder="500000" type="number" />
        </Form.Item>
        {/* businessActivityDescription */}
        <Form.Item
          name={MERCHANT_FORM_FIELDS.businessActivityDescription}
          label="Business Activity Description"
        >
          <Input placeholder="Description" />
        </Form.Item>
        {/* password */}
        <Form.Item name={MERCHANT_FORM_FIELDS.password} label="Password">
          <Input placeholder="GlTerminal2023$" />
        </Form.Item>
        {/* submit */}
        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            disabled={!isValid}
            htmlType="submit"
          >
            Add Merchant
          </Button>
        </Form.Item>
      </Form>
    </Root>
  );
};

/**
 * Exports
 */
export { CreateMerchantForm };
