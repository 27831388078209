/**
 * Imports
 */
import { Modal } from 'antd';
import { VibrantIcon } from 'components';
import {
  hideAddOwnerModal,
  showAddOwnerModalSelector,
} from 'features/merchants/redux';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddOwnerForm } from '../AddOwnerForm';
import { Firebase } from 'types/firebase';

export interface AddOwnerModalProps {
  merchant: Firebase.Merchant;
}

/**
 * AddOwnerModal component
 */
const AddOwnerModal: React.FC<AddOwnerModalProps> = ({ merchant }) => {
  const showOwnerModal = useSelector(showAddOwnerModalSelector);
  const dispatch = useDispatch();

  const _handleCancel = () => {
    dispatch(hideAddOwnerModal({}));
  };

  return (
    <Modal
      visible={showOwnerModal}
      onCancel={_handleCancel}
      footer={null}
      closeIcon={<VibrantIcon icon="close" size={20} />}
    >
      <AddOwnerForm merchant={merchant} />
    </Modal>
  );
};

/**
 * Exports
 */
export { AddOwnerModal };
