/**
 * Imports
 */
import { message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PaginationProps } from 'antd/lib/pagination';
import { TablePaginationConfig } from 'antd/lib/table';
import { getAllTransactions } from 'apis';
import { isLink4PaySupporterSelector } from 'features/user/redux/selectors';
import { orderBy, sortBy, uniq } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SoftPos } from 'types/softpos';
import { getTransactions } from '../redux/actions';
import {
  endDateSelector,
  startDateSelector,
  trxnItemsSelector,
} from '../redux/selectors';

/**
 * CONST
 */
export type CountrySelectValues =
  | 'CY'
  | 'DE'
  | 'GL'
  | 'DK'
  | 'ES'
  | 'GR'
  | 'NO'
  | 'TR'
  | 'FO'
  | 'ALL';

//  https://www.iban.com/country-codes
export const COUNTRY_ALPHA_CODES = {
  ALL: { alpha: [], name: 'All countries' },
  CY: { alpha: ['CY', 'CYP'], name: 'Cyprus' },
  DE: { alpha: ['DE', 'DEU'], name: 'Germany' },
  DK: { alpha: ['DK', 'DNK'], name: 'Denmark' },
  ES: { alpha: ['ES', 'ESP'], name: 'Spain' },
  FO: { alpha: ['FO', 'FRO'], name: 'Faroe Island' },
  GL: { alpha: ['GL', 'GRL'], name: 'Greenland' },
  GR: { alpha: ['GR', 'GRC'], name: 'Greece' },
  NO: { alpha: ['NO', 'NOR'], name: 'Norway' },
  TR: { alpha: ['TR', 'TUR'], name: 'Turkey' },
};
const TEST_MERCHANTS = [
  // UAT
  '000000030000006',
  '000000030000006',
  '000000030000007',
  // PROD
  '000000030000117',
  '000000030000053',
  '000000030000033',
  '000000030000032',
  '000000030000031',
  '000000030000030',
];

/**
 * Types
 */
type MerchantFilterOpt = { value: string; text: string };
export type useTransactionsHandlers = {
  onShowTestTrxn: (e: CheckboxChangeEvent) => void;
  onSelectCountry: (countryCode: CountrySelectValues) => void;
};
type UseTransactionsReturnType = {
  isFetching: boolean;
  tableChangeHandler: (pagination: PaginationProps) => void;
  pagination: TablePaginationConfig;
  trxnItems: SoftPos.TrxnRaw[];
  merchantsFilterOpt: MerchantFilterOpt[];
  handlers: useTransactionsHandlers;
};

/**
 * useTransactionsApi component
 */
const useTransactionsApi = (): UseTransactionsReturnType => {
  /* Hooks */
  const dispatch = useDispatch();
  /* Redux */
  const startDate = useSelector(startDateSelector);
  const endDate = useSelector(endDateSelector);
  const allTrxnItems = useSelector(trxnItemsSelector);
  const isLink4Pay = useSelector(isLink4PaySupporterSelector);
  /* Local state */
  const [countryCode, setCountryCode] = React.useState<CountrySelectValues>(
    isLink4Pay ? 'CY' : 'DK',
  );
  const [showTestTrxn, setShowTestTrxn] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState<PaginationProps>({});
  const [merchantsFilterOpt, setMerchantsFilterOpt] = React.useState<
    MerchantFilterOpt[]
  >([]);
  const [_trxns, setTrxn] = React.useState<SoftPos.TrxnRaw[]>([]);

  /**
   * _filterTrxn
   */
  const _filterTrxn = () => {
    const filteredTrxn = allTrxnItems.filter((t) => {
      return !TEST_MERCHANTS.some((filterEl) => t.merchantCode === filterEl);
    });
    return filteredTrxn;
  };

  const _filterByCountry = (trxn: SoftPos.TrxnRaw[]) => {
    const filteredTrxn = trxn.filter((t) => {
      return COUNTRY_ALPHA_CODES[countryCode].alpha.some(
        (filterEl) => t.merchantCountry === filterEl,
      );
    });
    return filteredTrxn;
  };

  /**
   * _buildMerchantFilter
   */
  const _buildMerchantFilter = () => {
    const uniqMerchants = uniq(allTrxnItems.map((t) => t.merchantName)).map(
      (m) => {
        return { value: m, text: m };
      },
    );
    const orderedMerchants = sortBy(uniqMerchants, 'text', 'desc');
    setMerchantsFilterOpt(orderedMerchants);
  };

  /**
   * _buildPagination
   */
  const _buildPagination = (totalRecords: number) => {
    const nextPagination = { ...pagination };
    nextPagination.total = totalRecords ? totalRecords : undefined;
    setPagination(nextPagination);
  };
  /**
   * tableChangeHandler
   */
  const tableChangeHandler = (pagination: PaginationProps) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fecthTrxnItems();
  };

  const _setTrxn = () => {
    if (showTestTrxn) {
      const trxn =
        countryCode === 'ALL' ? allTrxnItems : _filterByCountry(allTrxnItems);
      setTrxn(trxn);
    } else {
      const trxn = _filterTrxn();
      const countryFiltered =
        countryCode === 'ALL' ? trxn : _filterByCountry(trxn);
      setTrxn(countryFiltered);
    }
  };

  /**
   * fecthTrxnItems
   */
  const fecthTrxnItems = React.useCallback(
    async () => {
      setIsFetching(true);
      try {
        const resp = (await getAllTransactions(startDate, endDate)) as any;
        const trxnItems = orderBy(resp, ['utcTime'], ['desc']);
        dispatch(
          getTransactions({
            trxnItems,
            trxnCount: resp.count,
          }),
        );
        _buildPagination(resp.count);
        setIsFetching(false);
      } catch (exception) {
        message.error(exception?.message);
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endDate, startDate],
  );

  /**
   * Init
   */
  React.useEffect(() => {
    fecthTrxnItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  /**
   * Init
   */
  React.useEffect(() => {
    _setTrxn();
  }, [countryCode]);
  /**
  /**
   * Init
   */
  React.useEffect(() => {
    _setTrxn();
  }, [showTestTrxn]);
  /**
   * Init
   */
  React.useEffect(() => {
    _buildMerchantFilter();
    _setTrxn();
  }, [allTrxnItems]);

  /**
   * Handlers
   */
  const handlers: useTransactionsHandlers = {
    onShowTestTrxn: (e: CheckboxChangeEvent) => {
      const checked = e.target.checked;
      setShowTestTrxn(checked);
    },
    onSelectCountry: (countryCode: CountrySelectValues) => {
      setCountryCode(countryCode);
    },
  };

  return {
    isFetching,
    tableChangeHandler,
    pagination,
    trxnItems: _trxns,
    merchantsFilterOpt,
    handlers,
  };
};

/**
 * Exports
 */
export { useTransactionsApi };
