import React, { useState, Fragment } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { Button, Text } from 'components';
import { AddUserForm } from './AddUserForm';

export const AddButton = styled(Button)`
  color: ${(props) => props.theme.colors.Text.Brand};
`;

export type UserModalProps = {
  show: boolean;
  change: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddUserModal: React.FC<UserModalProps> = (props) => {
  const _handleCancel = () => {
    props.change(false);
  };

  return (
    <Modal
      title="Add User"
      visible={props.show}
      onCancel={_handleCancel}
      footer={null}
      okText="Create"
    >
      <AddUserForm handleCancel={_handleCancel} />
    </Modal>
  );
};

export { AddUserModal };
