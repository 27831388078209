import React from 'react';
import 'antd/dist/antd.css';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface.js';

export const ColumnSearchProps = (dataIndex: string) => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder={`Search ${dataIndex}`}
        value={props.selectedKeys[0]}
        onChange={(e) =>
          props.setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => props.confirm()}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
        autoFocus={true}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            props.confirm();
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => props.clearFilters && props.clearFilters()}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1890ff' : undefined,
      }}
    />
  ),
  onFilter: (value: string | number | boolean, record: any) => {
    const current = record[dataIndex];
    if (current) {
      return current
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
    }
    return false;
  },
});
