import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { EmailLead } from 'features/email-leads';
import firebaseNative from 'firebase/app';
import type { Firebase } from 'types/firebase';
import { getFirebaseInstance } from './firebaseAdapter';

/**
 * CONST
 */
export enum FirebaseCollectionsType {
  ACCOUNTS = 'vibrant_api.accounts',
  EMAIL_LEADS = 'emailLeads',
  MERCHANTS = 'merchants',
  SIGNUPS = 'signups',
  SOFTPOS_DASHBOARD_SETTINGS = 'softPosDashboardSettings',
  SOFTPOS_ONBOARDING = 'softPosOnboarding',
  USERS = 'users',
}

/**
 * Email login
 */
const firebaseLogin = async (
  email: string,
  password: string,
): Promise<Firebase.User> => {
  const firebase = getFirebaseInstance();
  const signinResult = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);

  if (!signinResult.user) {
    throw new Error('Could not sign in user.');
  }

  return getUser();
};
/**
 * Google login
 */
const firebaseGoogleLogin = async (): Promise<{ email: string }> => {
  const firebase = getFirebaseInstance();

  const resp = await firebase
    .auth()
    .signInWithPopup(new firebaseNative.auth.GoogleAuthProvider());

  if (!resp.user) {
    throw new Error('Could not sign user in.');
  }
  const email = resp.user.email!;

  return { email };
};

const getIdToken = async (): Promise<string> => {
  const firebase = getFirebaseInstance();
  const token = await firebase.auth().currentUser!.getIdToken();
  return token;
};

const firebaseLogout = async (): Promise<void> => {
  const firebase = getFirebaseInstance();
  await firebase.auth().signOut();
};

const getUser = async (): Promise<Firebase.User> => {
  const firestore = getFirebaseInstance().firestore();
  const firebase = getFirebaseInstance();
  const authUser = await firebase.auth().currentUser;
  if (authUser) {
    const doc = await firestore.collection('users/').doc(authUser.uid).get();
    if (doc.exists) {
      const user = doc.data() as Firebase.User;
      return user;
    }
  }
  throw new Error('Could not get user.');
};

const getOnboardingApi = async (): Promise<Firebase.SoftPosOnboarding[]> => {
  const firestore = getFirebaseInstance().firestore();
  const response: Firebase.SoftPosOnboarding[] = [];
  const onboardingCollectionRef = firestore.collection('softPosOnboarding');

  const onboardingCollection = await onboardingCollectionRef
    .where('onboardingDone', '==', null)
    .get();

  onboardingCollection.forEach((onboardingData) => {
    if (onboardingData.exists && onboardingData) {
      response.push(onboardingData.data() as Firebase.SoftPosOnboarding);
    }
  });

  return response;
};

const getMerchantsApi = async (): Promise<Firebase.Merchant[]> => {
  const firestore = getFirebaseInstance().firestore();
  const response: Firebase.Merchant[] = [];

  const merchantsCollection = await firestore.collection('merchants').get();

  merchantsCollection.forEach((merchantsData) => {
    if (merchantsData.exists) {
      response.push(merchantsData.data() as Firebase.Merchant);
    }
  });

  return response;
};

const updateMerchantApi = async (
  merchantId: string,
  data: Partial<Firebase.Merchant>,
): Promise<void> => {
  const firestore = getFirebaseInstance().firestore();

  await firestore.doc(`merchants/${merchantId}`).update(data);
};

const updateOnboardingApi = async (
  onboardingId: string,
  data: Partial<Firebase.SoftPosOnboarding>,
): Promise<void> => {
  const firestore = getFirebaseInstance().firestore();

  await firestore.doc(`softPosOnboarding/${onboardingId}`).update(data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const resetOwnerFiles = async (merchantId: string) => {
  const firestore = getFirebaseInstance().firestore();
  const merchantDoc = await firestore
    .collection(FirebaseCollectionsType.MERCHANTS)
    .doc(merchantId);
  const merchant = await merchantDoc.get();
  const ownerData = merchant.get('ownerData') as Firebase.Owner[];

  const resetData = ownerData.map((owner) => {
    const newOwnerData = {
      ...owner,
      addressId: null,
      fileUrl: null,
      photoId: null,
    } as Firebase.Owner;
    return newOwnerData;
  });

  await merchantDoc.update({
    ownerData: resetData,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateOwnerFileFireStore = async (
  merchantId: string,
  photoType: number,
  url: string | null, // Null for when we delete image
  ownerIndex: number,
) => {
  const firestore = getFirebaseInstance().firestore();
  const merchantDoc = firestore
    .collection(FirebaseCollectionsType.MERCHANTS)
    .doc(merchantId);
  const merchant = await merchantDoc.get();
  const ownerData = merchant.get('ownerData');

  const newFileurl = { ...ownerData[ownerIndex].fileUrl, [photoType]: url };

  ownerData[ownerIndex] = {
    ...ownerData[ownerIndex],
    fileUrl: newFileurl,
  };

  await merchantDoc.update({
    ownerData,
  });
};

const updateTerminalIMEIApi = async (
  merchantId: string,
  terminalId: string,
  data: Pick<Firebase.Terminal, 'imei'>,
): Promise<void> => {
  const firestore = getFirebaseInstance().firestore();

  const merchantDoc = await firestore
    .collection(FirebaseCollectionsType.MERCHANTS)
    .doc(merchantId);
  const merchant = await merchantDoc.get();

  const terminalCollection = merchant.get('terminalCollection');
  const terminalIndex = terminalCollection.findIndex(
    (terminal: Firebase.Terminal) => terminal.terminalId === terminalId,
  );

  terminalCollection[terminalIndex] = {
    ...terminalCollection[terminalIndex],
    ...data,
  };

  await merchantDoc.update({
    terminalCollection,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getEmailLeadsApi = async () => {
  const firestore = getFirebaseInstance().firestore();
  const response: EmailLead[] = [];

  const emailLeadsCollection = await firestore.collection('emailLeads').get();

  emailLeadsCollection.forEach((snapshot) => {
    if (snapshot.exists) {
      response.push(snapshot.data() as EmailLead);
    }
  });

  return response;
};

const getUserDatas = async (
  merchantId: string,
): Promise<Firebase.UserData[]> => {
  try {
    const token = await getIdToken();
    const response = await axios.get(
      `${PROJECT_API_URL}/api/softpos/v1/get_users`,
      {
        headers: { token },
        params: { merchantId },
      },
    );
    const result = response.data.result.users as Firebase.UserData[];
    return result;
  } catch (e) {
    console.log(e.exception);
    return [];
  }
};

const addUser = async (
  email: string,
  password: string,
  merchantId: string,
): Promise<void> => {
  try {
    const token = await getIdToken();
    await axios.post(
      `${PROJECT_API_URL}/api/softpos/v1/add_merchant_user`,
      { email, password, merchantId },
      {
        headers: { token },
      },
    );
  } catch (e) {
    console.log(e.exception);
  }
};

export {
  addUser,
  firebaseGoogleLogin,
  firebaseLogin,
  firebaseLogout,
  getEmailLeadsApi,
  getIdToken,
  getMerchantsApi,
  getOnboardingApi,
  getUser,
  getUserDatas,
  resetOwnerFiles,
  updateMerchantApi,
  updateOnboardingApi,
  updateOwnerFileFireStore as updateOwnerFile,
  updateTerminalIMEIApi,
};
