import { useEmailLeads } from 'features/email-leads/hooks';
import { isAuthenticatedSelector } from 'features/user/redux/selectors';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

const AppContainer: React.FC = (props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { getEmailLeads } = useEmailLeads();

  useEffect(() => {
    onPageInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageInit = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }

    Promise.all([getEmailLeads()]);
  }, [getEmailLeads, isAuthenticated]);

  return <>{props.children}</>;
};

export { AppContainer };
