import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Box, Space, Text } from 'components';
import React from 'react';
import * as Styles from './FormGroup.styles';

export type FormGroupProps = {
  icon: React.ReactNode;
  title: string;
  disableCreating?: boolean;
  onCreate?: () => void;
  onDelete?: () => void;
};

const FormGroup: React.FC<FormGroupProps> = (props) => {
  return (
    <Styles.Container>
      <Styles.TitleContainer>
        <Styles.TitleContent>
          {props.icon}
          <Space vertical />
          <Text size="ui3" bold>
            {props.title}
          </Text>
        </Styles.TitleContent>
        <Box>
          {props.onCreate != null && (
            <Button
              type="text"
              disabled={props?.disableCreating}
              onClick={props.onCreate}
            >
              <PlusOutlined />
            </Button>
          )}
          {props.onDelete != null && (
            <Button type="text" onClick={props.onDelete}>
              <MinusOutlined />
            </Button>
          )}
        </Box>
      </Styles.TitleContainer>

      <Styles.ContentContainer>{props.children}</Styles.ContentContainer>
    </Styles.Container>
  );
};

export { FormGroup };
