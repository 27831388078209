/**
 * Imports
 */
import React from 'react';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import * as Styles from './Table.styles';

/**
 * Styling
 */

/**
 * Types
 */
export type TableProps<T> = AntdTableProps<T> & {
  color?: string;
};

/**
 * Table component
 */
function Table<T>({ ...props }: TableProps<T>): JSX.Element {
  // TODO: This break pagination and only shows one result and not total, fix this so override of prev and next is easier
  // const itemRender = (
  //   _: JSX.Element,
  //   type: string,
  //   originalElement: JSX.Element,
  // ) => {
  //   if (type === 'prev') {
  //     return <VibrantIcon icon="arrow-long-left" />;
  //   } else if (type === 'next') {
  //     return <VibrantIcon icon="arrow-long-right" />;
  //   } else {
  //     return originalElement;
  //   }
  // };

  return <Styles.Table<any> {...props} />;
}

/**
 * Exports
 */
export { Table };
