/**
 * Imports
 */
import { HomeOutlined } from '@ant-design/icons';
import { FormGroup } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';
import * as React from 'react';
import { useMemo } from 'react';

import { styled } from 'theme';
import type { Firebase } from 'types/firebase';
import { FormFieldType } from '../MerchantDetailsForm';
import * as Styles from '../MerchantDetailsForm/MerchantDetailsForm.styles';
import { isValid } from 'iban';

/**
 * Styling
 */
const Root = styled.div`
  margin-bottom: 64px;
`;

/**
 * Types
 */
export interface MerchantBankFormProps {
  onSave: (name: string, value: string | number) => void;
  merchant: Firebase.Merchant;
}
const validateIBAN = (value: string | number) => {
  const iban = value.toString();
  return isValid(iban);
};

/**
 * MerchantBankForm component
 */
const MerchantBankForm: React.FC<MerchantBankFormProps> = ({
  merchant,
  onSave,
}) => {
  const formFields = useMemo<FormFieldType[]>(
    () => [
      {
        title: 'Bank',
        icon: <HomeOutlined />,
        inputs: [
          {
            label: 'Swift',
            alternativeLabel: 'Change Swift',
            name: 'swift',
            value: merchant.swift,
          },
          {
            label: 'IBAN',
            alternativeLabel: 'Change IBAN',
            value: merchant.iban,
            name: 'iban',
            hasCopyIcon: true,
            isValid: validateIBAN,
          },
        ],
      },
    ],
    [merchant.iban, merchant.swift],
  );
  return (
    <div>
      {formFields.map((form) => (
        <Styles.FormContainer key={form.title}>
          <FormGroup icon={form.icon} title={form.title}>
            {form.inputs.map((input) => (
              <FormGroupItem
                {...input}
                key={input.name}
                onSave={(newValue) => onSave(input.name, newValue)}
              />
            ))}
          </FormGroup>
        </Styles.FormContainer>
      ))}
    </div>
  );
};

/**
 * Exports
 */
export { MerchantBankForm };
