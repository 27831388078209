import React from 'react';
import { Firebase } from 'types/firebase';
import { ListItem, StyledList } from 'features/transactions';
import { Root } from 'features/merchants/components';
import { Trans } from 'react-i18next';
import { Text } from 'components';

type Props = {
  contactInformation: Firebase.SoftPosOnboarding['contact'];
};
const SignupDetails: React.FC<Props> = (props) => {
  if (!props.contactInformation) {
    return null;
  }

  return (
    <Root>
      <StyledList bordered>
        <ListItem>
          <Text size="ui2" bold>
            <Trans i18nKey="Signups.signup_details.title" />
          </Text>
        </ListItem>
        <ListItem>
          <div>
            <Text size="ui3" bold>
              Name
            </Text>
          </div>
          <Text size="ui3">{props.contactInformation.fullName}</Text>
        </ListItem>
        <ListItem>
          <div>
            <Text size="ui3" bold>
              Email
            </Text>
          </div>
          <Text size="ui3">
            <a href="mailto:">{props.contactInformation.contactEmail}</a>
          </Text>
        </ListItem>
        <ListItem>
          <div>
            <Text size="ui3" bold>
              Phone
            </Text>
          </div>
          <Text size="ui3">
            <a href="tel:">{props.contactInformation.phone}</a>
          </Text>
        </ListItem>
      </StyledList>
    </Root>
  );
};

export { SignupDetails };
