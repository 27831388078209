/**
 * Imports
 */
import * as React from 'react';
import { Radio, RadioProps } from 'antd';
import { Icon } from 'components';
import { styled } from 'theme';
import { CheckOutlined } from '@ant-design/icons';
import { css } from 'styled-components';

/**
 * Styling
 */
const DEFAULT_CSS = css`
  border: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  box-sizing: border-box;
  border-radius: 4px;
  height: 96px;

  padding-left: 26px;
  margin: 8px 0;
  width: 100%;

  display: flex;
  align-items: center;

  & span {
    padding-right: 4px;
    width: 100%;
  }
  &:before {
    z-index: -1;
  }
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.Border.BorderSecondary};
  }
`;
const LIST_CSS = css`
  display: flex;
  align-items: center;

  & span {
    padding-right: 4px;
    width: 100%;
  }
`;

const StyledRadioButton = styled(Radio.Button)<RadioProps & { list?: boolean }>`
  ${(props) => (props.list ? LIST_CSS : DEFAULT_CSS)}
`;

const InnerContent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 32px;
`;
const CheckmarkWrapper = styled.div`
  margin-left: auto;
`;
const Checkmark = styled(Icon)``;

/**
 * Types
 */
export interface RadioButtonProps {
  icon?: React.ReactNode;
  text?: React.ReactNode;
  value: React.ReactNode;
  checked: boolean;
  list?: boolean;
}

/**
 * RadioButton component
 */
const RadioButton: React.FC<RadioButtonProps> = ({
  icon,
  text,
  value,
  checked,
  list,
}) => {
  return (
    <StyledRadioButton value={value} list={list}>
      <InnerContent>
        <StyledIcon icon={icon} size={32} />
        {text}
        {checked && (
          <CheckmarkWrapper>
            <Checkmark icon={<CheckOutlined />} color="Orange500" />
          </CheckmarkWrapper>
        )}
      </InnerContent>
    </StyledRadioButton>
  );
};

/**
 * Exports
 */
export { RadioButton };
