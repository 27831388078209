import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  gap: 20px;
`;

export const Card = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
`;
