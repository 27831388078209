import { HomeOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { FormGroup } from 'components';
import { useFilesApi } from 'features/merchants/hooks';
import { showAddOwnerModal } from 'features/merchants/redux';
import { isKYCSelector } from 'features/user/redux/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Firebase } from 'types/firebase';
import { MerchantOwnerForm } from '../MerchantOwnerForm';

export type MerchantOwnersDetailsProps = {
  owners: Firebase.SoftPosOnboarding['ownerData'] | null;
  onboardingId: string;
  merchantId: string;
  onSave: (
    ownerIndex: number,
    property: string,
    value: string | number,
  ) => Promise<void>;
};

const MerchantOwnersDetails: React.FC<MerchantOwnersDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const isKYCAdmin = useSelector(isKYCSelector);
  const _showModal = () => {
    dispatch(showAddOwnerModal({}));
  };

  const {
    handlers: { onDeleteAllImages },
  } = useFilesApi({});

  /**
   * _deleteAllImages
   */
  const _deleteAllImages = async () => {
    if (confirm('Are you sure you want to delete all images?') == true) {
      await onDeleteAllImages(props.onboardingId, props.merchantId);
    } else {
    }
  };

  return (
    <FormGroup
      icon={<HomeOutlined />}
      title="Owners"
      onCreate={_showModal}
      onDelete={isKYCAdmin ? _deleteAllImages : undefined}
    >
      <Collapse>
        {props.owners?.map((owner, ownerIndex) => (
          <Collapse.Panel key={owner.name} header={owner.name}>
            <MerchantOwnerForm
              owner={owner}
              ownerIndex={ownerIndex}
              onSave={(name, value) => props.onSave(ownerIndex, name, value)}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </FormGroup>
  );
};

export { MerchantOwnersDetails };
