/**
 * Imports
 */
import * as React from 'react';
import baseStyled, {
  ThemedStyledInterface,
  ThemeProvider as BaseThemeProvider,
  createGlobalStyle,
} from 'styled-components';

import { VibrantColors } from './colors';
import { Transitions } from './transitions';

/**
 * GloblaStyle
 */
const GlobalStyle = createGlobalStyle`
  body,
  html {
    font-family: 'BasierRegular', apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%
  }
  #root {
  height: 100%
}
  span {
    display: block;
  }
  .ant-table-filter-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  // Dropdown override
  .ant-select-dropdown {
    background-color: white;
  }
  /* Antd message override styling / Global snackbar */
  .vibrant-error-message .ant-message-notice-content {
    background-color:  ${VibrantColors.Message.Danger};
    color: ${VibrantColors.Text.Danger}
  }
  .vibrant-warning-message .ant-message-notice-content {
    background-color:  ${VibrantColors.Message.Warning};
    color: ${VibrantColors.Text.Warning}
  }
  .vibrant-success-message .ant-message-notice-content {
    background-color:  ${VibrantColors.Message.Success};
    color: ${VibrantColors.Text.Success}
  }
  .vibrant-info-message .ant-message-notice-content {
   background-color: ${VibrantColors.Message.Info};
   color: ${VibrantColors.Text.Info}
  }
  .ant-message-notice-content {
    font-weight: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20.8px;
    box-shadow: 0px 2px 8px rgba(20, 20, 20, 0.1);
    border-radius: 4px;
    padding: 12px 24px;
  }
  .ant-message-custom-content {
    display: flex;
    align-items: center;
  }
  .ant-message-notice-content .anticon-info-circle {
    display: none;
  }
  /* RangePicker */
  .ant-picker-cell::before {
    height: 40px;
  }
  .ant-picker-cell .ant-picker-cell-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
  .ant-picker-cell-today .ant-picker-cell-inner  {
    background-color: none!important;
    border-radius: 50%;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner  {
    background-color: ${VibrantColors.Base.Brand}!important;
    border-radius: 50%;
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: ${VibrantColors.Shades.Orange100}!important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner  {
    background-color: ${VibrantColors.Base.Brand}!important;
    border-radius: 50%;
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

/**
 * Theme
 */
const theme = {
  colors: VibrantColors,
  transitions: Transitions,
};

/**
 * Types
 */
export type VibrantTheme = typeof theme;

/**
 * `styled` object for themed styled-components
 */
const styled: ThemedStyledInterface<VibrantTheme> = baseStyled;
const ThemeProvider: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
  </>
);

/**
 * Exports
 */
export { theme, styled, ThemeProvider, Transitions, VibrantColors };
