import React, { useMemo } from 'react';
import { HomeOutlined } from '@ant-design/icons';

import { FormGroupItem } from 'components/FormGroupItem';
import { FormGroup } from 'components';

import type { Firebase } from 'types/firebase';
import { styled } from 'theme';
import { CAMPAIGN_OPTIONS } from '../../../../constants';
import { getOptionValueText } from 'utils/OptionUtils';
/**
 * Styling
 */
const Container = styled.div``;

/**
 * Types
 */
export type MerchantNoteFormProps = {
  merchant: Firebase.Merchant;
  onSave: (name: string, value: string | number) => void;
};

type FormFieldType = {
  title: string;
  icon: React.ReactNode;
  inputs: {
    type?: string;
    label: string;
    alternativeLabel: string;
    name: string;
    value: string | number | null;
  }[];
};

/**
 * MerchantNoteForm component
 */
const MerchantNoteForm: React.FC<MerchantNoteFormProps> = (props) => {
  // @ts-ignore
  const formFields = useMemo<FormFieldType[]>(() => {
    const { merchant } = props;

    const campaignValue =
      // @ts-ignore
      getOptionValueText(
        CAMPAIGN_OPTIONS,
        merchant?.campaign?.qpCampaign || merchant?.campaign?.noCampaign,
      ) ?? 'NOT SET';

    return [
      {
        title: 'Notes',
        icon: <HomeOutlined />,
        inputs: [
          {
            type: 'dropdown',
            label: 'Campaign',
            alternativeLabel: 'Campaign',
            name: 'campaign',
            value: campaignValue,
            dropdown: {
              placeholder: 'Select a campaign',
              values: CAMPAIGN_OPTIONS,
              options: CAMPAIGN_OPTIONS,
            },
          },
          {
            type: 'textarea',
            label: 'Notes',
            alternativeLabel: 'Leave a note',
            name: 'noteAboutMerchant',
            value: merchant.noteAboutMerchant,
          },
          {
            label: 'Business Activity Description',
            alternativeLabel: 'Change the business description',
            name: 'businessActivityDescription',
            value:
              merchant.businessActivityDescription?.length > 0
                ? merchant.businessActivityDescription
                : '',
            hasCopyIcon: true,
          },
        ],
      },
    ];
  }, [props]);

  return (
    <div>
      {formFields.map((form) => (
        <Container key={form.title}>
          <FormGroup icon={form.icon} title={form.title}>
            {form.inputs.map((input) => (
              <FormGroupItem
                {...input}
                key={input.name}
                onSave={(newValue) => props.onSave(input.name, newValue)}
              />
            ))}
          </FormGroup>
        </Container>
      ))}
    </div>
  );
};

export { MerchantNoteForm };
