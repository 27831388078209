import { updateMerchantApi, updateTerminalIMEIApi } from 'firebaseAPI';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { Firebase } from 'types/firebase';
import { merchantSelector } from '../redux';

export type useMerchantProps = (
  merchantId: string,
) => {
  updateMerchant: (args: Partial<Firebase.Merchant>) => Promise<void>;
  updateTerminalIMEI: (
    merchantId: string,
    terminalId: string,
    value: Pick<Firebase.Terminal, 'imei'>,
  ) => Promise<void>;
};

const useMerchant: useMerchantProps = (merchantId) => {
  const { id } = useParams<{ id: string }>();

  const merchant: Firebase.Merchant = useSelector(merchantSelector(merchantId));

  const updateMerchant = async (args: Partial<Firebase.Merchant>) => {
    try {
      let convertedArgs = args;
      if (args.vat) {
        convertedArgs = { ...args, vat: Number(args.vat) };
      }
      await updateMerchantApi(merchantId, convertedArgs);
    } catch (error) {
      alert(JSON.stringify(error.message));
    }
  };

  const updateTerminalIMEI = async (
    merchantId: string,
    terminalId: string,
    imei: Pick<Firebase.Terminal, 'imei'>,
  ) => {
    try {
      await updateTerminalIMEIApi(merchantId, terminalId, imei);
    } catch (error) {
      alert(JSON.stringify(error.message));
    }
  };

  return { merchant, updateMerchant, updateTerminalIMEI };
};

export { useMerchant };
