import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { Moment } from 'moment';
import { VibrantAPI } from 'types/vibrant';
import { SoftPos } from 'types/softpos';

/**
 * Get all settlements from a specific period
 */
export const getSettlementsMerchantApi = async (
  merchantId: string,
  currentPage: number,
  pageSize: number,
): Promise<SoftPos.Settlement[]> => {
  const idToken = await getIdToken();
  try {
    const params = {
      merchantId: merchantId,
      page: currentPage,
      size: pageSize,
    };
    const response = await axios.get(
      `${PROJECT_API_URL}/api/softpos/v2/settlements_on_merchantid`,
      {
        params,
        headers: { token: idToken },
      },
    );
    return response.data.result.settlements as SoftPos.Settlement[];
  } catch (error) {
    console.log('getSettlementsApi error: ', error);
    return [];
  }
};

/**
 * Get all settlements from a specific period
 */
export const getSettlementsApi = async (
  startDate: Moment,
  endDate: Moment,
  currentPage: number,
  pageSize: number,
): Promise<VibrantAPI.GetSettlementsResult | []> => {
  const idToken = await getIdToken();
  try {
    const utcStart = startDate.clone().utc();
    const utcEnd = endDate.clone().utc();

    const params = {
      from: utcStart.format(),
      to: utcEnd.format(),
      page: currentPage - 1,
      size: pageSize,
    };
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/softpos/v2/settlements`,
      {
        params,
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetSettlementsResponse;
    return response.data.result;
  } catch (error) {
    console.log('getSettlementsApi error: ', error);
    return [];
  }
};
