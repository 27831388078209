/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { MerchantFilters } from './reducer';

/**
 * Constants
 */
const PREFIX = 'merchants';

/**
 * SHOW_OWNER_MODAL
 */
export const SHOW_OWNER_MODAL = `${PREFIX}/SHOW_OWNER_MODAL`;
export type ShowOwnerModalAction = VibrantlyAction<object>;
export const showAddOwnerModal = createAction<ShowOwnerModalAction['payload']>(
  SHOW_OWNER_MODAL,
);
/**
 * HIDE_OWNER_MODAL
 */
export const HIDE_OWNER_MODAL = `${PREFIX}/HIDE_OWNER_MODAL`;
export type HideOwnerModalAction = VibrantlyAction<object>;
export const hideAddOwnerModal = createAction<HideOwnerModalAction['payload']>(
  HIDE_OWNER_MODAL,
);
/**
 * SET_FILTERS
 */
export const SET_FILTERS = `${PREFIX}/SET_FILTERS`;
export type SetFiltersAction = VibrantlyAction<MerchantFilters>;
export const setFilters = createAction<SetFiltersAction['payload']>(
  SET_FILTERS,
);
/**
 * CLEAR_FILTERS
 */
export const CLEAR_FILTERS = `${PREFIX}/CLEAR_FILTERS`;
export type ClearFiltersAction = VibrantlyAction<object>;
export const clearFilters = createAction<ClearFiltersAction['payload']>(
  CLEAR_FILTERS,
);
