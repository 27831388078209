import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  isAdminSelector,
  isLink4PaySupporterSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { FirebaseCollectionsType } from 'firebaseAPI';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import type { Firebase } from 'types/firebase';
import { filtersSelector, merchantsSelector } from '../redux';

/**
 * CONST
 */
const LINK4_PAY_COUNTRY_CODE = ['GR', 'CY'];

/**
 * Types
 */
export type MerchantSelectOpt = { name: string; merchantId: string };
export type OwnerSelectOpt = { name: string; merchantId: string };
export type useMerchantsHandlers = {
  onShowLink4Pay: (e: CheckboxChangeEvent) => void;
  onSelectMerchant: (merchantId: string) => void;
  onClearSelectMerchant: () => void;
};
export type useMerchantsProps = () => {
  merchants: Firebase.Merchant[];
  merchantsSelectOpts: MerchantSelectOpt[];
  ownerSelectOpts: OwnerSelectOpt[];
  handlers: useMerchantsHandlers;
  selectedMerchantId: string | null;
  isFilterLoading: boolean;
};

const useMerchants: useMerchantsProps = () => {
  /* Redux */
  const allMerchants: Firebase.Merchant[] = useSelector(merchantsSelector);
  const filters = useSelector(filtersSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isLink4Pay = useSelector(isLink4PaySupporterSelector);
  const user = useSelector(userSelector);
  /* Local state */
  const [isFilterLoading, setIsFilterLoading] = React.useState(false);
  const [showLink4Pay, setShowLink4Pay] = React.useState(false);
  const [merchantsSelectOpts, setMerchantsSelectOpts] = React.useState<
    MerchantSelectOpt[]
  >([]);
  const [ownerSelectOpts, setOwnerSelectOpts] = React.useState<
    OwnerSelectOpt[]
  >([]);
  const [merchantData, setMerchantData] = React.useState<Firebase.Merchant[]>(
    [],
  );
  const [selectedMerchantId, setSelectedMerchantId] = React.useState<
    null | string
  >(null);

  useFirestoreConnect({
    collection: FirebaseCollectionsType.MERCHANTS,
    storeAs: FirebaseCollectionsType.MERCHANTS,
    where: isAdmin ? undefined : ['countryCode', 'in', user.meta.countries],
    orderBy: ['onboardedAt', 'desc'],
  });

  /**
   * Create unique owners search options
   */

  const _createOwnersSearchOpts = () => {
    const ownerSelectOptions = allMerchants
      ? allMerchants
          .filter(
            (merchant) =>
              merchant.ownerData && Array.isArray(merchant.ownerData),
          )
          .flatMap((merchant) =>
            merchant.ownerData.map((ownerData) => {
              return {
                name: ownerData.name,
                merchantId: merchant.merchantId,
              };
            }),
          )
      : [];
    setOwnerSelectOpts(ownerSelectOptions);
  };
  /**
   * Create unique merchant search options
   */
  const _createSelectOpt = () => {
    const merchantsTradingName = orderBy(
      allMerchants,
      ['merchantTradingName'],
      ['asc'],
    ).map((m) => {
      const name =
        m?.businessLegalName && m?.businessLegalName !== m?.merchantTradingName
          ? `${m?.merchantTradingName} (${m.businessLegalName})`
          : `${m.merchantTradingName}`;
      return {
        name,
        merchantId: m.merchantId,
      };
    });
    setMerchantsSelectOpts(merchantsTradingName);
  };

  /**
   * Filter merchant data
   */
  const _setMerchantData = (merchants: Firebase.Merchant[]) => {
    setMerchantData(merchants);

    // isLink4Pay return all merchants, as filters are not available
    if (isLink4Pay) {
      return;
    }
    if (!merchants) {
      return;
    }

    setIsFilterLoading(true);
    // Filter out Link4Pay
    const link4PayfilteredMerchants = showLink4Pay
      ? merchants
      : merchants.filter((m) => {
          return !LINK4_PAY_COUNTRY_CODE.some(
            (filterEl) => m.countryCode === filterEl,
          );
        });
    // Filter by countryCode
    const filteredMerchants = filters.countryCode
      ? link4PayfilteredMerchants.filter((merchant) => {
          return filters?.countryCode?.includes(merchant.countryCode);
        })
      : link4PayfilteredMerchants;
    // Filter by vibrantStatus
    const statusFilteredMerchants = filters.clearhausStatus
      ? filteredMerchants.filter((m) => {
          return (
            m.readyForApplication &&
            !(m?.clearhausEcomMerchantId || m?.clearhausMerchantId)
          );
        })
      : filters.vibrantStatus
      ? filteredMerchants.filter((m) => {
          return m.readyForApplication === null;
        })
      : filteredMerchants;

    const _merchants = filters.ecom
      ? statusFilteredMerchants.filter(
          (merchant) =>
            merchant?.merchantPaymentType === 'E_COM' ||
            merchant?.merchantPaymentType === 'IN_STORE_AND_ECOM',
        )
      : filters.paymentLinks
      ? statusFilteredMerchants.filter(
          (merchant) =>
            merchant?.merchantPaymentType === 'PAYMENT_LINKS' ||
            merchant?.merchantPaymentType === 'IN_STORE_AND_PAYMENT_LINKS' ||
            merchant?.merchantPaymentType === 'IN_STORE_AND_ECOM',
        )
      : filters.inStore
      ? statusFilteredMerchants.filter(
          (merchant) =>
            merchant?.merchantPaymentType === 'IN_STORE' ||
            merchant?.merchantPaymentType === 'IN_STORE_AND_PAYMENT_LINKS' ||
            merchant?.merchantPaymentType === 'IN_STORE_AND_ECOM',
        )
      : statusFilteredMerchants;

    setIsFilterLoading(false);

    setMerchantData(_merchants);
  };

  React.useEffect(() => {
    _setMerchantData(allMerchants);
  }, [filters]);

  React.useEffect(() => {
    _setMerchantData(allMerchants);
  }, [showLink4Pay]);

  React.useEffect(() => {
    if (!selectedMerchantId) {
      _createOwnersSearchOpts();
      _createSelectOpt();
    }
    _setMerchantData(allMerchants);
  }, [allMerchants]);

  React.useEffect(() => {
    if (selectedMerchantId) {
      const selectedMerchant = allMerchants.filter(
        (m) => m.merchantId === selectedMerchantId,
      );
      setMerchantData(selectedMerchant);
    } else {
      _setMerchantData(allMerchants);
    }
  }, [selectedMerchantId]);

  /**
   * Handlers
   */
  const handlers: useMerchantsHandlers = {
    onShowLink4Pay: (e: CheckboxChangeEvent) => {
      setShowLink4Pay(e.target.checked);
    },
    onSelectMerchant: (merchantId: string) => {
      setSelectedMerchantId(merchantId);
    },
    onClearSelectMerchant: () => {
      setSelectedMerchantId(null);
    },
  };

  return {
    merchants: merchantData,
    merchantsSelectOpts,
    ownerSelectOpts,
    handlers,
    selectedMerchantId,
    isFilterLoading,
  };
};

export { useMerchants };
