import {
  HomeOutlined,
  PropertySafetyOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { Collapse, Empty, Modal } from 'antd';
import {
  Box,
  Button,
  CopyButton,
  FormGroup,
  Icon,
  Text,
  VibrantIcon,
} from 'components';
import { FormGroupItem } from 'components/FormGroupItem';
import * as Styles from 'components/FormGroupItem/FormGroupItem.styles';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { formatTime } from 'utils';
import { Container } from './MerchantTerminalDetails.styles';
import { luhn } from 'cdigit';
import type { Firebase } from 'types/firebase';
import { styled } from 'theme';
import { useDispatch } from 'react-redux';
import { showAddTerminalModal } from 'features/navigation/redux/actions';
import { AddTerminalModal } from './AddTerminalModal';
import { updateTerminal } from 'apis';
import { CreateQRLogin } from 'features/merchants';

const StyledEmpty = styled(Empty)`
  && {
    margin: 0;
  }
`;

export type MerchantTerminalDetailsProps = {
  merchantId: string;
  terminals: Firebase.Terminal[];
  onSave: (terminalId: string, value: string) => Promise<void>;
};

const MerchantTerminalDetails: React.FC<MerchantTerminalDetailsProps> = (
  props,
) => {
  const [isForcingValidationOnIMEI, setIsForcingValidationOnIMEI] = useState(
    false,
  );
  const [showModal, setShowModal] = React.useState(false);

  const labels = (terminal: Firebase.Terminal) => [
    {
      label: 'Merchant Id',
      value: props.merchantId,
      copy: true,
    },
    {
      label: 'Terminal Id',
      value: terminal.terminalId,
      copy: true,
    },
    {
      label: 'Created At',
      value: terminal.createdAt ?? 'N/A',
    },
  ];

  const createLabel = (label: {
    label: string;
    value: string | number;
    copy?: boolean;
  }) => (
    <Styles.Container>
      <Styles.LabelContainer>
        <Text size="ui3">{label.label}</Text>
        <Styles.ValueText size="ui2">
          {typeof label.value === 'number'
            ? formatTime(label.value)
            : label.value}
        </Styles.ValueText>
      </Styles.LabelContainer>
      {label.copy && <CopyButton text={label.value.toString()} />}
    </Styles.Container>
  );
  const handleUpdatingTerminalIMEI = useCallback(
    () => setIsForcingValidationOnIMEI(true),
    [],
  );

  const alertDescription = useMemo(
    () => (
      <Fragment>
        It seems the IMEI is not correct,
        <span
          onClick={handleUpdatingTerminalIMEI}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          click here if you are sure that the IMEI is correct
        </span>
      </Fragment>
    ),
    [handleUpdatingTerminalIMEI],
  );

  const isOnlyDigits = (myString: string) => /\d/.test(myString);

  const validateIMEI = useCallback(
    (IMEI: string | number) => {
      const newIMEI = IMEI.toString();

      if (newIMEI.length < 3 || !isOnlyDigits) {
        setIsForcingValidationOnIMEI(false);
        return true;
      }

      if (newIMEI.length === 0) {
        setIsForcingValidationOnIMEI(false);
      }

      // The IMEI (15 decimal digits: 14 digits plus a check digit)
      // which is why we're using luhn/10mod algorithm as the check digit validator for IMEI.
      if (
        (newIMEI.length === 15 && luhn.validate(newIMEI)) ||
        isForcingValidationOnIMEI
      ) {
        return true;
      }

      return false;
    },
    [isForcingValidationOnIMEI],
  );

  const dispatch = useDispatch();

  const _showTerminalModal = () => {
    dispatch(showAddTerminalModal({}));
  };

  const updateTerminalIMEI = useCallback(
    (terminalId: string): ((text: string | number) => void) | undefined => (
      newIMEI,
    ) => {
      const IMEI = newIMEI.toString();

      if (validateIMEI(IMEI)) {
        props.onSave(terminalId, IMEI);
      }

      setIsForcingValidationOnIMEI(false);
    },
    [props, validateIMEI],
  );

  const updateTerminalName = useCallback(
    (
      terminalId: string,
    ): ((text: string | number) => void) | undefined => async (
      newTerminalName,
    ) => {
      await updateTerminal(
        { name: String(newTerminalName) },
        props.merchantId,
        terminalId,
      );
    },
    [props],
  );

  const updateActivationCode = useCallback(
    (
      terminalId: string,
    ): ((text: string | number) => void) | undefined => async (
      newActivationCode,
    ) => {
      await updateTerminal(
        { activationCode: String(newActivationCode) },
        props.merchantId,
        terminalId,
      );
    },
    [props],
  );

  return (
    <Container>
      <FormGroup
        icon={<HomeOutlined />}
        title="Terminal"
        onCreate={() => _showTerminalModal()}
      >
        <AddTerminalModal />
        {props.terminals?.length > 0 ? (
          <Collapse>
            {props.terminals?.map((terminal) => (
              <Collapse.Panel key={terminal.terminalId} header={terminal.name}>
                {labels(terminal).map((label) => (
                  <Fragment key={label.label}>{createLabel(label)}</Fragment>
                ))}

                <FormGroupItem
                  label="Name"
                  name="Name"
                  alternativeLabel="Change Name"
                  value={terminal.name}
                  onSave={updateTerminalName(terminal.terminalId)}
                />

                <FormGroupItem
                  label="Activation Code"
                  name="Activation Code "
                  alternativeLabel="Change Activation Code"
                  value={terminal.activationCode}
                  onSave={updateActivationCode(terminal.terminalId)}
                />

                <FormGroupItem
                  label="IMEI"
                  name="IMEI"
                  isValid={validateIMEI}
                  alertDescription={alertDescription}
                  alternativeLabel="Change IMEI"
                  value={terminal.imei ?? ''}
                  onSave={updateTerminalIMEI(terminal.terminalId)}
                />
                <Box justifyContent="space-between" display="flex">
                  <Box />
                  <Button onClick={() => setShowModal(true)} type="primary">
                    <Box display="flex" alignItems="center">
                      <Box mr="8px">
                        {/* @ts-ignore */}
                        <Icon icon={<QrcodeOutlined />} color="Brand500" />
                      </Box>
                      <Text size="ui2"> Create QR login</Text>
                    </Box>
                  </Button>
                </Box>
                <Modal
                  visible={showModal}
                  onCancel={() => setShowModal(false)}
                  footer={null}
                  closeIcon={<VibrantIcon icon="close" size={20} />}
                  style={{ maxWidth: 500, width: '100%' }}
                >
                  <CreateQRLogin terminal={terminal} />
                </Modal>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <StyledEmpty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No terminals"
          />
        )}
      </FormGroup>
    </Container>
  );
};

export { MerchantTerminalDetails };
