/**
 * Imports
 */
import * as React from 'react';
import { DatePicker as DatePickerAntd } from 'antd';
const { RangePicker } = DatePickerAntd;
import { Moment } from 'moment';
import { styled } from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  endDateSelector,
  startDateSelector,
} from 'features/transactions/redux/selectors';
import { setDates } from 'features/transactions/redux/actions';
import { DATE_FORMATS } from 'utils';
import moment from 'moment';
import { FixedDatesRange } from 'features/transactions/types';

/**
 * Styling
 */
const Root = styled.div`
  @media (max-width: 600px) {
    max-width: calc(100vw - 48px);
  }
`;

/**
 * Types
 */
export interface DatePickerProps {}

/**
 * DatePicker component
 */
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const endDate = useSelector(endDateSelector);
  const startDate = useSelector(startDateSelector);
  const dispatch = useDispatch();

  const _onChange = (dates: any) => {
    if (!!dates) {
      const [startDate, endDate] = dates as Moment[];
      dispatch(
        setDates({ endDate, startDate, fixedRange: FixedDatesRange.NONE }),
      );
    } else {
      dispatch(
        setDates({
          endDate: moment().endOf('day'),
          startDate: moment().startOf('day'),
          fixedRange: FixedDatesRange.NONE,
        }),
      );
    }
  };

  return (
    <Root>
      <RangePicker
        showTime={{ format: 'HH:mm' }}
        defaultValue={[startDate, endDate]}
        format={DATE_FORMATS.time}
        value={[endDate, startDate]}
        onChange={_onChange}
      />
    </Root>
  );
};

/**
 * Exports
 */
export { DatePicker };
