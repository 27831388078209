import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 30px auto 0;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 100px;
  margin-top: 50px;
`;
