import React, { useEffect, useMemo } from 'react';
import { Typography } from 'antd';

import { BaseLayout } from 'features/navigation/components';
import { EmailLeadsTable } from '../components';
import { useEmailLeads } from '../hooks';

const EmailLeadsPage: React.FC = () => {
  const { data, isLoading, getEmailLeads } = useEmailLeads();

  useEffect(() => {
    getEmailLeads();
  }, []);

  const dataSource = useMemo(() => {
    return data.map((lead) => ({ key: lead.email, email: lead.email }));
  }, [data]);

  return (
    <BaseLayout>
      <Typography.Title>Email Leads</Typography.Title>
      <EmailLeadsTable dataSource={dataSource} loading={isLoading} />
    </BaseLayout>
  );
};

export { EmailLeadsPage };
