/**
 * Imports
 */
import { combineReducers } from '@reduxjs/toolkit';

import features from 'features';

import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

/**
 * The app's reducer
 */
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  navigation: features.navigation.redux.reducer,
  user: features.user.redux.reducer,
  signups: features.signups.redux.reducer,
  merchants: features.merchants.redux.reducer,
  emailLeads: features.emailLeads.redux.reducer,
  transactions: features.transactions.redux.reducer,
});

/**
 * Exports
 */
export { rootReducer };
