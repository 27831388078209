import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { RadioChangeEvent, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Box, Icon, Table } from 'components';
import { useMerchants } from 'features/merchants';
import * as React from 'react';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router/RootRouter';
import type { Firebase } from 'types/firebase';
import { formatTime } from 'utils';
import styled from 'styled-components';
import { ColumnSearchProps } from 'utils/ColumnSearchProps';
import { useEffect, useState } from 'react';
import { updateMerchantApi } from 'firebaseAPI';
import { Checkbox } from 'components/CheckBox';

const DEFAULT_PAGINATED_PAGE_SIZE = 50;

export type MerchantsTableProps = {
  merchants: Firebase.Merchant[];
};

export const Container = styled.div``;

const MerchantsTable: React.FC<MerchantsTableProps> = ({ merchants }) => {
  const history = useHistory();
  const { isFilterLoading } = useMerchants();
  const [removedMerchants, setRemovedMerchants] = useState<
    Map<string, boolean>
  >(new Map());
  const [showAll, setShowAll] = useState<boolean>(false);

  useEffect(() => {
    const hiddenMap = new Map();
    merchants?.forEach((merchant) => {
      hiddenMap.set(merchant.merchantId, merchant.removeFromMerchant);
    });
    setRemovedMerchants(hiddenMap);
  }, [merchants]);

  const _onChangeMerchantVisibility = async (
    e: RadioChangeEvent,
    merchantId: string,
  ) => {
    const value = e.target.checked;
    await updateMerchantApi(merchantId, { removeFromMerchant: value });
  };
  const _onCheckboxClick = (e: RadioChangeEvent) => {
    setShowAll(e.target.checked);
  };

  const columns = React.useMemo<ColumnsType<Firebase.Merchant>>(() => {
    return [
      {
        title: 'Country',
        dataIndex: 'countryCode',
        key: 'countryCode',
        render: (countryCode) => {
          return (
            <ReactCountryFlag
              style={{
                fontSize: '32px',
                lineHeight: '32px',
              }}
              title={countryCode}
              countryCode={countryCode}
              svg
            />
          );
        },
      },
      {
        title: 'Trading Name',
        dataIndex: 'merchantTradingName',
        key: 'merchantTradingName',
        render: (_, record) => {
          const name = record.merchantTradingName;
          return <Typography.Text>{name}</Typography.Text>;
        },
        ...ColumnSearchProps('merchantTradingName'),
      },
      {
        title: 'Legal Name',
        dataIndex: 'businessLegalName',
        key: 'businessLegalName',
        render: (_, record) => {
          const name = record.businessLegalName;
          return <Typography.Text>{name}</Typography.Text>;
        },
        ...ColumnSearchProps('businessLegalName'),
      },
      {
        title: 'Registration Number',
        dataIndex: 'companyRegistrationNumber',
        key: 'companyRegistrationNumber',
        ...ColumnSearchProps('companyRegistrationNumber'),
      },
      {
        title: 'Onboarded At',
        key: 'onboardedAt',
        render: (_, record) => {
          const onboardedAt = record.onboardedAt
            ? formatTime(record.onboardedAt)
            : formatTime(record.createdAt);
          return <Typography.Text>{onboardedAt}</Typography.Text>;
        },
      },
      {
        title: 'Note',
        key: 'noteAboutMerchant',
        dataIndex: 'noteAboutMerchant',
        render: (note) => {
          return (
            <Tooltip title={note}>
              <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                {note}
              </Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title: 'Type',
        key: 'merchantPaymentType',
        dataIndex: 'merchantPaymentType',
        render: (merchantPaymentType: Firebase.MerchantPaymentType) => {
          return (
            <>
              {merchantPaymentType === 'E_COM' ? (
                <Tag color="#E7DAFB">
                  <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                    e-com
                  </Typography.Text>
                </Tag>
              ) : merchantPaymentType === 'IN_STORE' ? (
                <Tag color="#DDF4B8">
                  <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                    in-store
                  </Typography.Text>
                </Tag>
              ) : merchantPaymentType === 'PAYMENT_LINKS' ? (
                <Tag color="#FCD0B1">
                  <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                    payment links
                  </Typography.Text>
                </Tag>
              ) : merchantPaymentType === 'IN_STORE_AND_PAYMENT_LINKS' ? (
                <Box display="flex">
                  <Tag color="#DDF4B8">
                    <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                      in-store
                    </Typography.Text>
                  </Tag>
                  <Tag color="#FCD0B1">
                    <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                      payment links
                    </Typography.Text>
                  </Tag>
                </Box>
              ) : merchantPaymentType === 'IN_STORE_AND_ECOM' ? (
                <Box display="flex">
                  <Tag color="#FFFBC2">
                    <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                      all payment methods
                    </Typography.Text>
                  </Tag>
                </Box>
              ) : (
                <Tag color="#F9F8F1">
                  <Typography.Text ellipsis style={{ maxWidth: '120px' }}>
                    not set
                  </Typography.Text>
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: 'Live',
        key: 'live',
        sorter: (a, b) => {
          const aIsLive = Boolean(
            a?.readyForApplication &&
              a.clearhausKYCdone &&
              a.terminalCollection.length > 0,
          );
          const bIsLive = Boolean(
            b?.readyForApplication &&
              b.clearhausKYCdone &&
              b.terminalCollection.length > 0,
          );
          return (aIsLive ? -1 : 1) - (bIsLive ? -1 : 1);
        },
        render: (_, record) => {
          const isLive = Boolean(
            record?.readyForApplication &&
              record.clearhausKYCdone &&
              record.terminalCollection.length > 0,
          );
          const liveIcon = (
            <Icon icon={<CheckCircleOutlined />} color="Green300" />
          );
          const notLiveIcon = <Icon icon={<StopOutlined />} color="Red300" />;

          return (
            <Typography.Text>{isLive ? liveIcon : notLiveIcon}</Typography.Text>
          );
        },
      },
      {
        title: 'Vibrant Status',
        key: 'readyForApplication',
        sorter: (a, b) =>
          (a.readyForApplication ? -1 : 1) - (b.readyForApplication ? -1 : 1),
        render: (_, record) => {
          const isApproved = Boolean(record.readyForApplication);
          const color = isApproved ? 'green' : 'orange';
          const translationKey = isApproved
            ? 'Global.vibrantStatus.approved'
            : 'Global.vibrantStatus.pending';

          return (
            <Box>
              <Tag key={record.onboardingId} color={color}>
                <Trans i18nKey={translationKey} />
              </Tag>
            </Box>
          );
        },
      },
      {
        title: 'Clearhaus POS Status',
        key: 'clearhausKYCdone',
        sorter: (a, b) =>
          (a.clearhausKYCdone ? -1 : 1) - (b.clearhausKYCdone ? -1 : 1),
        render: (_, record) => {
          const isApproved = Boolean(record.clearhausKYCdone);
          const color = isApproved ? 'green' : 'orange';
          const translationKey = isApproved
            ? 'Global.statuses.approved'
            : 'Global.statuses.pending';

          return (
            <Tag key={record.onboardingId} color={color}>
              <Trans i18nKey={translationKey} />
            </Tag>
          );
        },
      },
      {
        title: 'Clearhaus ECOM Status',
        key: 'clearhausEcomMerchantId',
        sorter: (a, b) =>
          (a.clearhausEcomMerchantId ? -1 : 1) -
          (b.clearhausEcomMerchantId ? -1 : 1),
        render: (_, record) => {
          const isApproved = Boolean(record.clearhausEcomMerchantId);
          const color = isApproved ? 'green' : 'orange';
          const translationKey = isApproved
            ? 'Global.statuses.approved'
            : 'Global.statuses.pending';

          return (
            <Tag key={record.onboardingId} color={color}>
              <Trans i18nKey={translationKey} />
            </Tag>
          );
        },
      },
      {
        title: 'Visibility',
        render: (_, record) => {
          return (
            <a onClick={(event) => event.stopPropagation()}>
              <Checkbox
                checked={record.removeFromMerchant}
                onChange={(e: RadioChangeEvent) =>
                  _onChangeMerchantVisibility(e, record.merchantId)
                }
              >
                Hide
              </Checkbox>
            </a>
          );
        },
      },
    ];
  }, [merchants]);

  return (
    <Container>
      <Box display="flex" mb="24px" justifyContent="flex-end" mr="16px">
        <Checkbox onChange={_onCheckboxClick} checked={showAll}>
          Show Hidden Merchants
        </Checkbox>
      </Box>
      <Table
        dataSource={
          showAll
            ? merchants
            : merchants?.filter(
                (merchant) => !removedMerchants.get(merchant.merchantId),
              )
        }
        columns={columns}
        rowKey="id"
        loading={isFilterLoading}
        rowClassName="clickable-row"
        pagination={{ defaultPageSize: DEFAULT_PAGINATED_PAGE_SIZE }}
        onRow={(record) => {
          return {
            onClick: () =>
              history.push(`${ROUTES.merchants.route}/${record.merchantId}`),
          };
        }}
      />
    </Container>
  );
};

export { MerchantsTable };
