import styled from 'styled-components';
import { Drawer as AntdDrawer } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 100vw;
  padding: 24px 24px 0px 0px;
`;

export const Drawer = styled(AntdDrawer)`
  && {
    .ant-drawer-content {
      background-color: ${(props) => props.theme.colors.Background.Primary};
    }
    .ant-drawer-body {
      display: flex;
      padding: 0;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  min-height: calc(100vh);
  width: 100%;

  @media (max-width: 600px) {
    min-height: calc(100vh - (48px + 72px));
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Row)`
  justify-content: flex-start;
  width: 100%;
  padding: 24px 0 0 24px;
  margin-bottom: 80px;
  @media (max-width: 600px) {
    margin-bottom: 48px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

export const Link = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  & div {
    color: ${(props) => props.$selected && props.theme.colors.Text.Brand};
  }
`;

export const Top = styled(Column)`
  flex: 1;
  width: 100%;
`;

export const Bottom = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const Footer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.Border.BorderPrimary};
  padding: 24px;
  width: 100%;
`;
