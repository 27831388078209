import { createSelector } from '@reduxjs/toolkit';
import { VibrantlyState } from 'store/types';

export const rootSelector = (state: VibrantlyState) => state.emailLeads;

export const emailLeadsSelector = createSelector(
  [rootSelector],
  (root) => root.emailLeads,
);

export const emailLeadsCountSelector = createSelector(
  [emailLeadsSelector],
  (root) => root.length,
);
