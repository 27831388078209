/**
 * Imports
 */
import { Input as AntdInput } from 'antd';
import { styled } from 'theme';

/**
 * Styled components
 */
const Input = styled(AntdInput)`
  && {
    border-color: ${(props) => props.theme.colors.Input.BorderDefault};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.Input.ColorDefault};
    font-size: 17px;
    font-family: 'BasierRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    height: 48px;
    line-height: 21.76px;
    outline: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.Input.ColorPlaceholder};
    }

    &:focus {
      color: ${(props) => props.theme.colors.Input.ColorFocus};
      box-shadow: none;
      border-color: ${(props) => props.theme.colors.Input.BorderFocus};
    }

    &.ant-input {
      padding: 12px 16px;
    }

    &.ant-input[disabled] {
      background-color: ${(props) =>
        props.theme.colors.Input.BackgroundDisabled};
      border-color: ${(props) => props.theme.colors.Input.BorderDisabled};
    }
  }
`;

Input.Search = styled(AntdInput.Search)`
  && {
    border-color: ${(props) => props.theme.colors.Input.BorderDefault};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.Input.ColorDefault};
    font-size: 17px;
    font-family: 'BasierRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    height: 48px;
    line-height: 21.76px;
    outline: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.Input.ColorPlaceholder};
    }

    .ant-input-affix-wrapper {
      height: 48px;
    }

    .ant-input-search-button {
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.colors.Input.BorderDefault};
      height: 48px;
    }
  }
`;

Input.Password = styled(AntdInput.Password)`
  && {
    border-color: ${(props) => props.theme.colors.Input.BorderDefault};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.Input.ColorDefault};
    font-size: 17px;
    font-family: 'BasierRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    height: 48px;
    line-height: 21.76px;
    outline: none;

    &.ant-input-password {
      padding: 12px 16px;
    }
  }
`;

Input.TextArea = styled(Input.TextArea)`
  && {
    white-space: pre-wrap;
    &.ant-input {
      white-space: pre-wrap;
      padding: 12px 16px;
    }
  }
`;

/**
 * Exports
 */
export { Input };
