/**
 * Imports
 */
import { Input, Button, Alert } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import userFeature from 'features/user';

import { firebaseLogin } from 'firebaseAPI';
import { Divider, Logo, Space, Text } from 'components';

import { styled } from 'theme';
import { LostPassword } from '../ResetPassword';
import { GoogleLogin } from '../GoogleLogin';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  border-radius: 4px;
  padding: 12px 24px;
  max-width: 400px;
`;
const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;
const LoginText = styled.h1`
  color: white;
`;

/**
 * LoginForm component
 */
const LoginForm: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = !!useSelector(
    userFeature.redux.selectors.maybeUserSelector,
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(false);
      history.replace({ pathname: '/' });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const _handleLogin = async () => {
    try {
      setIsLoading(true);
      const user = await firebaseLogin(email, password);
      dispatch(userFeature.redux.actions.userUpdated(user));
      dispatch(userFeature.redux.actions.userEmail({ email }));
      history.replace({ pathname: '/' });
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(null), 3000);
      setIsLoading(false);
    }
  };

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleLogin();
    }
  };

  return (
    <Root>
      <Logo size={132} />

      <Space size="xxl" />
      <GoogleLogin />

      <Divider />

      <Input
        placeholder="hello@example.com"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onKeyDown={_handleKeyDown}
      />

      <Space size="lg" />

      <Input
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyDown={_handleKeyDown}
      />

      <Space size="xl" />

      <Button type="primary" onClick={_handleLogin} loading={isLoading} block>
        <Text size="ui2" bold>
          Login
        </Text>
      </Button>

      {errorMessage && <Alert type="error" message={errorMessage} />}
    </Root>
  );
};

/**
 * Exports
 */
export { LoginForm };
