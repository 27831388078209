/**
 * Imports
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider, ProviderProps } from 'react-redux';

import { RootRouter } from 'router/RootRouter';
import { styled, ThemeProvider } from 'theme';

import { Spinner } from 'components';
import { AppContainer } from 'containers';

import reportWebVitals from './reportWebVitals';
// Store setup
import { storePromise } from './store';
import { useTranslation } from 'react-i18next';
import './translations/config';
import './index.css';
import './styles/fonts/basier.css';
// Antd style overrides
import './styles/antd/less/antd-overrides.less';

// Firebase
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import firebase from 'firebase/app';

const Container = styled.div`
  display: flex;
  align-items: 'center';
  flex: 1;
  min-height: 100vh;
`;

/**
 * `App` component
 */
const App = () => {
  const [store, setStore] = React.useState<ProviderProps['store'] | null>(null);
  useTranslation();
  React.useEffect(() => {
    storePromise
      .then((_store) => setStore(_store))
      .catch(() => console.log('Error occurred getting store ready'));
  }, []);

  if (!store) {
    return (
      <Container>
        <Spinner showSpinner />
      </Container>
    );
  }

  /* Firebase setup*/
  const reactReduxFirebaseProps = {
    firebase,
    config: {},
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  return (
    <React.StrictMode>
      <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
        <ThemeProvider>
          <Provider store={store}>
            <AppContainer>
              <RootRouter />
            </AppContainer>
          </Provider>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </React.StrictMode>
  );
};

/**
 * Entry point
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
