import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { addUser } from 'firebaseAPI';

export type UserFormData = {
  email: string;
  password: string;
};
export interface AddUserFormProps {
  handleCancel: () => void;
}

const AddUserForm: React.FC<AddUserFormProps> = (props) => {
  const [form] = Form.useForm();
  const { merchantId } = useParams<{ merchantId: string }>();

  const _onFinish = async (values: UserFormData) => {
    await addUser(values.email, values.password, merchantId);
    props.handleCancel();
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      onFinish={_onFinish}
      form={form}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input the email of the user!',
          },
          { max: 180, message: 'Name must be max 30 characters.' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input the password of the user!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Row justify="space-between">
          <Button type="default" onClick={props.handleCancel}>
            Cancel
          </Button>

          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export { AddUserForm };
