import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
`;

// export const TitleText = styled(Text)`
//   margin-left: 14px;
// `;

export const ContentContainer = styled.div`
  margin-top: 17px;
`;
