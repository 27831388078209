/**
 * Imports
 */
import { Checkbox, Row, Col } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Box, Select, Space } from 'components';
import { BaseLayout } from 'features/navigation/components';
import {
  CountrySelectValues,
  COUNTRY_ALPHA_CODES,
  DatePicker,
  FixedDatePicker,
  KeyNumbers,
  TransactionsTable,
  useTransactionsApi,
} from 'features/transactions';
import { userSelector } from 'features/user/redux/selectors';
import { isLink4PaySupporterSelector } from 'features/user/redux/selectors';
import React, { useState } from 'react';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';
import { styled } from 'theme';

/**
 *Styling
 */
const StyledSelect = styled(Select)`
  && {
    min-width: 240px;
    .ant-select-selector .ant-select-selection-search-input {
      height: 42px;
    }
    .ant-select-arrow svg {
      fill: ${({ theme }) => theme.colors.Text.UI};
    }
  }
`;

const TransactionsWrapper = styled(Box)`
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc(100vw - 128px);

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    max-width: 100%;
  }
  }
`;
const StyledOption = styled(Select.Option)``;
const Spacer = styled.div`
  margin-bottom: 16px;
`;

/**
 * Typesinterface
 */
export type TransactionsPageProps = object;

/**
 * TransactionsPage component
 */
const TransactionsPage: React.FC<TransactionsPageProps> = (_props) => {
  const {
    trxnItems,
    handlers: { onShowTestTrxn, onSelectCountry },
    merchantsFilterOpt,
    isFetching,
    tableChangeHandler,
  } = useTransactionsApi();
  const isLink4Pay = useSelector(isLink4PaySupporterSelector);
  const user = useSelector(userSelector);
  const isFO =
    user.meta?.countries?.length === 1 && user.meta.countries[0] === 'FO';
  const isGL =
    user.meta?.countries?.length === 1 && user.meta.countries[0] === 'GL';
  const initCountry = isFO ? 'FO' : isGL ? 'GL' : 'DK';
  console.log(initCountry);
  const [selectValue, setSelectValue] = useState<CountrySelectValues>(
    initCountry,
  );

  const _onChange = (countryCode: SelectValue) => {
    onSelectCountry(countryCode as CountrySelectValues);
    setSelectValue(countryCode as CountrySelectValues);
  };

  return (
    <BaseLayout>
      <Container>
        <TransactionsWrapper>
          <Box display="flex" mb="12px">
            <DatePicker key="trxn-date-picker" />
            <Space vertical size="xl" />
            <FixedDatePicker key="trsc-date-picker" clearable />
          </Box>
          <Box display="flex" alignItems={'center'} flexWrap="wrap">
            <StyledSelect
              defaultValue={isLink4Pay ? 'CY' : selectValue}
              placeholder="Select country"
              onChange={(countryCode: SelectValue) => _onChange(countryCode)}
            >
              {Object.entries(COUNTRY_ALPHA_CODES).map((c) => {
                return (
                  <StyledOption key={c[0]} value={c[0]}>
                    {c[0] !== 'ALL' && (
                      <ReactCountryFlag
                        style={{
                          fontSize: '18px',
                          lineHeight: '18px',
                          marginRight: '8px',
                          marginTop: '-4px',
                        }}
                        countryCode={c[0]}
                        svg
                      />
                    )}
                    {c[1].name}
                  </StyledOption>
                );
              })}
            </StyledSelect>
            <Space vertical size="xl" />
            <Checkbox onChange={onShowTestTrxn}>
              Show test transactions
            </Checkbox>
          </Box>
        </TransactionsWrapper>
        {!isLink4Pay && (
          <Box>
            <KeyNumbers trxnItems={trxnItems} country={selectValue} />
          </Box>
        )}
      </Container>
      <TransactionsTable
        trxnItems={trxnItems}
        paginate={false}
        merchantsFilterOpt={merchantsFilterOpt}
        isFetching={isFetching}
        tableChangeHandler={tableChangeHandler}
      />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TransactionsPage };
