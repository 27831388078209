/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';

/**
 * Constants
 */
const PREFIX = 'sidebar';

/**
 * OPEN_SIDEBAR
 */
export const OPEN_SIDEBAR = `${PREFIX}/OPEN_SIDEBAR`;
export type OpenSidebarAction = VibrantlyAction<object>;
export const openSidebar = createAction<OpenSidebarAction['payload']>(
  OPEN_SIDEBAR,
);

/**
 * COLLAPSE_SIDEBAR
 */
export const COLLAPSE_SIDEBAR = `${PREFIX}/COLLAPSE_SIDEBAR`;
export type CollapseSidebarAction = VibrantlyAction<object>;
export const collapseSidebar = createAction<CollapseSidebarAction['payload']>(
  COLLAPSE_SIDEBAR,
);

/**
 * HIDE_SIDEBAR
 */
export const HIDE_SIDEBAR = `${PREFIX}/HIDE_SIDEBAR`;
export type HideSidebarAction = VibrantlyAction<object>;
export const hideSidebar = createAction<HideSidebarAction['payload']>(
  HIDE_SIDEBAR,
);
/**
 * SHOW_OWNER_MODAL
 */
export const SHOW_TERMINAL_MODAL = `${PREFIX}/SHOW_TERMINAL_MODAL`;
export type ShowTerminalModalAction = VibrantlyAction<object>;
export const showAddTerminalModal = createAction<
  ShowTerminalModalAction['payload']
>(SHOW_TERMINAL_MODAL);
/**
 * HIDE_OWNER_MODAL
 */
export const HIDE_TERMINAL_MODAL = `${PREFIX}/HIDE_TERMINAL_MODAL`;
export type HideTerminalModalAction = VibrantlyAction<object>;
export const hideAddTerminalModal = createAction<
  HideTerminalModalAction['payload']
>(HIDE_TERMINAL_MODAL);
