/**
 * Imports
 */
import {
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { Empty, Modal } from 'antd';
import { FormGroup, Icon } from 'components';
import { DocumentObj, useDocumentsApi } from 'features/merchants/hooks';
import * as React from 'react';
import { styled } from 'theme';
import { Firebase } from 'types/firebase';
import { UploadDocument } from 'utils';
import { AddDocumentForm } from './AddDocumentForm';

/**
 * Styling
 */
const Row = styled.div`
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 4px;

  cursor: pointer;
  padding: 12px 16px;
  margin-bottom: 8px;
  margin-right: 12px;
  width: 100%;

  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.Background.Secondary};
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DeleteButton = styled.div`
  cursor: pointer;
  margin-left: 12px;
`;
const File = styled.div`
  margin-left: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledEmpty = styled(Empty)`
  && {
    margin: 0;
  }
`;

/**
 * Types
 */
export interface MerchantDocumentsProps {
  merchant: Firebase.Merchant;
}

/**
 * MerchantDocuments component
 */
const MerchantDocuments: React.FC<MerchantDocumentsProps> = ({ merchant }) => {
  const documentsWithAcctId = !!merchant?.companyDocumentsPath
    ?.split('/')[1]
    .includes('acct_');
  const [showModal, setShowModal] = React.useState(false);
  const [documents, setDocuments] = React.useState<DocumentObj[]>([]);
  const {
    handlers: {
      onGetDocuments,
      onDownloadImage,
      onDeleteDocument,
      onUpdateCompanyDocuments,
    },
    isLoading,
  } = useDocumentsApi({
    userId: merchant.onboardingId,
    merchantId: merchant.merchantId,
    accoutId: merchant.accountId,
    usesCustomerFiles: documentsWithAcctId,
  });

  const _onGetDocuments = async () => {
    if (merchant?.companyDocumentsPath) {
      const documents = await onGetDocuments();
      setDocuments(documents);
    }
  };

  const _delete = async (name: string) => {
    if (
      confirm('Are you sure you want to delete this document? ' + name) == true
    ) {
      await onDeleteDocument(name);
      _onGetDocuments();
    } else {
    }
  };

  const _download = async (name: string) => {
    alert;
    await onDownloadImage(name);
  };

  const _onUpdateCompanyDocuments = async ({
    file,
    name,
  }: UploadDocument): Promise<void> => {
    await onUpdateCompanyDocuments({
      file,
      name,
    });
    _onGetDocuments();
  };

  const _showModal = () => {
    setShowModal(true);
  };

  React.useEffect(() => {
    _onGetDocuments();
  }, [merchant.onboardingId]);

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (documents.length === 0) {
    return (
      <>
        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={false}
        >
          <AddDocumentForm
            userId={merchant.onboardingId}
            onUpdateCompanyDocuments={_onUpdateCompanyDocuments}
          />
        </Modal>
        <FormGroup
          icon={<FileOutlined />}
          title="Documents"
          onCreate={_showModal}
        >
          <StyledEmpty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No documents"
          />
        </FormGroup>
      </>
    );
  }
  return (
    <>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <AddDocumentForm
          userId={merchant.onboardingId}
          onUpdateCompanyDocuments={_onUpdateCompanyDocuments}
        />
      </Modal>
      <FormGroup
        icon={<FileOutlined />}
        title={`Documents (${documents.length})`}
        onCreate={_showModal}
      >
        {documents.map((doc) => {
          return (
            <Flex key={doc.name}>
              <Row onClick={() => _download(doc.name)}>
                <Icon icon={<DownloadOutlined />} />
                <File>{doc.name}</File>
              </Row>
              <a target="_blank" rel="noreferrer" href={doc.url}>
                VIEW
              </a>
              <DeleteButton onClick={() => _delete(doc.name)}>
                <Icon icon={<DeleteOutlined />} />
              </DeleteButton>
            </Flex>
          );
        })}
      </FormGroup>
    </>
  );
};

/**
 * Exports
 */
export { MerchantDocuments };
