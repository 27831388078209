import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import type { Firebase } from 'types/firebase';
import { TerminalFormData } from 'features/merchants/components/MerchantTerminalDetails/AddTerminalForm';

export const getTerminals = async (): Promise<Firebase.Terminal[]> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${PROJECT_API_URL}/api/v1/merchant/terminals`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Firebase.Terminal[];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createTerminal = async (
  terminalData: TerminalFormData,
  merchantId: string,
): Promise<void> => {
  const idToken = await getIdToken();
  try {
    await axios.post(
      `${PROJECT_API_URL}/api/softpos/v1/terminals/create`,
      { merchantId: merchantId, ...terminalData },
      {
        headers: { token: idToken },
      },
    );
  } catch (error) {
    console.log(error);
  }
};
export const updateTerminal = async (
  terminalData: Partial<TerminalFormData>,
  merchantId: string,
  terminalId: string,
): Promise<void> => {
  const idToken = await getIdToken();
  try {
    await axios.post(
      `${PROJECT_API_URL}/api/softpos/v1/terminals/update`,
      { merchantId, terminalId, ...terminalData },
      {
        headers: { token: idToken },
      },
    );
  } catch (error) {
    console.log(error);
  }
};
