/**
 * Imports
 */
import { Form, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Button, Input, Text } from 'components';
import * as React from 'react';
import { styled } from 'theme';
import { UploadDocument } from 'utils';

const { Dragger } = Upload;

/**
 * Styling
 */
const Root = styled.div`
  min-height: 480px;
`;
const Header = styled.div`
  margin-bottom: 42px;
  display: flex;
  justify-content: center;
`;
const UploadWrapper = styled.div`
  width: 100%;
`;
const Inner = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.div`
  margin-top: 64px;
  height: 100%;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * Types
 */
export type DocumentFormRow = {
  category: string;
  documents: { file: File; fileList: UploadFile[] };
};
export interface AddDocumentFormProps {
  userId: string;
  onUpdateCompanyDocuments: (document: UploadDocument) => Promise<void>;
}

/**
 * AddDocumentForm component
 */
const AddDocumentForm: React.FC<AddDocumentFormProps> = ({
  userId,
  onUpdateCompanyDocuments,
}) => {
  const [isValid, setIsValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [form] = Form.useForm();

  const _onFinish = async (values: DocumentFormRow) => {
    try {
      setIsLoading(true);
      const { category, documents } = values;
      await onUpdateCompanyDocuments({
        file: documents.file,
        name: category,
      });
      form.resetFields();
      setIsLoading(false);
    } catch (exception) {
      setIsLoading(false);
      console.log('Failed to add owner', exception);
    }
  };

  const _onChange = (_values: DocumentFormRow, allValues: DocumentFormRow) => {
    const allFieldsFilled =
      Object.values(allValues).filter((e) => e)?.length === 2;

    if (allFieldsFilled) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <Root>
      <Form
        name="add_document"
        onFinish={_onFinish}
        autoComplete="off"
        onValuesChange={_onChange}
        layout="vertical"
        form={form}
      >
        <Inner>
          <Content>
            <Header>
              <Text size="h5">Upload document</Text>
            </Header>
            <Form.Item
              label="Document category (one word, no special characters)"
              name="category"
            >
              <Input placeholder="ex. invoice, vat, company, addressID, passport" />
            </Form.Item>
            <Form.Item label="Document (one file at a time)" name="documents">
              <Dragger
                beforeUpload={() => false}
                multiple={false}
                accept="application/msword, application/pdf, application/vnd.oasis.opendocument.spreadsheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png"
              >
                <Text size="ui2">Click to upload, or drag and drop </Text>
              </Dragger>
            </Form.Item>
          </Content>
          <Footer>
            <Form.Item>
              <Button
                disabled={!isValid}
                loading={isLoading}
                htmlType="submit"
                type="primary"
              >
                Add document
              </Button>
            </Form.Item>
          </Footer>
        </Inner>
      </Form>
    </Root>
  );
};

/**
 * Exports
 */
export { AddDocumentForm };
